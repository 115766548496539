angular
    .module("admin.semesters.semester.contracts.contract.predefined-fields", ["ui.sortable"])
    .controller("ContractPredfinedFieldsCtrl", [
        "field",
        "semester",
        "$uibModalInstance",
        "ContractsModel",
        "Loader",
        function (field, semester, $uibModalInstance, ContractsModel, Loader) {
            var contractPredefinedFieldsCtrl = this;

            contractPredefinedFieldsCtrl.label = ContractsModel.labels()[field];

            contractPredefinedFieldsCtrl.meta = null;
            contractPredefinedFieldsCtrl.items = [];
            contractPredefinedFieldsCtrl.editedItems = [];

            ContractsModel.getContractsMeta().then(function (meta) {
                contractPredefinedFieldsCtrl.meta = _.find(meta, function (meta) {
                    if (semester && meta.value.semester) {
                        return meta.key == field && meta.value.semester == semester.term.name;
                    } else {
                        return meta.key == field;
                    }
                });

                if (contractPredefinedFieldsCtrl.meta) {
                    contractPredefinedFieldsCtrl.items = contractPredefinedFieldsCtrl.meta.value.items;
                    contractPredefinedFieldsCtrl.editedItems = angular.copy(
                        contractPredefinedFieldsCtrl.meta.value.items
                    );
                }
            });

            contractPredefinedFieldsCtrl.add = function () {
                var item = {
                    label: "",
                    value: "",
                };

                contractPredefinedFieldsCtrl.editedItems.push(item);
            };

            contractPredefinedFieldsCtrl.delete = function (item) {
                _.remove(contractPredefinedFieldsCtrl.editedItems, item);
            };

            contractPredefinedFieldsCtrl.save = function () {
                var editedMeta = {};
                editedMeta.key = field;
                editedMeta.value = {};
                editedMeta.value.items = contractPredefinedFieldsCtrl.editedItems;

                if (semester) {
                    editedMeta.value.semester = semester.term.name;
                }

                Loader.showLoading("Saving");
                if (contractPredefinedFieldsCtrl.meta && contractPredefinedFieldsCtrl.meta.id) {
                    editedMeta.id = contractPredefinedFieldsCtrl.meta.id;
                    ContractsModel.updateContractsMeta(editedMeta).finally(function () {
                        $uibModalInstance.close();
                        Loader.hideLoading();
                    });
                } else {
                    ContractsModel.createContractsMeta(editedMeta).finally(function () {
                        $uibModalInstance.close();
                        Loader.hideLoading();
                    });
                }
            };

            contractPredefinedFieldsCtrl.cancel = function () {
                $uibModalInstance.dismiss("cancel");
            };
        },
    ]);
