angular
    .module("admin.approvers.approver.members.add", ["app.models.terms"])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.approvers.approver.members.add", {
            url: "/members/add",
            views: {
                add: {
                    templateUrl: "admin/approvers/approver/members/add/member-add.tmpl.html",
                    controller: "AddApproverMemberCtrl as addApproverMemberCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Add Staff Member",
            },
        });
    })
    .controller("AddApproverMemberCtrl", [
        "$state",
        "$stateParams",
        "ApproversModel",
        "Loader",
        function ($state, $stateParams, ApproversModel, Loader) {
            var addApproverMemberCtrl = this;
            var approverId = $stateParams.approverId;

            function returnToApprovers() {
                $state.go("admin.approvers.approver");
            }

            function cancel() {
                returnToApprovers();
            }

            function addApproverMember(member) {
                Loader.showLoading("Adding Staff Member");
                ApproversModel.addApproverMember(approverId, member)
                    .then(
                        function (approver) {
                            returnToApprovers();
                        },
                        function (result) {
                            var errors = [];
                            _.forOwn(result.errors, function (value, key) {
                                errors = errors.concat(value);
                                addApproverMemberCtrl.addApproverMemberForm[key].$invalid = true;
                            });
                            addApproverMemberCtrl.addApproverMemberForm.errors = errors;
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            }

            function resetForm() {
                addApproverMemberCtrl.newApproverMember = {
                    first_name: "",
                    last_name: "",
                    email: "",
                };
            }

            addApproverMemberCtrl.cancel = cancel;
            addApproverMemberCtrl.addApproverMember = addApproverMember;

            resetForm();
        },
    ]);
