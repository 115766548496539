angular.module('admin.users.audits', [])
    .config(function($stateProvider) {
        $stateProvider
            .state('admin.users.user.audits', {
                url: '/audits',
                views: {
                    '': {
                        templateUrl: 'admin/users/user/audits/user-audits.tmpl.html',
                        controller: 'AuditUserCtrl as auditUserCtrl'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Audits'
                }
            });
    })
    .controller('AuditUserCtrl', ['$state', '$stateParams', '$location', '$timeout', 'UsersModel', 'Loader', 'LSFactory', 'UsersFactory',
        function($state, $stateParams, $location, $timeout, UsersModel, Loader, LSFactory, UsersFactory) {
            var auditUserCtrl = this;
            var userId = $stateParams.userId;

            var params = $location.search();

            if (_.isEmpty(params)) {
                params = LSFactory.get('userAuditsQuery');
            }

            auditUserCtrl.events = [
                'created',
                'updated',
                'deleted'
            ];

            auditUserCtrl.fields = [];
            _.forEach(UsersModel.fields(), function(field) {
                if (!_.includes(['created_at', 'updated_at'], field)) {
                    auditUserCtrl.fields.push({
                        value: field,
                        label: UsersModel.labels()[field],
                        group: UsersModel.groups()[field],
                    });
                }
            });

            if (!_.isEmpty(params)) {
                if (params.page) {
                    auditUserCtrl.page = parseInt(params.page);
                }

                if (params.event) {
                    auditUserCtrl.event = params.event;
                }

                if (params['field[]']) {
                    auditUserCtrl.field = _.filter(auditUserCtrl.fields, function(field) {
                        return _.includes(params['field[]'], field.value);
                    });
                }

                updateQueryString();
            }

            function updateQueryString() {
                $timeout(function() {
                    LSFactory.set('userAuditsQuery', params);
                    $location.search(params);
                });
            }

            function getAudits() {
                Loader.showLoading('Loading Audits');
                UsersModel.getAudits(userId, params).then(function(result) {
                    auditUserCtrl.audits = result;
                    auditUserCtrl.users = result.users;

                    if (params && params['user[]']) {
                        auditUserCtrl.user = _.filter(auditUserCtrl.users, function(user) {
                            return _.includes(params['user[]'], user.id);
                        });
                    }

                    Loader.hideLoading();
                });
            }

            auditUserCtrl.label = function(field) {
                $labels = UsersModel.labels();

                if ($labels[field]) {
                    return $labels[field];
                }

                return field;
            };

            auditUserCtrl.format = function(field, value) {
                value = UsersFactory.format(field, value);

                if (!value && value !== 0) {
                    value = 'NULL';
                }

                return value;
            };

            auditUserCtrl.search = function() {
                function searchAudits() {
                    params = {};

                    if (auditUserCtrl.page) {
                        params.page = parseInt(auditUserCtrl.page);
                    }

                    if (auditUserCtrl.event) {
                        params.event = auditUserCtrl.event;
                    }

                    if (auditUserCtrl.user) {
                        params['user[]'] = _.map(auditUserCtrl.user, 'id');
                    }

                    if (auditUserCtrl.field) {
                        params['field[]'] = _.map(auditUserCtrl.field, 'value');
                    }

                    updateQueryString();

                    getAudits();
                }

                searchAudits();
            };

            auditUserCtrl.clear = function(field) {
                if (field) {
                    if (field == 'event') {
                        auditUserCtrl.event = null;
                    }
                } else {
                    auditUserCtrl.event = null;
                    auditUserCtrl.user = null;
                    auditUserCtrl.field = null;
                }
                auditUserCtrl.page = 1;
                auditUserCtrl.search();
            };

            getAudits();

            auditUserCtrl.getAudits = getAudits;
        }
    ]);