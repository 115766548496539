angular.module("app.factories.proposal-approvers", []).factory("ProposalApproversFactory", [
    "$uibModal",
    "$filter",
    "ProposalsModel",
    "ApprovalsModel",
    "ContractsModel",
    function ($uibModal, $filter, ProposalsModel, ApprovalsModel, ContractsModel) {
        var proposalApproversFactory = this;

        function getPanelClass(status) {
            if (status === 1) {
                return "panel-success";
            }

            if (status === 0) {
                return "panel-danger";
            }

            if (_.includes(["Queued", "Pending", "Sent", "Sent Reminder"], status)) {
                return "panel-default";
            }

            if (
                _.includes(
                    [
                        "Information Adjustment",
                        "Salary Correction",
                        "Salary Adjustment",
                        "DE Adjustment",
                        "Prorated Salary",
                        "Cancelled Course",
                        "Cancelled Course (No Fee)",
                        "Change in Instructor",
                        "Contract",
                    ],
                    status
                )
            ) {
                return "panel-info";
            }

            return "panel-primary";
        }

        function getRevisionHTML(revision, prevRevision) {
            var labels = ContractsModel.labels();
            var dates = ContractsModel.dates();

            var fields = [
                "user_ums_id",
                "user_last_name",
                "user_first_name",
                "instructor_unit",
                "instructor_rank",
                "section_max_units",
                "salary",
                "payments",
                "pay_start_date",
                "pay_end_date",
                "subject",
                "course_catalog",
                "section_no",
                "lesson_comb_sects_id",
                "course_title",
                "lesson_start_date_one",
                "lesson_end_date_one",
                "department",
            ];

            var html = "<ul class='list-unstyled m-0'>";

            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                var value = revision[field];
                var prevValue = prevRevision && prevRevision[field];
                var wasChanged = prevValue && value != prevValue;

                if (_.includes(dates, field)) {
                    value = $filter("date")(value, "M/d/yy");
                    prevValue = prevValue && $filter("date")(prevValue, "M/d/yy");
                }

                html += "<li><b>" + labels[field] + ":</b> ";

                if (wasChanged) {
                    html += '<span class="text-warning">';
                }

                html += value;

                if (wasChanged) {
                    html += ' <s class="text-danger">' + prevValue + "</s></span>";
                }

                html += "</li>";
            }

            html += "</ul>";

            return html;
        }

        function getApproval(proposalApprover, contract) {
            var approval = _.find(proposalApprover.approvals, function (approval) {
                return approval.contract_id == contract.parent_id;
            });

            return approval ? approval : null;
        }

        var getApprovalStatus = function (proposalApprover, contract) {
            var approval = getApproval(proposalApprover, contract);

            if (approval) {
                return approval.status;
            }

            return null;
        };

        function hasApproval(proposalApprover, contract) {
            var approval = getApproval(proposalApprover, contract);

            if (approval) {
                return true;
            }

            return false;
        }

        var showApproval = function (proposalApprover, contract) {
            var modalInstance = $uibModal.open({
                templateUrl: "common/templates/approvals/approval.tmpl.html",
                controller: [
                    "contract",
                    "proposalApprover",
                    "approval",
                    "$uibModalInstance",
                    function (contract, proposalApprover, approval, $uibModalInstance) {
                        var modalCtrl = this;

                        modalCtrl.contract = contract;
                        modalCtrl.proposalApprover = proposalApprover;
                        modalCtrl.approval = approval;

                        modalCtrl.cancel = function () {
                            $uibModalInstance.dismiss("cancel");
                        };
                    },
                ],
                controllerAs: "modalCtrl",
                resolve: {
                    contract: function () {
                        return contract;
                    },
                    proposalApprover: function () {
                        return proposalApprover;
                    },
                    approval: function () {
                        return getApproval(proposalApprover, contract);
                    },
                },
            });

            modalInstance.result.catch(function (result) {
                if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
            });
        };

        function hasApprovalStatuses(proposalApprover, contract) {
            var approval = getApproval(proposalApprover, contract);

            if (approval && approval.statuses) {
                return true;
            }

            return false;
        }

        function showApprovalStatuses(proposalApprover, contract) {
            var approval = getApproval(proposalApprover, contract);

            var modalInstance = $uibModal.open({
                templateUrl: "common/templates/approvals/approval-statuses.tmpl.html",
                controller: [
                    "proposalApprover",
                    "contract",
                    "approval",
                    "$uibModalInstance",
                    function (proposalApprover, contract, approval, $uibModalInstance) {
                        var modalCtrl = this;

                        modalCtrl.isLoading = true;

                        ApprovalsModel.getApprovalStatuses(approval.id)
                            .then(function (statuses) {
                                modalCtrl.statuses = statuses;
                            })
                            .finally(function () {
                                modalCtrl.isLoading = false;
                            });

                        modalCtrl.proposalApprover = proposalApprover;
                        modalCtrl.contract = contract;
                        modalCtrl.approval = approval;
                        modalCtrl.getPanelClass = getPanelClass;

                        modalCtrl.getRevisionHTML = function (revision, index) {
                            var revisions = _.map(modalCtrl.statuses, "revision");

                            var prevRevision = revisions[index + 1];

                            return getRevisionHTML(revision, prevRevision);
                        };

                        modalCtrl.cancel = function () {
                            $uibModalInstance.dismiss("cancel");
                        };
                    },
                ],
                controllerAs: "modalCtrl",
                resolve: {
                    proposalApprover: function () {
                        return proposalApprover;
                    },
                    contract: function () {
                        return contract;
                    },
                    approval: function () {
                        return approval;
                    },
                },
            });

            modalInstance.result.catch(function (result) {
                if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
            });
        }

        var hasApprovalMessage = function (contract, proposalApprover) {
            var approval = getApproval(contract, proposalApprover);

            if (approval && approval.message) {
                return true;
            }

            return false;
        };

        var getProposalApproverStatus = function (proposalApprover) {
            if (proposalApprover.status == "Approved") {
                return 3;
            } else if (proposalApprover.status == "Declined" || proposalApprover.status == "Needs Revised") {
                return 2;
            }

            return 1;
        };

        var getProposalApproverStatusHTML = function (proposalApprover) {
            var proposalApproverStatus = getProposalApproverStatus(proposalApprover);

            var html = "";

            if (proposalApproverStatus == 3) {
                html = '<span class="text-success"><i class="fa fa-lg fa-check-circle"></i></span>';
            } else if (proposalApproverStatus == 2) {
                html = '<span class="text-warning"><i class="fa fa-lg fa-exclamation-triangle"></i></span>';
            } else if (proposalApproverStatus == 1) {
                html = '<span class="text-danger"><i class="fa fa-lg fa-exclamation-circle"></i></span>';
            }

            return html;
        };

        function showProposalApproverStatuses(proposalApprover) {
            var modalInstance = $uibModal.open({
                templateUrl: "common/templates/proposal-approvers/proposal-approver-statuses.tmpl.html",
                controller: [
                    "proposalApprover",
                    "$uibModalInstance",
                    function (proposalApprover, $uibModalInstance) {
                        var modalCtrl = this;

                        modalCtrl.isLoading = true;

                        ProposalsModel.getProposalApproverStatuses(proposalApprover.id)
                            .then(function (statuses) {
                                modalCtrl.statuses = statuses;
                            })
                            .finally(function () {
                                modalCtrl.isLoading = false;
                            });

                        modalCtrl.proposalApprover = proposalApprover;
                        modalCtrl.getPanelClass = getPanelClass;

                        modalCtrl.getRevisionHTML = function (revision, index) {
                            var revisions = _.map(modalCtrl.statuses, "revision");

                            var prevRevision = revisions[index + 1];

                            return getRevisionHTML(revision, prevRevision);
                        };

                        modalCtrl.cancel = function () {
                            $uibModalInstance.dismiss("cancel");
                        };
                    },
                ],
                controllerAs: "modalCtrl",
                resolve: {
                    proposalApprover: function () {
                        return proposalApprover;
                    },
                },
            });

            modalInstance.result.catch(function (result) {
                if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
            });
        }

        proposalApproversFactory.showProposalApproverStatuses = showProposalApproverStatuses;

        proposalApproversFactory.hasApproval = hasApproval;
        proposalApproversFactory.hasApprovalMessage = hasApprovalMessage;
        proposalApproversFactory.hasApprovalStatuses = hasApprovalStatuses;

        proposalApproversFactory.getApproval = getApproval;
        proposalApproversFactory.getApprovalStatus = getApprovalStatus;

        proposalApproversFactory.showApproval = showApproval;
        proposalApproversFactory.showApprovalStatuses = showApprovalStatuses;

        proposalApproversFactory.getProposalApproverStatusHTML = getProposalApproverStatusHTML;

        return proposalApproversFactory;
    },
]);
