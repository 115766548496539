angular.module('admin.semesters.delete', [])
    .controller('DeleteSemesterCtrl', ['semester', '$state', '$uibModalInstance', 'SemestersModel', 'Loader', 'AlertFactory',
        function(semester, $state, $uibModalInstance, SemestersModel, Loader, AlertFactory) {
            var deleteSemesterCtrl = this;

            deleteSemesterCtrl.semester = semester;

            deleteSemesterCtrl.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };

            deleteSemesterCtrl.delete = function(semester) {
                Loader.showLoading('Deleting Semester');
                SemestersModel.deleteSemester(semester).then(function() {
                    $state.go('admin.semesters');
                }, function(result) {
                    if (result.errors) {
                        var messages = [];
                        _.forEach(result.errors, function(error) {
                            messages.push(error);
                        });
                        AlertFactory.show(messages.join("\r\n"), 'danger');
                    }
                }).finally(function() {
                    deleteSemesterCtrl.cancel();
                    Loader.hideLoading();
                });
            };

            $uibModalInstance.result.catch(function() { $uibModalInstance.close(); });
        }
    ]);