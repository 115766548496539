angular.module("app.factories.approvals", []).factory("ApprovalsFactory", [
    "$uibModal",
    "$filter",
    "ApprovalsModel",
    "ContractsModel",
    function ($uibModal, $filter, ApprovalsModel, ContractsModel) {
        var approvalsFactory = this;

        function getPanelClass(status) {
            if (status === 1) {
                return "panel-success";
            }

            if (status === 0) {
                return "panel-danger";
            }

            if (_.includes(["Queued", "Pending", "Sent", "Sent Reminder"], status)) {
                return "panel-default";
            }

            if (
                _.includes(
                    [
                        "Information Adjustment",
                        "Salary Correction",
                        "Salary Adjustment",
                        "DE Adjustment",
                        "Prorated Salary",
                        "Cancelled Course",
                        "Cancelled Course (No Fee)",
                        "Change in Instructor",
                        "Contract",
                    ],
                    status
                )
            ) {
                return "panel-info";
            }

            return "panel-primary";
        }

        function getRevisionHTML(revision, prevRevision) {
            var labels = ContractsModel.labels();
            var dates = ContractsModel.dates();

            var fields = [
                "user_ums_id",
                "user_last_name",
                "user_first_name",
                "instructor_unit",
                "instructor_rank",
                "section_max_units",
                "salary",
                "payments",
                "pay_start_date",
                "pay_end_date",
                "subject",
                "course_catalog",
                "section_no",
                "lesson_comb_sects_id",
                "course_title",
                "lesson_start_date_one",
                "lesson_end_date_one",
                "department",
            ];

            var html = "<ul class='list-unstyled m-0'>";

            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                var value = revision[field];
                var prevValue = prevRevision && prevRevision[field];
                var wasChanged = prevValue && value != prevValue;

                if (_.includes(dates, field)) {
                    value = $filter("date")(value, "M/d/yy");
                    prevValue = prevValue && $filter("date")(prevValue, "M/d/yy");
                }

                html += "<li><b>" + labels[field] + ":</b> ";

                if (wasChanged) {
                    html += '<span class="text-warning">';
                }

                html += value;

                if (wasChanged) {
                    html += ' <s class="text-danger">' + prevValue + "</s></span>";
                }

                html += "</li>";
            }

            html += "</ul>";

            return html;
        }

        function showContractApprovalStatuses(contract) {
            var modalInstance = $uibModal.open({
                templateUrl: "common/templates/approvals/contract-approval-statuses.tmpl.html",
                controller: [
                    "contract",
                    "$uibModalInstance",
                    function (contract, $uibModalInstance) {
                        var modalCtrl = this;

                        modalCtrl.isLoading = true;

                        ApprovalsModel.getContractApprovalStatuses(contract.parent_id)
                            .then(function (statuses) {
                                modalCtrl.statuses = statuses;
                            })
                            .finally(function () {
                                modalCtrl.isLoading = false;
                            });

                        modalCtrl.contract = contract;
                        modalCtrl.getPanelClass = getPanelClass;

                        modalCtrl.getRevisionHTML = function (revision, index) {
                            var revisions = _.map(modalCtrl.statuses, "revision");

                            var prevRevision = revisions[index + 1];

                            return getRevisionHTML(revision, prevRevision);
                        };

                        modalCtrl.cancel = function () {
                            $uibModalInstance.dismiss("cancel");
                        };
                    },
                ],
                controllerAs: "modalCtrl",
                resolve: {
                    contract: function () {
                        return contract;
                    },
                },
            });

            modalInstance.result.catch(function (result) {
                if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
            });
        }

        function showApprovalStatuses(approval, contract) {
            var modalInstance = $uibModal.open({
                templateUrl: "common/templates/approvals/approval-statuses.tmpl.html",
                controller: [
                    "approval",
                    "contract",
                    "$uibModalInstance",
                    function (approval, contract, $uibModalInstance) {
                        var modalCtrl = this;

                        modalCtrl.isLoading = true;

                        ApprovalsModel.getApprovalStatuses(approval.id)
                            .then(function (statuses) {
                                modalCtrl.statuses = statuses;
                            })
                            .finally(function () {
                                modalCtrl.isLoading = false;
                            });

                        modalCtrl.approval = approval;
                        modalCtrl.contract = contract;
                        modalCtrl.getPanelClass = getPanelClass;

                        modalCtrl.getRevisionHTML = function (revision, index) {
                            var revisions = _.map(modalCtrl.statuses, "revision");

                            var prevRevision = revisions[index + 1];

                            return getRevisionHTML(revision, prevRevision);
                        };

                        modalCtrl.cancel = function () {
                            $uibModalInstance.dismiss("cancel");
                        };
                    },
                ],
                controllerAs: "modalCtrl",
                resolve: {
                    approval: function () {
                        return approval;
                    },
                    contract: function () {
                        return contract;
                    },
                },
            });

            modalInstance.result.catch(function (result) {
                if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
            });
        }

        approvalsFactory.showContractApprovalStatuses = showContractApprovalStatuses;
        approvalsFactory.showApprovalStatuses = showApprovalStatuses;
        approvalsFactory.getRevisionHTML = getRevisionHTML;

        return approvalsFactory;
    },
]);
