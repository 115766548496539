angular.module('instructor.semesters', [
        'app.models.semesters',
        'instructor.semesters.contracts',
        'instructor.semesters.warnings'
    ])
    .config(function($stateProvider) {
        $stateProvider
            .state('instructor.semesters', {
                url: '/semesters',
                views: {
                    '': {
                        templateUrl: 'instructor/semesters/semesters.tmpl.html',
                        controller: 'InstructorSemestersCtrl as semestersCtrl'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Semesters'
                }
            })
            .state('instructor.semesters.semester', {
                abstract: true,
                url: '/{semesterId:int}',
                templateUrl: 'instructor/semesters/semester.tmpl.html',
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    semester: ['$state', '$stateParams', '$q', 'Loader', 'UsersModel',
                        function($state, $stateParams, $q, Loader, UsersModel) {
                            var semesterId = $stateParams.semesterId;
                            var deferred = $q.defer();

                            Loader.showLoading('Loading Semester');
                            UsersModel.getInstructorSemester(semesterId).then(function(semester) {
                                deferred.resolve(semester);
                            }, function() {
                                deferred.reject();
                                $state.go('instructor.semesters');
                            }).finally(function() {
                                Loader.hideLoading();
                            });

                            return deferred.promise;
                        }
                    ]
                },
                controller: function($state, $stateParams, $uibModal, semester) {
                    var semesterCtrl = this;
                    var semesterId = $stateParams.semesterId;

                    semesterCtrl.semester = semester;
                },
                controllerAs: 'semesterCtrl'
            });
    })
    .controller('InstructorSemestersCtrl', ['Loader', 'UsersModel', function(Loader, UsersModel) {
        var semestersCtrl = this;

        Loader.showLoading('Loading Semesters');
        UsersModel.getInstructorSemesters().then(function(semesters) {
            semestersCtrl.semesters = semesters;
            Loader.hideLoading();
        });
    }]);