angular.module("app.models.proposals", []).service("ProposalsModel", [
    "$http",
    "$q",
    "$window",
    "$state",
    "$auth",
    "API_URL",
    "Loader",
    function ($http, $q, $window, $state, $auth, API_URL, Loader) {
        var model = this,
            API = {
                /**
                 * Proposals
                 */

                getProposal: function (proposalId) {
                    return $http.get(API_URL + "proposals/" + proposalId);
                },
                updateProposal: function (proposalId, editedProposal) {
                    return $http.patch(API_URL + "proposals/" + proposalId, editedProposal);
                },
                deleteProposal: function (proposalId) {
                    return $http.delete(API_URL + "proposals/" + proposalId);
                },
                toggleProposalRouting: function (proposalId) {
                    return $http.patch(API_URL + "proposals/" + proposalId + "/toggle");
                },
                sendProposalRevision: function (proposalId, editedProposal) {
                    return $http.patch(API_URL + "proposals/" + proposalId + "/resend", editedProposal);
                },

                /*
                 * Proposal Approvers
                 */

                addProposalApprover: function (proposalId, approver) {
                    return $http.post(API_URL + "proposals/" + proposalId + "/approvers", approver);
                },
                removeProposalApprover: function (proposalId, proposalApproverId) {
                    return $http.delete(API_URL + "proposals/" + proposalId + "/approvers/" + proposalApproverId);
                },
                sendProposalApproverReminder: function (proposalId, proposalApproverId) {
                    return $http.patch(
                        API_URL + "proposals/" + proposalId + "/approvers/" + proposalApproverId + "/remind"
                    );
                },
                replaceProposalApprover: function (proposalId, proposalApproverId, approver) {
                    return $http.patch(
                        API_URL + "proposals/" + proposalId + "/approvers/" + proposalApproverId + "/replace",
                        approver
                    );
                },

                /*
                 * Proposal Contracts
                 */

                addProposalContracts: function (proposalId, contracts) {
                    return $http.patch(API_URL + "proposals/" + proposalId + "/contracts/add", contracts);
                },
                removeProposalContracts: function (proposalId, contracts) {
                    return $http.patch(API_URL + "proposals/" + proposalId + "/contracts/remove", contracts);
                },
                sendProposalContractReminder: function (proposalId, contractId) {
                    return $http.patch(API_URL + "proposals/" + proposalId + "/contracts/" + contractId + "/remind");
                },
            };

        model.dates = function () {
            return ["created_at", "updated_at", "max_updated_at"];
        };

        model.times = function () {
            return [];
        };

        model.numbers = function () {
            return ["id"];
        };

        model.booleans = function () {
            return ["is_routing"];
        };

        model.statuses = function () {
            return ["is_approved", "is_accepted"];
        };

        function extract(result) {
            return result.data;
        }

        /**
         * Proposals
         */

        model.getProposal = function (proposalId) {
            var deferred = $q.defer();

            API.getProposal(proposalId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.updateProposal = function (proposalId, editedProposal) {
            var deferred = $q.defer();

            API.updateProposal(proposalId, editedProposal).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.toggleProposalRouting = function (proposalId) {
            var deferred = $q.defer();

            API.toggleProposalRouting(proposalId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.sendProposalRevision = function (proposalId, editedProposal) {
            var deferred = $q.defer();

            API.sendProposalRevision(proposalId, editedProposal).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteProposal = function (proposalId) {
            var deferred = $q.defer();

            API.deleteProposal(proposalId).then(
                function () {
                    deferred.resolve();
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /*
         * Proposal Approvers
         */

        model.addProposalApprover = function (proposalId, approver) {
            var deferred = $q.defer();

            API.addProposalApprover(proposalId, approver).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.removeProposalApprover = function (proposalId, proposalApproverId) {
            var deferred = $q.defer();

            API.removeProposalApprover(proposalId, proposalApproverId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.sendProposalApproverReminder = function (proposalId, proposalApproverId) {
            var deferred = $q.defer();

            API.sendProposalApproverReminder(proposalId, proposalApproverId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.replaceProposalApprover = function (proposalId, proposalApproverId, approver) {
            var deferred = $q.defer();

            API.replaceProposalApprover(proposalId, proposalApproverId, approver).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /*
         * Contracts
         */

        model.addProposalContracts = function (proposalId, contracts) {
            var deferred = $q.defer();

            API.addProposalContracts(proposalId, contracts).then(
                function () {
                    deferred.resolve();
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.removeProposalContracts = function (proposalId, contracts) {
            var deferred = $q.defer();

            API.removeProposalContracts(proposalId, contracts).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.sendProposalContractReminder = function (proposalId, contractId) {
            var deferred = $q.defer();

            API.sendProposalContractReminder(proposalId, contractId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };
    },
]);
