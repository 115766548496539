angular.module("admin.semesters.semester.contracts.delete", []).controller("DeleteContractsCtrl", [
    "contracts",
    "$uibModalInstance",
    function (contracts, $uibModalInstance) {
        var deleteContractsCtrl = this;

        deleteContractsCtrl.contracts = contracts;

        deleteContractsCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        deleteContractsCtrl.delete = function (contracts) {
            $uibModalInstance.close(contracts);
        };
    },
]);
