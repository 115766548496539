angular.module('admin.approvers.import', [])
    .config(function($stateProvider) {
        $stateProvider
            .state('admin.approvers.import', {
                url: '/import',
                views: {
                    'import': {
                        templateUrl: 'admin/approvers/import/approvers-import.tmpl.html',
                        controller: 'ImportApproversCtrl as importApproversCtrl'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Import Approvers'
                }
            });
    })
    .directive('fileModel', ['$parse', function($parse) {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var model = $parse(attrs.fileModel);
                var modelSetter = model.assign;

                element.bind('change', function() {
                    scope.$apply(function() {
                        modelSetter(scope, element[0].files[0]);
                    });
                });
            }
        };
    }])
    .controller('ImportApproversCtrl', ['$scope', '$state', 'SemestersModel', 'ApproversModel', 'Loader', 'AlertFactory',
        function($scope, $state, SemestersModel, ApproversModel, Loader, AlertFactory) {
            var importApproversCtrl = this;
            importApproversCtrl.approvers = {};
            importApproversCtrl.update_contracts = false;

            Loader.showLoading('Loading Semesters');
            SemestersModel.getSemesters().then(function(semesters) {
                importApproversCtrl.semesters = semesters;
                Loader.hideLoading();
            });

            importApproversCtrl.importApprovers = function() {
                Loader.showLoading('Importing Approvers');

                var fd = new FormData();
                fd.append('file', importApproversCtrl.file);

                ApproversModel.importApprovers(fd).then(function(result) {
                    if (result.errors) {
                        importApproversCtrl.importApproversForm.errors = result.errors;
                    }else {
                        $state.go('admin.approvers');
                    }
                }, function(result) {
                     if (result && result.errors) {
                        importApproversCtrl.importApproversForm.errors = result.errors;
                    } else {
                        AlertFactory.show('Cannot load excel file. It may be corrupt.', 'danger');
                    }
                }).finally(function() {
                    Loader.hideLoading();
                    $scope.$emit('approvers.update');
                });
            };
        }
    ]);