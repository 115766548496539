angular.module("admin.semesters.semester.contracts.contract.cancel", []).controller("CancelContractCtrl", [
    "contract",
    "$uibModalInstance",
    "Loader",
    "ApproversModel",
    "SemestersModel",
    "TemplatesModel",
    function (contract, $uibModalInstance, Loader, ApproversModel, SemestersModel, TemplatesModel) {
        var cancelContractCtrl = this;

        cancelContractCtrl.approvers = [];
        cancelContractCtrl.proposalApprovers = [];
        cancelContractCtrl.isAdding = false;

        Loader.showLoading("Loading Template");
        TemplatesModel.getTemplates()
            .then(
                function (templates) {
                    cancelContractCtrl.template = _.find(_.groupBy(templates, "type").email, function (emailTemplate) {
                        return emailTemplate.name == "canc";
                    });
                },
                function () {
                    $state.go("admin.semesters.semester.contracts.contract");
                }
            )
            .finally(function () {
                Loader.hideLoading();
            });

        var title =
            contract.subject +
            " " +
            contract.course_catalog +
            " (" +
            contract.section_no +
            ") " +
            contract.semester.term.name +
            " " +
            contract.semester.year;
        title += " Part-Time Faculty Bargaining Unit Course Cancellation Payment";

        cancelContractCtrl.title = title;
        cancelContractCtrl.contract = contract;

        cancelContractCtrl.roles = ApproversModel.roles();
        _.remove(cancelContractCtrl.roles, { name: "director" });

        Loader.showLoading("Loading Approvers");
        ApproversModel.getApprovers().then(function (approvers) {
            _.forEach(approvers, function (approver) {
                approver.label =
                    approver.user.first_name +
                    " " +
                    approver.user.last_name +
                    " (Role: " +
                    approver.role.display_name +
                    ")";
                if (approver.college) {
                    approver.label += " (College: " + approver.college + ")";
                }
                if (approver.department) {
                    approver.label += " (Department: " + approver.department + ")";
                }
            });

            cancelContractCtrl.approvers = approvers;
            cancelContractCtrl.colleges = _.compact(_.uniq(_.map(approvers, "college")));
            cancelContractCtrl.departments = _.compact(_.uniq(_.map(approvers, "department")));

            if (contract.proposal) {
                cancelContractCtrl.proposalApprovers = angular.copy(contract.proposal.approvers);
            }

            Loader.hideLoading();
        });

        cancelContractCtrl.save = function () {
            cancelContractCtrl.contract.note = cancelContractCtrl.note;
            cancelContractCtrl.contract.approvers = _.map(cancelContractCtrl.proposalApprovers, "user_id");

            $uibModalInstance.close(cancelContractCtrl.contract);
        };

        cancelContractCtrl.cancel = function () {
            $uibModalInstance.dismiss();
        };

        cancelContractCtrl.filterRole = function () {
            return {
                role: {
                    name: cancelContractCtrl.role,
                },
            };
        };

        cancelContractCtrl.filterCollege = function () {
            if (cancelContractCtrl.college) {
                return {
                    college: cancelContractCtrl.college,
                };
            }

            return "";
        };

        cancelContractCtrl.filterDepartment = function () {
            if (cancelContractCtrl.department) {
                return {
                    department: cancelContractCtrl.department,
                };
            }

            return "";
        };

        cancelContractCtrl.resetForm = function () {
            cancelContractCtrl.role = "";
            cancelContractCtrl.college = "";
            cancelContractCtrl.department = "";
            cancelContractCtrl.approver = "";

            cancelContractCtrl.roleFilterStrict = false;
            cancelContractCtrl.collegeFilterStrict = false;
            cancelContractCtrl.departmentFilterStrict = false;

            cancelContractCtrl.resetValidity();
        };

        cancelContractCtrl.resetValidity = function () {
            cancelContractCtrl.addApproverForm.approver.$setValidity("unique", true);
            cancelContractCtrl.addApproverForm.role.$setValidity("unique", true);
            cancelContractCtrl.addApproverForm.$setPristine();
        };

        cancelContractCtrl.addApprover = function (newApprover) {
            cancelContractCtrl.addApproverForm.approver.$setValidity(
                "unique",
                !_.some(cancelContractCtrl.proposalApprovers, ["user_id", newApprover.user_id])
            );
            cancelContractCtrl.addApproverForm.role.$setValidity(
                "unique",
                !_.some(cancelContractCtrl.proposalApprovers, ["role_id", newApprover.role_id])
            );

            if (cancelContractCtrl.addApproverForm.$invalid) {
                return;
            }

            cancelContractCtrl.proposalApprovers.push(newApprover);

            cancelContractCtrl.resetForm();
        };

        cancelContractCtrl.removeApprover = function (approver) {
            _.remove(cancelContractCtrl.proposalApprovers, function (proposalApprovers) {
                return approver.user_id == proposalApprovers.user_id;
            });
        };
    },
]);
