angular.module("admin.semesters.semester.contracts.notes.create", []).controller("CreateContractsNoteCtrl", [
    "contracts",
    "$uibModalInstance",
    function (contracts, $uibModalInstance) {
        var createContractsNoteCtrl = this;

        createContractsNoteCtrl.contracts = contracts;

        createContractsNoteCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        createContractsNoteCtrl.save = function (contractNote) {
            $uibModalInstance.close(contractNote);
        };
    },
]);
