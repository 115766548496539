angular
    .module("admin.instructors.create", ["app.models.terms"])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.instructors.create", {
            url: "/create",
            views: {
                create: {
                    templateUrl: "admin/instructors/create/instructor-create.tmpl.html",
                    controller: "CreateInstructorCtrl as createInstructorCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Create Instructor",
            },
        });
    })
    .controller("CreateInstructorCtrl", [
        "$state",
        "$timeout",
        "InstructorsModel",
        "InstructorsFactory",
        "Loader",
        "AlertFactory",
        function ($state, $timeout, InstructorsModel, InstructorsFactory, Loader, AlertFactory) {
            var createInstructorCtrl = this;

            function returnToInstructors() {
                $state.go("admin.instructors");
            }

            function cancelCreating() {
                returnToInstructors();
            }

            function createInstructor(instructor) {
                Loader.showLoading("Creating Instructor");
                InstructorsModel.createInstructor(instructor)
                    .then(
                        function (instructor) {
                            $state.go("admin.instructors.instructor", { instructorId: instructor.user_id });
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_(result.errors).values().flatten().value().join("\r\n"), "danger");

                                $timeout(function () {
                                    _.forOwn(result.errors, function (value, key) {
                                        if (createInstructorCtrl.createInstructorForm[key]) {
                                            createInstructorCtrl.createInstructorForm[key].$invalid = true;
                                        }
                                    });
                                });
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            }

            function resetForm() {
                createInstructorCtrl.newInstructor = {
                    first_name: "",
                    last_name: "",
                    ums_id: "",
                    email: "",
                    college: "",
                    department: "",
                    unit: "",
                    status: "",
                    status_change_date: "",
                    position_no: "",
                    rank: "",
                    credit_hr_rate: "",
                    patfa_service_credits: "",
                    months_in_work_year: "",
                    job_code: "",
                    faculty_type: "",
                    address: "",
                    city: "",
                    state: "",
                    zip: "",
                };
            }

            function setPredefinedField(field) {
                InstructorsFactory.getPredefinedField(field).then(function (items) {
                    createInstructorCtrl[field + "_options"] = items;
                });
            }

            _.forEach(InstructorsModel.predefinedFields(), function (field) {
                setPredefinedField(field);
            });

            createInstructorCtrl.updatePredefinedField = function (field) {
                InstructorsFactory.updatePredefinedField(field).then(function (isUpdated) {
                    if (isUpdated) {
                        setPredefinedField(field);
                    }
                });
            };

            createInstructorCtrl.selectOptions = InstructorsFactory.getSelectOptions();
            createInstructorCtrl.cancelCreating = cancelCreating;
            createInstructorCtrl.createInstructor = createInstructor;

            resetForm();
        },
    ]);
