angular.module("admin", [
    "admin.notes",
    "admin.semesters",
    "admin.instructors",
    "admin.approvers",
    "admin.users",
    "admin.audits",
    "admin.courses",
    "admin.templates",
]);
