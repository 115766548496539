angular.module("admin.semesters.semester.warnings.notes.delete", []).controller("DeleteWarningNoteCtrl", [
    "warningNote",
    "$uibModalInstance",
    function (warningNote, $uibModalInstance) {
        var deleteWarningNoteCtrl = this;

        deleteWarningNoteCtrl.warningNote = warningNote;

        deleteWarningNoteCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        deleteWarningNoteCtrl.delete = function () {
            $uibModalInstance.close(warningNote.id);
        };
    },
]);
