angular.module("app.factories.instructors", []).factory("InstructorsFactory", [
    "$window",
    "$timeout",
    "$q",
    "$filter",
    "$uibModal",
    "InstructorsModel",
    "UsersFactory",
    function ($window, $timeout, $q, $filter, $uibModal, InstructorsModel, UsersFactory) {
        var instructorsFactory = this;
        var editableInstructor;

        var fillable = InstructorsModel.fillable();
        var labels = InstructorsModel.labels();
        var groups = InstructorsModel.groups();
        var dates = InstructorsModel.dates();
        var times = InstructorsModel.times();
        var numbers = InstructorsModel.numbers();
        var decimals = InstructorsModel.decimals();
        var booleans = InstructorsModel.booleans();
        var statuses = InstructorsModel.statuses();

        function getSelectOptions() {
            return [
                {
                    label: "Yes",
                    value: true,
                },
                {
                    label: "No",
                    value: false,
                },
            ];
        }

        function showEdit() {
            var editable = this;
            if (UsersFactory.hasPermission("edit-instructors") && !editable.editing) {
                editable.visible = true;
            }
        }

        function hideEdit() {
            this.visible = false;
        }

        function edit(model) {
            this.visible = false;
            this.editing = true;

            if (model) {
                model.$setViewValue(model.$viewValue ? model.$viewValue : "");
                model.$render();
            }
        }

        function cancelEdit() {
            this.value = editableInstructor[this.key];
            this.editing = false;
        }

        function saveEdit() {
            var editable = this;
            var deferred = $q.defer();

            if (editableInstructor[editable.key] == editable.value) {
                editable.editing = false;
                editable.error = false;
                deferred.resolve();
                return deferred.promise;
            }

            editable.saving = true;

            var instructor = {};
            instructor.user_id = editableInstructor.user_id;
            instructor[editable.key] = editable.value;

            InstructorsModel.updateInstructor(instructor)
                .then(
                    function (result) {
                        editableInstructor[editable.key] = editable.value;
                        editable.editing = false;
                        editable.error = false;
                        deferred.resolve(result);
                    },
                    function (result) {
                        editable.value = editableInstructor[editable.key];
                        editable.error = true;
                        deferred.reject(result);
                    }
                )
                .finally(function () {
                    editable.saving = false;
                });

            return deferred.promise;
        }

        instructorsFactory.getEditable = function (instructor) {
            var editable = {};

            _.forOwn(instructor, function (value, key) {
                if (!editable[key]) {
                    editable[key] = {};
                    editable[key].key = key;
                    editable[key].value = value;
                    editable[key].visible = false;
                    editable[key].error = false;
                    editable[key].editing = false;
                    editable[key].showEdit = showEdit;
                    editable[key].hideEdit = hideEdit;
                    editable[key].edit = edit;
                    editable[key].cancelEdit = cancelEdit;
                    editable[key].saveEdit = saveEdit;
                }
            });

            editableInstructor = instructor;

            return editable;
        };

        function updatePredefinedField(field) {
            var deferred = $q.defer();

            var modalInstance = $uibModal.open({
                size: "lg",
                templateUrl: "admin/instructors/instructor/predefined-fields/instructor-predefined-fields.tmpl.html",
                controller: "InstructorPredfinedFieldsCtrl as instructorPredefinedFieldsCtrl",
                resolve: {
                    field: function () {
                        return field;
                    },
                },
            });

            modalInstance.result
                .then(function () {
                    deferred.resolve(true);
                })
                .catch(function (result) {
                    deferred.resolve(false);
                    if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                });

            return deferred.promise;
        }

        function getPredefinedField(field) {
            var deferred = $q.defer();

            InstructorsModel.getInstructorsMeta().then(function (instructorsMeta) {
                var predefinedField = [];

                predefinedField = _.find(instructorsMeta, function (meta) {
                    return meta.key == field;
                });

                if (!predefinedField) {
                    deferred.resolve([]);
                } else {
                    deferred.resolve(predefinedField.value.items);
                }
            });

            return deferred.promise;
        }

        function getSearchProperties() {
            var fields = [
                {
                    label: "All",
                    value: null,
                    is_hidden: 0,
                },
                {
                    label: "Employee ID",
                    value: "user.ums_id",
                    group: "User",
                    is_hidden: 0,
                },
                {
                    label: "First Name",
                    value: "user.first_name",
                    group: "User",
                    is_hidden: 0,
                },
                {
                    label: "Last Name",
                    value: "user.last_name",
                    group: "User",
                    is_hidden: 0,
                },
                {
                    label: "Email",
                    value: "user.email",
                    group: "User",
                    is_hidden: 0,
                },
            ];

            _.forEach(fillable, function (field) {
                fields.push({
                    value: field,
                    label: labels[field],
                    group: groups[field],
                    is_hidden: 0,
                });

                if (_.includes(dates, field) || _.includes(numbers, field)) {
                    fields.push({
                        value: field,
                        label: labels[field] + " (Range)",
                        group: groups[field],
                        is_range: 1,
                        is_hidden: 0,
                    });
                }
            });

            return fields;
        }

        function getSortProperties() {
            var fields = [
                {
                    value: null,
                    label: "None",
                },
            ];

            _.forEach(fillable, function (field) {
                fields.push({
                    value: field,
                    label: labels[field],
                    group: groups[field],
                });
            });

            return fields;
        }

        function label(prop) {
            if (labels[prop]) {
                return labels[prop];
            }

            return prop;
        }

        function format(prop, value) {
            if (_.includes(times, prop)) {
                return $filter("date")(value, "h:mm a");
            }

            if (_.includes(dates, prop)) {
                return $filter("date")(value, "M/d/yy");
            }

            if (_.includes(decimals, prop)) {
                return $filter("toFixed")(value, 2);
            }

            if (_.includes(booleans, prop)) {
                return value === null ? "Pending" : value === true ? "Yes" : value === false ? "No" : "";
            }

            return value;
        }

        function getSearch() {
            var searchProps = getSearchProperties();
            var sortProps = getSortProperties();
            var currentSortType = "user.last_name";

            var search = {
                searchProps: searchProps,
                sortProps: sortProps,
                searchCol: searchProps[0],
                sortCol: sortProps[0],
                sortType: "user.last_name",
                sortReverse: false,
                limitTo: 25,
                totalItems: 0,
                orderBy: "user.last_name",
                searchGT: null,
                searchLT: null,
                value: "",
                currentPage: 1,
                dates: dates,
                times: times,
                booleans: booleans,
                numbers: numbers,
                statuses: statuses,
                partialMatches: ["section_no"],
                filters: [],
                searchCols: [],
                get: function () {
                    var filter = {};
                    var searchGT = this.searchGT;
                    var searchLT = this.searchLT;
                    var col = this.searchCol.value;
                    var value = null;

                    if (col && this.searchCol.is_range && _.includes(this.dates, col)) {
                        value = this.value;
                        return function (contract) {
                            if (value == "!!") {
                                if (searchGT && searchLT) {
                                    return (
                                        new Date(contract[col]).setHours(0, 0, 0, 0) <
                                            new Date(searchGT).setHours(0, 0, 0, 0) ||
                                        new Date(contract[col]).setHours(0, 0, 0, 0) >
                                            new Date(searchLT).setHours(0, 0, 0, 0)
                                    );
                                } else if (searchGT) {
                                    return (
                                        new Date(contract[col]).setHours(0, 0, 0, 0) <
                                        new Date(searchGT).setHours(0, 0, 0, 0)
                                    );
                                } else if (searchLT) {
                                    return (
                                        new Date(contract[col]).setHours(0, 0, 0, 0) >
                                        new Date(searchLT).setHours(0, 0, 0, 0)
                                    );
                                } else {
                                    return contract[col] != null;
                                }
                            }

                            if (searchGT && searchLT) {
                                return (
                                    new Date(contract[col]).setHours(0, 0, 0, 0) >=
                                        new Date(searchGT).setHours(0, 0, 0, 0) &&
                                    new Date(contract[col]).setHours(0, 0, 0, 0) <=
                                        new Date(searchLT).setHours(0, 0, 0, 0)
                                );
                            } else if (searchGT) {
                                return (
                                    new Date(contract[col]).setHours(0, 0, 0, 0) >=
                                    new Date(searchGT).setHours(0, 0, 0, 0)
                                );
                            } else if (searchLT) {
                                return (
                                    new Date(contract[col]).setHours(0, 0, 0, 0) <=
                                    new Date(searchLT).setHours(0, 0, 0, 0)
                                );
                            } else {
                                return contract[col] == null;
                            }
                        };
                    } else if (col && this.searchCol.is_range) {
                        value = this.value;
                        return function (contract) {
                            if (value == "!!") {
                                if (searchGT && searchLT) {
                                    return (
                                        parseInt(contract[col]) < parseInt(searchGT) ||
                                        parseInt(contract[col]) > parseInt(searchLT)
                                    );
                                } else if (searchGT) {
                                    return parseInt(contract[col]) < parseInt(searchGT);
                                } else if (searchLT) {
                                    return parseInt(contract[col]) > parseInt(searchLT);
                                } else {
                                    return contract[col] != null;
                                }
                            }

                            if (searchGT && searchLT) {
                                return (
                                    parseInt(contract[col]) >= parseInt(searchGT) &&
                                    parseInt(contract[col]) <= parseInt(searchLT)
                                );
                            } else if (searchGT) {
                                return parseInt(contract[col]) >= parseInt(searchGT);
                            } else if (searchLT) {
                                return parseInt(contract[col]) <= parseInt(searchLT);
                            } else {
                                return contract[col] != null;
                            }
                        };
                    } else if (col) {
                        value = this.value;

                        if (_.includes(this.dates, col) || _.includes(this.times, col)) {
                            value = angular.fromJson(angular.toJson(this.value));
                        }

                        if (!value && value !== 0 && value !== false) {
                            value = null;
                        }

                        filter = _.set(filter, col, value);
                    } else {
                        filter = this.value;
                    }

                    return filter;
                },
                dateFilter: function () {
                    var filter = {};

                    if (!_.includes(this.dates, this.searchCol.value)) {
                        return filter;
                    }

                    if (this.searchCol.value) {
                        var value = this.value;
                        if (!value) {
                            value = null;
                        }
                        filter[this.searchCol.value] = value;
                    }

                    return filter;
                },
                stringComparator: function (a, b) {
                    return typeof a === "string" && typeof b === "string"
                        ? a.trim().toUpperCase() === b.trim().toUpperCase()
                        : angular.equals(a, b);
                },
                numberComparator: function (a, b) {
                    return parseFloat(a) == parseFloat(b);
                },
                dateComparator: function (a, b) {
                    return angular.equals(new Date(a).setHours(0, 0, 0, 0), new Date(b).setHours(0, 0, 0, 0));
                },
                timeComparator: function (a, b) {
                    var dateA = new Date(a);
                    var dateB = new Date(b);

                    if (!b) {
                        return a == b;
                    }

                    return (
                        angular.equals(dateA.getHours(), dateB.getHours()) &&
                        angular.equals(dateA.getMinutes(), dateB.getMinutes())
                    );
                },
                updateSortCol: function () {
                    var searchCol = this.searchCol;
                    if (this.searchCol.value) {
                        var index = _.findIndex(this.sortProps, function (property) {
                            return property.value == searchCol.value;
                        });
                        if (index >= 0) {
                            this.sortCol = this.sortProps[index];
                        }
                    }
                    this.value = null;
                },
                pageYOffset: null,
                minLimitTo: null,
                resetLimitTo: function () {
                    if (this.limitTo > this.minLimitTo) {
                        $timeout(function () {
                            search.limitTo = search.minLimitTo || search.limitTo;
                        });
                    }
                },
                setLimitTo: function (totalItems) {
                    if (this.limitTo < totalItems) {
                        if (!this.pageYOffset) {
                            this.pageYOffset = $window.pageYOffset;
                        }

                        if (this.pageYOffset && !this.minLimitTo) {
                            this.minLimitTo = this.limitTo;
                        }

                        this.limitTo += 25;
                    }
                },
                statusOrder: function (item) {
                    var value = _.get(item, currentSortType);
                    if (value === null) return 1;
                    if (value === false) return 2;
                    if (value === true) return 3;
                    return 4;
                },
                numberOrder: function (item) {
                    return +_.get(item, currentSortType);
                },
                sort: function (sortType) {
                    this.limitTo = this.minLimitTo || this.limitTo;
                    this.sortReverse = !this.sortReverse;
                    this.sortType = sortType;

                    currentSortType = sortType;

                    if (_.includes(this.numbers, sortType)) {
                        this.orderBy = [this.numberOrder, sortType];
                    } else if (_.includes(this.statuses, sortType)) {
                        this.orderBy = [this.statusOrder, sortType];
                    } else if (sortType == "semester") {
                        this.orderBy = ["semester.year", "semester.term.weight"];
                    } else {
                        this.orderBy = sortType;
                    }

                    this.currentPage = 1;
                },
                format: format,
                hasSearchCol: function (searchCol) {
                    return this.searchCol.value == searchCol || _.includes(_.map(this.searchCols, "value"), searchCol);
                },
                filterContract: function (contract) {
                    var filter = {};
                    filter.expression = { id: "!" + contract.id };
                    var searchCol = {};
                    searchCol.label = "Contract ID";
                    searchCol.value = "id";
                    searchCol.is_hidden = 0;
                    filter.searchCol = searchCol;
                    filter.searchVal = this.format("id", contract.id);
                    filter.comparator = this.numberComparator;
                    filter.html = "Contract ID: " + contract.id;
                    filter.value = contract.id;
                    filter.type = "excluded";

                    this.filters.push(filter);
                },
                isSearchColHidden: function () {
                    if (!this.searchCol.value) {
                        return true;
                    }

                    if (_.includes(this.defaultCols, this.searchCol.value)) {
                        return true;
                    }

                    return false;
                },
                isSortColHidden: function () {
                    if (!this.sortCol.value) {
                        return true;
                    }

                    if (this.sortCol.value == this.searchCol.value) {
                        return true;
                    }

                    if (_.includes(this.defaultCols, this.sortCol.value)) {
                        return true;
                    }

                    if (_.includes(_.values(_.map(this.searchCols, "value")), this.sortCol.value)) {
                        return true;
                    }

                    return false;
                },
                isReplaceColHidden: function (replaceCol) {
                    if (!replaceCol) {
                        return true;
                    }

                    if (replaceCol == this.searchCol.value) {
                        return true;
                    }

                    if (replaceCol == this.sortCol.value) {
                        return true;
                    }

                    if (_.includes(this.defaultCols, replaceCol)) {
                        return true;
                    }

                    if (_.includes(_.values(_.map(this.searchCols, "value")), replaceCol)) {
                        return true;
                    }

                    return false;
                },
                defaultCols: [
                    "user.ums_id",
                    "user.last_name",
                    "user.first_name",
                    "user.email",
                    "college",
                    "department",
                    "unit",
                    "status",
                    "rank",
                    "position_no",
                    "credit_hr_rate",
                    "months_in_work_year",
                ],
                defaultColsFilter: function (defaultCols) {
                    return function (col) {
                        return !_.includes(defaultCols, col.value);
                    };
                },
                cleanUp: function () {
                    window.off("scroll", scrollYHandler);
                },
            };

            var window = angular.element($window);

            var scrollYHandler = _.throttle(function (e) {
                if (search.pageYOffset && $window.pageYOffset + 100 < search.pageYOffset) {
                    search.resetLimitTo();
                }
            }, 100);

            window.on("scroll", scrollYHandler);

            return search;
        }

        function getRowClass(instructor) {
            if (!instructor.status) {
                return;
            }

            var status = instructor.status.trim().toLowerCase();

            if (status == "terminated") {
                return "danger";
            }

            if (status == "retired" || status == "sabbatical") {
                return "warning";
            }
        }

        instructorsFactory.getPredefinedField = getPredefinedField;
        instructorsFactory.updatePredefinedField = updatePredefinedField;

        instructorsFactory.getSelectOptions = getSelectOptions;
        instructorsFactory.label = label;
        instructorsFactory.format = format;
        instructorsFactory.getSearch = getSearch;

        instructorsFactory.getRowClass = getRowClass;

        return instructorsFactory;
    },
]);
