angular
    .module("admin.instructors.instructor.contracts.contract.revisions", [
        "admin.instructors.instructor.contracts.contract.revisions.revision",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.instructors.instructor.contracts.contract.revisions", {
            abstract: true,
            url: "/revisions",
            views: {
                "": {
                    templateUrl:
                        "admin/instructors/instructor/contracts/contract/revisions/contract-revisions.tmpl.html",
                    controller: "AdminInstructorContractRevisionsCtrl as contractsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Revisions",
            },
        });
    })
    .controller("AdminInstructorContractRevisionsCtrl", [
        "contract",
        "$scope",
        "$state",
        "$stateParams",
        "$timeout",
        "Loader",
        function (contract, $scope, $state, $stateParams, $timeout, Loader) {
            var contractsCtrl = this;
            var revisionId = $stateParams.revisionId;

            contractsCtrl.revisionId = revisionId;
            contractsCtrl.revisions = contract.revisions;

            contractsCtrl.showRevision = function (revisionId) {
                Loader.showLoading("Loading Revision");
                $timeout(function () {
                    $state.go("admin.instructors.instructor.contracts.contract.revisions.revision", {
                        revisionId: revisionId,
                    });
                    Loader.hideLoading();
                });
            };

            $scope.$on("contract.revisions.update", function (event, revisionId) {
                $timeout(function () {
                    contractsCtrl.revisionId = revisionId;
                });
            });
        },
    ]);
