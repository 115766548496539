angular.module("admin.approvers.approver.members.remove", []).controller("RemoveApproverMemberCtrl", [
    "$scope",
    "$state",
    "$uibModalInstance",
    "member",
    function ($scope, $state, $uibModalInstance, member) {
        var removeApproverMemberCtrl = this;

        removeApproverMemberCtrl.member = member;

        removeApproverMemberCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        removeApproverMemberCtrl.remove = function () {
            $uibModalInstance.close();
        };
    },
]);
