angular
    .module("admin.approvers", [
        "app.models.approvers",
        "app.factories.approvers",
        "admin.approvers.import",
        "admin.approvers.create",
        "admin.approvers.delete",
        "admin.approvers.approver",
        "infinite-scroll",
        "angular.filter",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.approvers", {
            url: "/approvers",
            views: {
                "": {
                    templateUrl: "admin/approvers/approvers.tmpl.html",
                    controller: "AdminApproversCtrl as approversCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Approvers",
            },
        });
    })
    .controller("AdminApproversCtrl", [
        "$scope",
        "$filter",
        "$timeout",
        "Loader",
        "ApproversModel",
        "AlertFactory",
        function ($scope, $filter, $timeout, Loader, ApproversModel, AlertFactory) {
            var approversCtrl = this;

            approversCtrl.limitTo = 25;

            approversCtrl.searchProps = [
                {
                    label: "All",
                    value: null,
                },
                {
                    label: "First Name",
                    value: "user.first_name",
                },
                {
                    label: "Last Name",
                    value: "user.last_name",
                },
                {
                    label: "Email",
                    value: "user.email",
                },
                {
                    label: "College",
                    value: "college",
                },
                {
                    label: "Dept",
                    value: "department",
                },
                {
                    label: "Role",
                    value: "role.alt_display_name",
                },
                {
                    label: "Archived?",
                    value: "is_archived",
                },
            ];

            approversCtrl.searchCol = approversCtrl.searchProps[0];

            approversCtrl.sortType = "user.last_name";

            function getApprovers() {
                approversCtrl.isLoading = true;

                ApproversModel.getApprovers()
                    .then(function (approvers) {
                        _.forEach(approvers, function (approver) {
                            approver.isDirector = _.includes(_.map(approver.user.roles, "name"), "director");
                            approver.role.alt_display_name = approver.role.display_name;

                            if (approver.isDirector) {
                                approver.role.alt_display_name = "DLL Director, " + approver.role.alt_display_name;
                            }
                        });

                        approversCtrl.approvers = approvers;
                    })
                    .finally(function () {
                        $timeout(function () {
                            approversCtrl.isLoading = false;
                        });
                    });
            }

            getApprovers();

            // Need for import
            $scope.$on("approvers.update", function () {
                getApprovers();
            });

            approversCtrl.setLimitTo = function (totalItems) {
                if (approversCtrl.limitTo < totalItems) {
                    approversCtrl.limitTo += 25;
                }
            };

            approversCtrl.sort = function (sortType) {
                approversCtrl.sortReverse = !approversCtrl.sortReverse;
                approversCtrl.sortType = sortType;
            };

            approversCtrl.filterSearch = function () {
                var filter = {};

                if (approversCtrl.searchCol.value) {
                    var values = approversCtrl.searchCol.value.split(".");

                    if (values.length > 1) {
                        filter[values[0]] = {};
                        filter[values[0]][values[1]] = approversCtrl.search;
                    } else {
                        filter[approversCtrl.searchCol.value] = approversCtrl.search;
                    }
                } else {
                    filter = approversCtrl.search;
                }

                return filter;
            };

            approversCtrl.resetLimitTo = function () {
                if (approversCtrl.limitTo > 25) {
                    approversCtrl.limitTo = 25;
                }
            };

            approversCtrl.exportApprovers = function (data) {
                Loader.showLoading("Exporting Approvers");

                var date = new Date();
                date = $filter("date")(date, "M-d-yyyy");
                var fileName = "Approvers_" + date + ".xlsx";

                alasql.fn.datetime = function (date) {
                    if (date) {
                        date = new Date(date);
                        return $filter("date")(date, "M/d/yyyy");
                    }
                };

                alasql.fn.get = function (value) {
                    if (!value && value !== 0) {
                        return "";
                    }
                    return value;
                };

                alasql.fn.getBoolean = function (value) {
                    if (value) {
                        return "Yes";
                    }
                    return "No";
                };

                alasql.fn.getMembers = function (members) {
                    if (members && members.length) {
                        return _.map(members, function (member) {
                            return (
                                member.user.first_name + " " + member.user.last_name + " <" + member.user.email + ">"
                            );
                        }).join(", ");
                    }

                    return "";
                };

                alasql
                    .promise(
                        "SELECT " +
                            "get(user->ums_id) AS [Employee ID], " +
                            "get(user->last_name) AS [Last Name], " +
                            "get(user->first_name) AS [First Name], " +
                            "get(user->email) AS [Email], " +
                            "get(role->display_name) AS Role, " +
                            "get(college) AS College, " +
                            "get(department) AS Dept, " +
                            "getBoolean(is_archived) AS [Archived?], " +
                            "getMembers(members) AS [Support Staff] " +
                            'INTO XLSX("' +
                            fileName +
                            '", {headers:true}) FROM ?',
                        [data]
                    )
                    .then(function () {
                        Loader.hideLoading();
                    })
                    .catch(function (err) {
                        AlertFactory.show("Export Error", "danger");
                        console.log(err);
                        Loader.hideLoading();
                    });
            };
        },
    ]);
