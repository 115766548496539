var filters = angular.module("app.filters", []);

filters.filter("object2Array", function () {
    return function (input) {
        return _.toArray(input);
    };
});

filters.filter("toFixed", function () {
    return function (value, digits) {
        if (value == null || isNaN(value)) return value; // Handle null or invalid numbers
        try {
            return new Big(value).toFixed(digits);
        } catch (error) {
            console.error("Error in toFixed filter:", error);
            return value; // Fallback to original value if Big.js fails
        }
    };
});

filters.filter("filterAuditFields", [
    "$filter",
    function ($filter) {
        return function (array, fields) {
            if (fields && fields.length) {
                _.map(array, function (item) {
                    _.forOwn(item.modified, function (modified, key) {
                        if (!_.includes(_.map(fields, "value"), key)) {
                            delete item.modified[key];
                        }
                    });
                });
            }

            return array;
        };
    },
]);

filters.filter("customFilter", [
    "$filter",
    function ($filter) {
        return function (values, search) {
            var expression = search.get();
            var comparator = true;

            if (_.includes(search.dates, search.searchCol.value)) {
                comparator = search.dateComparator;
            } else if (_.includes(search.times, search.searchCol.value)) {
                comparator = search.timeComparator;
            } else if (search.partialMatches && _.includes(search.partialMatches, search.searchCol.value)) {
                comparator = false;
            } else if (_.includes(search.numbers, search.searchCol.value)) {
                comparator = search.numberComparator;
            } else if (search.searchCol.value) {
                comparator = search.stringComparator;
            } else if (!search.searchCol.value) {
                comparator = false;
            }

            values = $filter("filter")(values, expression, comparator);

            return values;
        };
    },
]);

filters.filter("dynamicFilter", [
    "$filter",
    function ($filter) {
        return function (values, filters) {
            _.forEach(filters, function (filter) {
                values = $filter("filter")(values, filter.expression, filter.comparator);
            });
            return values;
        };
    },
]);

filters.filter("emptyToEnd", function () {
    return function (array, key, nullExempt) {
        if (!angular.isArray(array)) return;

        var present = array.filter(function (item) {
            var value = _.get(item, key);

            if (nullExempt && nullExempt.indexOf(key) !== -1 && value === null) {
                return true;
            }

            return value || value === 0 || value === false;
        });

        var empty = array.filter(function (item) {
            var value = _.get(item, key);

            if (nullExempt && nullExempt.indexOf(key) !== -1 && value === null) {
                return false;
            }

            return !value && value !== 0 && value !== false;
        });

        return present.concat(empty);
    };
});
