angular.module("app.factories.users", []).factory("UsersFactory", [
    "$q",
    "UsersModel",
    function ($q, UsersModel) {
        var usersFactory = this;

        var editableUser;

        var showEdit = function () {
            var editable = this;
            if (usersFactory.hasPermission("edit-users") && !editable.editing) {
                editable.visible = true;
            }
        };

        var hideEdit = function () {
            this.visible = false;
        };

        var edit = function () {
            this.visible = false;
            this.editing = true;
        };

        var cancelEdit = function () {
            this.value = editableUser[this.key];
            this.editing = false;
        };

        var saveEdit = function () {
            var editable = this;
            var deferred = $q.defer();

            if (editableUser[editable.key] == editable.value) {
                editable.editing = false;
                editable.error = false;
                deferred.resolve(false);
                return deferred.promise;
            }

            editable.saving = true;

            var user = {};
            user.id = editableUser.id;
            user[editable.key] = editable.value;

            UsersModel.updateUser(user)
                .then(
                    function (result) {
                        editableUser[editable.key] = editable.value;
                        editable.editing = false;
                        editable.error = false;
                        deferred.resolve(result);
                    },
                    function (result) {
                        editable.error = true;
                        deferred.reject(result);
                    }
                )
                .finally(function () {
                    editable.saving = false;
                });

            return deferred.promise;
        };

        usersFactory.getEditable = function (user) {
            var editable = {};

            _.forOwn(user, function (value, key) {
                if (!editable[key]) {
                    editable[key] = {};
                    editable[key].key = key;
                    editable[key].value = value;
                    editable[key].visible = false;
                    editable[key].error = false;
                    editable[key].editing = false;
                    editable[key].showEdit = showEdit;
                    editable[key].hideEdit = hideEdit;
                    editable[key].edit = edit;
                    editable[key].cancelEdit = cancelEdit;
                    editable[key].saveEdit = saveEdit;
                }
            });

            editableUser = user;

            return editable;
        };

        usersFactory.hasPermission = function (permission) {
            var currentUser = UsersModel.getCurrentUser();

            var value = permission.trim();

            var notFlag = value[0] === "!";

            if (!_.isString(permission)) {
                return false;
            }

            if (!currentUser) {
                return false;
            }

            if (notFlag) {
                value = value.slice(1).trim();
            }

            var permissions = _.flatten(
                _.map(currentUser.roles, function (role) {
                    return _.map(role.perms, "name");
                })
            );

            var hasPermission = _.includes(permissions, value);

            if ((hasPermission && !notFlag) || (!hasPermission && notFlag)) {
                return true;
            }

            return false;
        };

        var label = function (field) {
            if (labels[field]) {
                return labels[field];
            }

            return field;
        };

        var format = function (field, value, contract) {
            if (_.includes(UsersModel.times(), field)) {
                value = $filter("date")(value, "h:mm a");
            } else if (_.includes(UsersModel.dates(), field)) {
                value = $filter("date")(value, "M/d/yy");
            } else if (_.includes(UsersModel.booleans(), field)) {
                if (value === true) {
                    value = "Yes";
                } else if (value === false) {
                    value = "No";
                } else {
                    value = "";
                }
            }

            return value;
        };

        usersFactory.label = label;

        usersFactory.format = format;

        return usersFactory;
    },
]);
