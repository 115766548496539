angular
    .module("admin.semesters.semester.contracts.contract.revisions", [
        "admin.semesters.semester.contracts.contract.revisions.revision",
        "admin.semesters.semester.contracts.contract.revisions.create",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.contracts.contract.revisions", {
            abstract: true,
            url: "/revisions",
            views: {
                "": {
                    templateUrl: "admin/semesters/contracts/contract/revisions/contract-revisions.tmpl.html",
                    controller: "AdminSemesterContractRevisionsCtrl as revisionsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Revisions",
            },
        });
    })
    .controller("AdminSemesterContractRevisionsCtrl", [
        "contract",
        "$scope",
        "$state",
        "$stateParams",
        "$timeout",
        "Loader",
        function (contract, $scope, $state, $stateParams, $timeout, Loader) {
            var revisionsCtrl = this;

            revisionsCtrl.revisionId = $stateParams.revisionId;
            revisionsCtrl.revisions = contract.revisions;

            revisionsCtrl.showRevision = function (revisionId) {
                Loader.showLoading("Loading Revision");
                $timeout(function () {
                    $state.go("admin.semesters.semester.contracts.contract.revisions.revision", {
                        revisionId: revisionId,
                    });
                    Loader.hideLoading();
                });
            };

            $scope.$on("revisionCreated", function (event, latestContract) {
                revisionsCtrl.revisions = latestContract.revisions;
            });

            $scope.$on("revisionIdUpdated", function (event, revisionId) {
                $timeout(function () {
                    revisionsCtrl.revisionId = revisionId;
                });
            });
        },
    ]);
