angular.module("app.models.courses", []).service("CoursesModel", [
    "$http",
    "$q",
    "API_URL",
    function ($http, $q, API_URL) {
        var model = this,
            API = {
                getCourses: function (params) {
                    return $http.get(API_URL + "courses", { params: params });
                },
                getSubjects: function (params) {
                    return $http.get(API_URL + "subjects", { params: params });
                },
                getCatalogs: function (params) {
                    return $http.get(API_URL + "catalogs", { params: params });
                },
                getSections: function (params) {
                    return $http.get(API_URL + "sections", { params: params });
                },
                getEmployees: function (params) {
                    return $http.get(API_URL + "employees", { params: params });
                },
                getCourse: function (contractId, params) {
                    return $http.get(API_URL + "courses/" + contractId, { params: params });
                },
            };

        function extract(result) {
            return result.data;
        }

        model.getCourses = function (params) {
            var deferred = $q.defer();

            API.getCourses(params).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.getSubjects = function (params) {
            var deferred = $q.defer();

            API.getSubjects(params).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.getCatalogs = function (params) {
            var deferred = $q.defer();

            API.getCatalogs(params).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.getSections = function (params) {
            var deferred = $q.defer();

            API.getSections(params).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.getEmployees = function (params) {
            var deferred = $q.defer();

            API.getEmployees(params).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.getCourse = function (contractId, params) {
            var deferred = $q.defer();

            API.getCourse(contractId, params).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };
    },
]);
