angular
    .module("admin.instructors.instructor.audits", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.instructors.instructor.audits", {
            url: "/audits",
            views: {
                "": {
                    templateUrl: "admin/instructors/instructor/audits/instructor-audits.tmpl.html",
                    controller: "AuditInstructorCtrl as auditInstructorCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Audits",
            },
        });
    })
    .controller("AuditInstructorCtrl", [
        "$state",
        "$stateParams",
        "$location",
        "$timeout",
        "InstructorsModel",
        "Loader",
        "LSFactory",
        "InstructorsFactory",
        function ($state, $stateParams, $location, $timeout, InstructorsModel, Loader, LSFactory, InstructorsFactory) {
            var auditInstructorCtrl = this;
            var instructorId = $stateParams.instructorId;

            var params = $location.search();

            if (_.isEmpty(params)) {
                params = LSFactory.get("instructorAuditsQuery");
            }

            auditInstructorCtrl.types = [
                {
                    key: "App\\Contract",
                    value: "Contract",
                },
                {
                    key: "App\\User",
                    value: "User",
                },
                {
                    key: "App\\Instructor",
                    value: "Instructor",
                },
                {
                    key: "App\\Approver",
                    value: "Approver",
                },
            ];

            auditInstructorCtrl.events = ["created", "updated", "deleted"];

            auditInstructorCtrl.fields = [];
            _.forEach(InstructorsModel.fields(), function (field) {
                if (!_.includes(["created_at", "updated_at"], field)) {
                    auditInstructorCtrl.fields.push({
                        value: field,
                        label: InstructorsModel.labels()[field],
                        group: InstructorsModel.groups()[field],
                    });
                }
            });

            if (!_.isEmpty(params)) {
                if (params.page) {
                    auditInstructorCtrl.page = parseInt(params.page);
                }

                if (params.event) {
                    auditInstructorCtrl.event = params.event;
                }

                if (params["field[]"]) {
                    auditInstructorCtrl.field = _.filter(auditInstructorCtrl.fields, function (field) {
                        return _.includes(params["field[]"], field.value);
                    });
                }

                updateQueryString();
            }

            function updateQueryString() {
                $timeout(function () {
                    LSFactory.set("instructorAuditsQuery", params);
                    $location.search(params);
                });
            }

            function getAudits() {
                Loader.showLoading("Loading Audits");
                InstructorsModel.getAudits(instructorId, params).then(function (result) {
                    auditInstructorCtrl.audits = result;
                    auditInstructorCtrl.users = result.users;

                    if (params && params["user[]"]) {
                        auditInstructorCtrl.user = _.filter(auditInstructorCtrl.users, function (user) {
                            return _.includes(params["user[]"], user.id);
                        });
                    }

                    Loader.hideLoading();
                });
            }

            auditInstructorCtrl.label = function (field) {
                $labels = InstructorsModel.labels();

                if ($labels[field]) {
                    return $labels[field];
                }

                return field;
            };

            auditInstructorCtrl.format = function (field, value) {
                value = InstructorsFactory.format(field, value);

                if (!value && value !== 0) {
                    value = "NULL";
                }

                return value;
            };

            var timeout;

            auditInstructorCtrl.search = function (keywords) {
                function searchAudits() {
                    params = {};

                    if (auditInstructorCtrl.page) {
                        params.page = parseInt(auditInstructorCtrl.page);
                    }

                    if (auditInstructorCtrl.event) {
                        params.event = auditInstructorCtrl.event;
                    }

                    if (auditInstructorCtrl.user) {
                        params["user[]"] = _.map(auditInstructorCtrl.user, "id");
                    }

                    if (auditInstructorCtrl.field) {
                        params["field[]"] = _.map(auditInstructorCtrl.field, "value");
                    }

                    updateQueryString();

                    getAudits();
                }

                if (timeout) {
                    $timeout.cancel(timeout);
                }

                if (keywords) {
                    timeout = $timeout(
                        function () {
                            searchAudits();
                        },
                        500,
                        false
                    );
                } else {
                    searchAudits();
                }
            };

            auditInstructorCtrl.clear = function (field) {
                if (field) {
                    if (field == "event") {
                        auditInstructorCtrl.event = null;
                    }
                } else {
                    auditInstructorCtrl.event = null;
                    auditInstructorCtrl.user = null;
                    auditInstructorCtrl.field = null;
                }
                auditInstructorCtrl.page = 1;
                auditInstructorCtrl.search();
            };

            getAudits();

            auditInstructorCtrl.getAudits = getAudits;
        },
    ]);
