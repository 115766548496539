angular.module('admin.instructors.replace', [])
    .controller('ReplaceInstructorsCtrl', ['replacement', 'instructors', '$scope', '$state', '$stateParams', '$uibModalInstance', 'Loader', 'AlertFactory', 'InstructorsFactory', 'InstructorsModel', 'SemestersModel', 'ApproversModel',
        function(replacement, instructors, $scope, $state, $stateParams, $uibModalInstance, Loader, AlertFactory, InstructorsFactory, InstructorsModel, SemestersModel, ApproversModel) {
            var replaceInstructorsCtrl = this;

            replaceInstructorsCtrl.field = replacement.field.label;
            replaceInstructorsCtrl.value = InstructorsFactory.format(replacement.field.value, replacement.value);
            replaceInstructorsCtrl.instructors = instructors;

            Loader.showLoading('Loading Semesters');
            SemestersModel.getSemesters().then(function(semesters) {
                if (semesters.length > 0) {
                    replaceInstructorsCtrl.semester = semesters[0];
                }
            }).finally(function() {
                Loader.hideLoading();
            });

            replaceInstructorsCtrl.cancel = function() {
                $uibModalInstance.dismiss();
            };

            replaceInstructorsCtrl.replace = function() {
                var _replacement = {};
                _replacement[replacement.field.value] = replacement.value;
                instructors = _.values(_.map(instructors, 'user_id'));

                Loader.showLoading('Updating Instructors');
                InstructorsModel.replaceInstructors(instructors, _replacement).then(function(result) {
                    if (result.errors) {
                        var messages = [];
                        _.forEach(result.errors, function(error) {
                            messages.push(error);
                        });
                        AlertFactory.show(messages.join("\r\n"), 'danger');
                    }
                }).finally(function() {
                    ApproversModel.clearCache();
                    SemestersModel.clearCache();
                    replaceInstructorsCtrl.cancel();
                    $state.go('admin.instructors');
                    Loader.hideLoading();
                });
            };

            $uibModalInstance.result.catch(function() { $uibModalInstance.close(); });
        }
    ]);