angular.module("admin.semesters.semester.contracts.export", ["ui.sortable"]).controller("ExportContractsCtrl", [
    "contracts",
    "spreadsheetGroups",
    "spreadsheet",
    "$uibModalInstance",
    "ContractsModel",
    "ContractsFactory",
    "Loader",
    "AlertFactory",
    "$timeout",
    function (
        contracts,
        spreadsheetGroups,
        spreadsheet,
        $uibModalInstance,
        ContractsModel,
        ContractsFactory,
        Loader,
        AlertFactory,
        $timeout
    ) {
        var exportContractsCtrl = this;
        var semester = contracts[0].semester;

        var fillable = ContractsModel.fillable(semester);
        var labels = ContractsModel.labels(semester);
        var groups = ContractsModel.groups();
        var months = ["month_one", "month_two", "month_three", "month_four", "month_five"];

        _.remove(fillable, function (field) {
            return _.includes(months, field);
        });

        _.forEach(fillable, function (field, i) {
            if (field == "amount_prev_paid") {
                fillable.splice(i + 1, 0, "months");
            }
        });
        labels.months = "Months";
        groups.months = "Compensation";

        exportContractsCtrl.fields = [];
        exportContractsCtrl.selected = [];
        exportContractsCtrl.addons = [];

        exportContractsCtrl.name = "Custom Spreadsheet";

        exportContractsCtrl.spreadsheetGroups = spreadsheetGroups;

        if (spreadsheet) {
            spreadsheet = angular.copy(spreadsheet);
            exportContractsCtrl.group = spreadsheet.value.group;
            exportContractsCtrl.name = spreadsheet.value.name;
            exportContractsCtrl.selected = spreadsheet.value.fields;
            exportContractsCtrl.isRevision = spreadsheet.value.isRevision;
            exportContractsCtrl.isCanc = spreadsheet.value.isCanc;
            if (exportContractsCtrl.isCanc) {
                exportContractsCtrl.isRevision = true;
            }
            exportContractsCtrl.isAccepted = spreadsheet.value.isAccepted;

            _.forEach(exportContractsCtrl.selected, function (field) {
                if (labels[field.value] && field.label != labels[field.value]) {
                    field.alt = field.label;
                }
                field.label = labels[field.value] ? labels[field.value] : field.label;
                field.value = field.value;
                field.isSelected = true;
                if (field.group) {
                    field.group = groups[field.value].toLowerCase();
                }
            });
            exportContractsCtrl.addons = _.filter(spreadsheet.value.fields, { type: "addon" });
        }

        _.forEach(fillable, function (field) {
            var isSelected = false;
            var alt = "";

            var selection = _.find(exportContractsCtrl.selected, function (selection) {
                return selection.value == field;
            });

            if (selection) {
                isSelected = true;
                alt = selection.alt ? selection.alt : "";
            }

            exportContractsCtrl.fields.push({
                label: labels[field],
                alt: alt,
                value: field,
                isSelected: isSelected,
                group: groups[field].toLowerCase(),
            });
        });

        exportContractsCtrl.addField = function () {
            var addon = {
                label: "",
                value: "",
                type: "addon",
                isSelected: true,
            };
            exportContractsCtrl.addons.push(addon);
            exportContractsCtrl.selected.push(addon);
        };

        exportContractsCtrl.removeField = function (field) {
            _.remove(exportContractsCtrl.addons, field);
            _.remove(exportContractsCtrl.selected, field);
        };

        exportContractsCtrl.toggleSelection = function (field) {
            if (field.isSelected) {
                field.isSelected = false;
                _.remove(exportContractsCtrl.selected, function (selection) {
                    return selection.value == field.value;
                });
            } else {
                field.isSelected = true;
                exportContractsCtrl.selected.push(field);
            }
        };

        exportContractsCtrl.isCompensationField = function (field) {
            var compensationFields = [
                "salary",
                "payments",
                "pay_start_date",
                "pay_end_date",
                "monthly",
                "hourly_rate",
                "payroll_date",
            ];

            if (exportContractsCtrl.isRevision && _.includes(compensationFields, field.value)) {
                return true;
            }

            return false;
        };

        exportContractsCtrl.isAdjCompensationField = function (field) {
            var adjCompensationFields = [
                "adj_payments",
                "adj_pay_start_date",
                "adj_pay_end_date",
                "adj_monthly",
                "adj_hourly_rate",
                "adj_payroll_date",
            ];

            if (!exportContractsCtrl.isCanc) {
                adjCompensationFields.push("adj_salary");
            }

            if (exportContractsCtrl.isRevision && _.includes(adjCompensationFields, field.value)) {
                return true;
            }

            return false;
        };

        exportContractsCtrl.removeAdjSalary = function () {
            $timeout(function () {
                // need to wait for checkbox model update
                var adjSalaryField = "adj_salary";
                if (!exportContractsCtrl.isCanc) {
                    _.forEach(exportContractsCtrl.fields, function (field) {
                        if (field.value == adjSalaryField) {
                            field.isSelected = false;
                        }
                    });
                    _.remove(exportContractsCtrl.selected, function (selection) {
                        return selection.value == adjSalaryField;
                    });
                }
            });
        };

        exportContractsCtrl.removeAdjCompensation = function () {
            $timeout(function () {
                // need to wait for checkbox model update
                var adjCompensationFields = [
                    "adj_payments",
                    "adj_pay_start_date",
                    "adj_pay_end_date",
                    "adj_payroll_date",
                ];

                if (!exportContractsCtrl.isCanc) {
                    adjCompensationFields.push("adj_salary");
                }

                if (exportContractsCtrl.isRevision) {
                    _.forEach(adjCompensationFields, function (adjCompensationField) {
                        _.forEach(exportContractsCtrl.fields, function (field) {
                            if (field.value == adjCompensationField) {
                                field.isSelected = false;
                            }
                        });
                        _.remove(exportContractsCtrl.selected, function (selection) {
                            return selection.value == adjCompensationField;
                        });
                    });
                } else {
                    exportContractsCtrl.isCanc = false;
                }
            });
        };

        function getMeta() {
            var meta = {};
            meta.key = "spreadsheet";
            meta.value = {};
            meta.value.group = exportContractsCtrl.group;
            meta.value.name = exportContractsCtrl.name;
            meta.value.fields = [];

            _.forEach(exportContractsCtrl.selected, function (field, i) {
                meta.value.fields.push({
                    label: field.alt ? field.alt : field.label,
                    value: field.value,
                });
                if (field.type) {
                    meta.value.fields[i].type = field.type;
                }
            });

            if (exportContractsCtrl.isRevision) {
                meta.value.isRevision = true;
                if (exportContractsCtrl.isCanc) {
                    meta.value.isCanc = true;
                }
            }

            if (exportContractsCtrl.isAccepted) {
                meta.value.isAccepted = true;
            }

            return meta;
        }

        exportContractsCtrl.export = function () {
            var meta = getMeta();

            ContractsFactory.exportSpreadsheet(contracts, meta);

            exportContractsCtrl.cancel();
        };

        exportContractsCtrl.saveExport = function (shouldExport) {
            if (!exportContractsCtrl.name || exportContractsCtrl.fields.length == 0) {
                return;
            }

            var meta = getMeta();

            Loader.showLoading("Saving Spreadsheet");

            if (spreadsheet && spreadsheet.id) {
                meta.id = spreadsheet.id;
                ContractsModel.updateContractsMeta(meta)
                    .then(function (meta) {
                        if (shouldExport) {
                            ContractsFactory.exportSpreadsheet(contracts, meta);
                        }
                        $uibModalInstance.close();
                    })
                    .catch(function (result) {
                        if (result.errors) {
                            AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                        }
                    })
                    .finally(function () {
                        Loader.hideLoading();
                    });
            } else {
                ContractsModel.createContractsMeta(meta)
                    .then(function (meta) {
                        if (shouldExport) {
                            ContractsFactory.exportSpreadsheet(contracts, meta);
                        }
                        $uibModalInstance.close();
                    })
                    .catch(function (result) {
                        if (result.errors) {
                            AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                        }
                    })
                    .finally(function () {
                        Loader.hideLoading();
                    });
            }
        };

        exportContractsCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };
    },
]);
