angular.module('admin.semesters.create', [
        'app.models.terms'
    ])
    .config(function($stateProvider) {
        $stateProvider
            .state('admin.semesters.create', {
                url: '/create',
                views: {
                    'create': {
                        templateUrl: 'admin/semesters/create/semester-create.tmpl.html',
                        controller: 'CreateSemesterCtrl as createSemesterCtrl'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Create Semester'
                }
            });
    })
    .directive("validYear", function() {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, elem, attr, ctrl) {
                ctrl.$validators.validYear = function(value) {
                    if (value === undefined || value === null || value === "") {
                        return true;
                    }

                    return moment(value, ["YYYY"], true).isValid();
                };
            }
        };
    })
    .controller('CreateSemesterCtrl', ['$state', 'TermsModel', 'SemestersModel', 'Loader', 'AlertFactory', function($state, TermsModel, SemestersModel, Loader, AlertFactory) {
        var createSemesterCtrl = this;

        var names = ['Fall', 'Spring', 'Summer'];

        var years = [];
        var today = new Date();
        var year = today.getFullYear();
        year -= 5;

        for (var i = 0; i <= 10; i++) {
            years.push(year++);
        }

        Loader.showLoading('Loading Terms');
        TermsModel.getTerms().then(function(terms) {
            createSemesterCtrl.terms = terms;
            Loader.hideLoading();
        });

        function returnToSemesters() {
            $state.go('admin.semesters');
        }

        function cancelCreating() {
            returnToSemesters();
        }

        function createSemester(semester) {
            Loader.showLoading('Creating Semester');
            SemestersModel.createSemester(semester).then(function(semester) {
                $state.go('admin.semesters.semester.contracts', { 'semesterId': semester.id });
            }, function(result) {
                if (result.errors) {
                    AlertFactory.show(_.values(result.errors).join("\r\n"), 'danger');
                }
            }).finally(function() {
                Loader.hideLoading();
            });
        }

        function resetForm() {
            createSemesterCtrl.newSemester = {
                name: '',
                year: ''
            };
        }

        createSemesterCtrl.names = names;
        createSemesterCtrl.years = years;

        createSemesterCtrl.cancelCreating = cancelCreating;
        createSemesterCtrl.createSemester = createSemester;

        resetForm();
    }]);