angular.module("admin.semesters.semester.contracts.replace", []).controller("ReplaceContractsCtrl", [
    "replacement",
    "isClearing",
    "contracts",
    "$uibModalInstance",
    "ContractsFactory",
    function (replacement, isClearing, contracts, $uibModalInstance, ContractsFactory) {
        var replaceContractsCtrl = this;

        replaceContractsCtrl.field = replacement.field.label;
        replaceContractsCtrl.value = ContractsFactory.format(replacement.field.value, replacement.value);
        replaceContractsCtrl.isClearing = isClearing;
        replaceContractsCtrl.contracts = contracts;

        replaceContractsCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        replaceContractsCtrl.replace = function () {
            $uibModalInstance.close();
        };
    },
]);
