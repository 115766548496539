angular
    .module("admin.courses", ["app.models.courses", "app.factories.courses"])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.courses", {
            url: "/courses",
            views: {
                "": {
                    templateUrl: "admin/courses/courses.tmpl.html",
                    controller: "AdminCoursesCtrl as coursesCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Course Histories",
            },
            cache: false,
        });
    })
    .controller("AdminCoursesCtrl", [
        "$state",
        "CoursesFactory",
        "ContractsFactory",
        "NotesFactory",
        function ($state, CoursesFactory, ContractsFactory, NotesFactory) {
            var coursesCtrl = this;

            coursesCtrl.showContract = function (contract) {
                $state.go("admin.semesters.semester.contracts.contract", {
                    semesterId: contract.semester_id,
                    contractId: contract.parent_id,
                });
            };

            CoursesFactory.initSearch(coursesCtrl);
            coursesCtrl.search = CoursesFactory.search;
            coursesCtrl.clear = CoursesFactory.clear;
            coursesCtrl.sort = CoursesFactory.sort;
            coursesCtrl.getRowClass = ContractsFactory.getRowClass;
            coursesCtrl.showNotes = NotesFactory.showNotes;

            coursesCtrl.getStatusPopoverHTML = ContractsFactory.getStatusPopoverHTML;
            coursesCtrl.getStatusHTML = ContractsFactory.getStatusHTML;
        },
    ]);
