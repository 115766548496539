angular.module("admin.approvers.delete", []).controller("DeleteApproverCtrl", [
    "approver",
    "$state",
    "$uibModalInstance",
    "ApproversModel",
    "UsersModel",
    "SemestersModel",
    "Loader",
    "AlertFactory",
    function (approver, $state, $uibModalInstance, ApproversModel, UsersModel, SemestersModel, Loader, AlertFactory) {
        var deleteApproverCtrl = this;

        deleteApproverCtrl.approver = approver;

        deleteApproverCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        deleteApproverCtrl.delete = function () {
            Loader.showLoading("Deleting Approver");
            ApproversModel.deleteApprover(approver)
                .then(
                    function () {
                        UsersModel.clearCache();
                        SemestersModel.clearCache();
                        $state.go("admin.approvers");
                    },
                    function (result) {
                        if (result.errors) {
                            var messages = [];
                            _.forEach(result.errors, function (error) {
                                messages.push(error);
                            });
                            AlertFactory.show(messages.join("\r\n"), "danger");
                        }
                    }
                )
                .finally(function () {
                    deleteApproverCtrl.cancel();
                    Loader.hideLoading();
                });
        };

        $uibModalInstance.result.catch(function () {
            $uibModalInstance.close();
        });
    },
]);
