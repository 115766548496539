angular.module('app.models.terms', [])
    .service('TermsModel', ['$http', '$q', 'API_URL', function($http, $q, API_URL) {
        var model = this,
            terms,
            API = {
                getTerms: function() {
                    return $http.get(API_URL + 'terms');
                },
                getTerm: function(termId) {
                    return $http.get(API_URL + 'terms/' + termId);
                },
                createTerm: function(term) {
                    return $http.post(API_URL + 'terms', term);
                },
                updateTerm: function(term) {
                    return $http.patch(API_URL + 'terms/' + term.id, term);
                },
                deleteTerm: function(term) {
                    return $http.delete(API_URL + 'terms/' + term.id);
                }
            };

        function extract(result) {
            return result.data;
        }

        function cacheTerms(result) {
            terms = extract(result);

            return terms;
        }

        model.getTerms = function() {
            if (terms) {
                return $q.when(terms);
            } else {
                return API.getTerms().then(cacheTerms);
            }
        };

        model.getTerm = function(termId) {
            var deferred = $q.defer();

            function findTerm() {
                return _.find(terms, function(term) {
                    return term.id === termId;
                });
            }

            if (terms) {
                deferred.resolve(findTerm());
            } else {
                model.getTerms().then(function() {
                    deferred.resolve(findTerm());
                });
            }

            return deferred.promise;
        };

        model.createTerm = function(term) {
            var deferred = $q.defer();

            API.createTerm(term).then(function(result) {
                var term = extract(result).data;
                terms.push(term);
                deferred.resolve(term);
            }, function(result) {
                deferred.reject(extract(result));
            });

            return deferred.promise;
        };

        model.updateTerm = function(term) {
            API.updateTerm(term);
        };

        model.deleteTerm = function(term) {
            var deferred = $q.defer();

            API.deleteTerm(term).success(function() {
                _.remove(terms, function(_term) {
                    return _term.id === term.id;
                });
            }).finally(function() {
                deferred.resolve();
            });

            return deferred.promise;
        };
    }]);