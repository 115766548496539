angular.module("admin.instructors.notes.create", []).controller("CreateInstructorsNoteCtrl", [
    "instructors",
    "$uibModalInstance",
    function (instructors, $uibModalInstance) {
        var createInstructorsNoteCtrl = this;

        createInstructorsNoteCtrl.instructors = instructors;

        createInstructorsNoteCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        createInstructorsNoteCtrl.save = function (note) {
            $uibModalInstance.close(note);
        };
    },
]);
