angular.module('app.models.audits', [])
    .service('AuditsModel', ['$http', '$q', 'API_URL', function($http, $q, API_URL) {
        var model = this,
            audits,
            API = {
                /**
                 * Audits
                 */

                getAudits: function(params) {
                    return $http.get(API_URL + 'audits', { params: params });
                },
                getAudit: function(auditId) {
                    return $http.get(API_URL + 'audits/' + auditId);
                },
            };

        function extract(result) {
            return result.data;
        }

        model.getAudits = function(params) {
            var deferred = $q.defer();

            API.getAudits(params).then(function(result) {
                deferred.resolve(extract(result));
            }, function(result) {
                deferred.reject(extract(result));
            });

            return deferred.promise;
        };

        model.getAudit = function(auditId) {
            var deferred = $q.defer();

            function findAudit() {
                return _.find(audits, function(audit) {
                    return audit.id == auditId;
                });
            }

            if (findAudit()) {
                deferred.resolve(findAudit());
            } else {
                API.getAudit(auditId).then(function(result) {
                    deferred.resolve(extract(result));
                }, function(result) {
                    deferred.reject(extract(result));
                });
            }

            return deferred.promise;
        };
    }]);