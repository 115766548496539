angular
    .module("admin.instructors.instructor.contracts", [
        "app.models.contracts",
        "app.factories.contracts",
        "admin.instructors.instructor.contracts.contract",
        "infinite-scroll",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.instructors.instructor.contracts", {
            url: "/contracts",
            views: {
                "": {
                    templateUrl: "admin/instructors/instructor/contracts/contracts.tmpl.html",
                    controller: "AdminInstructorContractsCtrl as contractsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Contracts",
            },
        });
    })
    .controller("AdminInstructorContractsCtrl", [
        "$state",
        "$stateParams",
        "$timeout",
        "InstructorsModel",
        "ContractsModel",
        "Loader",
        "ContractsFactory",
        function ($state, $stateParams, $timeout, InstructorsModel, ContractsModel, Loader, ContractsFactory) {
            var contractsCtrl = this;
            var instructorId = $stateParams.instructorId;

            contractsCtrl.excludeFields = function (item) {
                return ![
                    "semester_term_code",
                    "department",
                    "subject",
                    "course_catalog",
                    "section_no",
                    "is_completed",
                    "is_approved",
                    "is_accepted",
                    "in_proposal",
                    "updated_at",
                ].includes(item.value);
            };

            contractsCtrl.isLoading = true;
            contractsCtrl.contracts = [];
            contractsCtrl.currentPage = 1;
            contractsCtrl.show = false;
            contractsCtrl.search = ContractsFactory.getSearch();
            contractsCtrl.search.defaultCols.push("semester_term_code");
            contractsCtrl.statuses = ContractsModel.statuses();

            function getContracts() {
                return InstructorsModel.getContracts(instructorId)
                    .then(function (contracts) {
                        contractsCtrl.contracts = contracts;

                        contractsCtrl.filteredContracts = contractsCtrl.contracts;

                        contractsCtrl.contract = contracts[0];

                        contractsCtrl.totalItems = contractsCtrl.contracts.length;

                        if ($state.current.name === "admin.instructors.instructor.contracts.contract") {
                            contractsCtrl.show = true;
                            var contractId = $stateParams.contractId;
                            var index = _.findIndex(contractsCtrl.contracts, function (contract) {
                                return contract.id == contractId;
                            });
                            contractsCtrl.currentPage = index + 1;
                        }
                    })
                    .catch(function () {
                        $state.go("admin.instructors.instructor");
                    })
                    .finally(function () {
                        $timeout(function () {
                            contractsCtrl.isLoading = false;
                        });
                    });
            }

            contractsCtrl.contracts = getContracts();

            ContractsModel.getContractsMeta().then(function (meta) {
                contractsCtrl.meta = meta;
            });

            contractsCtrl.showContract = function (contract) {
                Loader.showLoading("Loading Contract");
                $timeout(function () {
                    contractsCtrl.limitTo = 25;

                    contractsCtrl.show = true;
                    if (!contractsCtrl.filteredContracts || contractsCtrl.filteredContracts.length === 0) {
                        contractsCtrl.filteredContracts = contractsCtrl.contracts;
                    }

                    if (contract) {
                        var index = _.findIndex(contractsCtrl.filteredContracts, function (_contract) {
                            return _contract.id == contract.id;
                        });
                        contractsCtrl.currentPage = index + 1;
                    } else {
                        contract = contractsCtrl.filteredContracts[contractsCtrl.currentPage - 1];
                    }

                    contract = contractsCtrl.filteredContracts[contractsCtrl.currentPage - 1];

                    $state.go("admin.instructors.instructor.contracts.contract", { contractId: contract.id });
                    Loader.hideLoading();
                });
            };

            contractsCtrl.isState = ContractsFactory.isState;
            contractsCtrl.includesState = ContractsFactory.includesState;

            contractsCtrl.getStatusPopoverHTML = ContractsFactory.getStatusPopoverHTML;
            contractsCtrl.getStatusHTML = ContractsFactory.getStatusHTML;

            contractsCtrl.exportCustomSpreadsheet = ContractsFactory.exportCustomSpreadsheet;
            contractsCtrl.exportSpreadsheet = ContractsFactory.exportSpreadsheet;
            contractsCtrl.deleteSpreadsheet = ContractsFactory.deleteSpreadsheet;

            contractsCtrl.exportContracts = ContractsFactory.exportContracts;
            contractsCtrl.exportMaster = ContractsFactory.exportMaster;
            contractsCtrl.exportPayrollPATFA = ContractsFactory.exportPayrollPATFA;
        },
    ]);
