angular
    .module("admin.instructors.instructor.contracts.contract.instructor.revision", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.instructors.instructor.contracts.contract.instructor.revision", {
            url: "/{revisionId:int}",
            views: {
                "": {
                    templateUrl:
                        "admin/instructors/instructor/contracts/contract/instructor/revision/contract-revision.tmpl.html",
                    controller: "AdminInstructorContractInstructorRevisionCtrl as instructorRevisionCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Revision",
            },
        });
    })
    .controller("AdminInstructorContractInstructorRevisionCtrl", [
        "instructorContract",
        "$scope",
        "$stateParams",
        "AlertFactory",
        function (instructorContract, $scope, $stateParams, AlertFactory) {
            var instructorRevisionCtrl = this;

            var revisionId = $stateParams.revisionId;

            var revisions = instructorContract.revisions;

            var index = _.findIndex(revisions, function (revision) {
                return revisionId == revision.id;
            });

            instructorRevisionCtrl.revision = revisions[index];
            instructorRevisionCtrl.prevRevision = revisions[index + 1] || revisions[index];
            instructorRevisionCtrl.contract = instructorContract;
            instructorRevisionCtrl.revisions = revisions;

            $scope.$emit("contract.revisions.update", revisionId);

            instructorRevisionCtrl.acceptContract = function (contract, form) {
                AlertFactory.show("Submit Test", "success");
            };
        },
    ]);
