angular
    .module("admin.semesters.semester.proposals.proposal.contracts.remove", [])
    .controller("RemoveProposalContractCtrl", [
        "contract",
        "$uibModalInstance",
        function (contract, $uibModalInstance) {
            var removeProposalContractCtrl = this;

            removeProposalContractCtrl.contract = contract;

            removeProposalContractCtrl.cancel = function () {
                $uibModalInstance.dismiss("cancel");
            };

            removeProposalContractCtrl.remove = function (contract) {
                $uibModalInstance.close();
            };
        },
    ]);
