angular.module("admin.semesters.semester.enrollments.delete", []).controller("DeleteEnrollmentsCtrl", [
    "$uibModalInstance",
    "exported_at",
    function ($uibModalInstance, exported_at) {
        var deleteEnrollmentsCtrl = this;

        deleteEnrollmentsCtrl.exported_at = exported_at;

        deleteEnrollmentsCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        deleteEnrollmentsCtrl.delete = function () {
            $uibModalInstance.close();
        };
    },
]);
