angular
    .module("signin", [])
    .config(function ($stateProvider) {
        $stateProvider.state("signin", {
            cache: false,
            url: "/signin",
            templateUrl: "signin/signin.tmpl.html",
            controller: "SigninCtrl as signinCtrl",
        });
    })
    .controller("SigninCtrl", [
        "$auth",
        "$state",
        "$rootScope",
        "UsersModel",
        "AuthFactory",
        function ($auth, $state, $rootScope, UsersModel, AuthFactory) {
            var signinCtrl = this;

            signinCtrl.status = "alert-info";
            signinCtrl.msg = null;
            signinCtrl.img = "assets/img/google_signin_buttons/btn_google_signin_light_normal_web@2x.png";

            var signin = function () {
                signinCtrl.status = "alert-info";
                signinCtrl.msg = "Signing in...";
                $auth
                    .authenticate("google")
                    .then(function (response) {
                        var user = response.data.user;
                        var roles = _.map(user.roles, "name");

                        UsersModel.setCurrentUser(user);

                        signinCtrl.status = "alert-success";
                        signinCtrl.msg = "Sign in success.";

                        // Signed in with Google.
                        if ($rootScope.postLoginState) {
                            $state.go($rootScope.postLoginState, $rootScope.postLoginParams);
                        } else if (_.includes(roles, "admin") || _.includes(roles, "view-only")) {
                            $state.go("admin.semesters");
                        } else if (_.includes(roles, "instructor")) {
                            $state.go("instructor.semesters");
                        } else if (_.includes(roles, "approver") || _.includes(roles, "approver-member")) {
                            $state.go("approver.semesters");
                        } else {
                            signinCtrl.status = "alert-danger";
                            signinCtrl.msg = "Sign in failed.";
                            AuthFactory.logout();
                        }
                    })
                    .catch(function () {
                        // Something went wrong.
                        signinCtrl.status = "alert-danger";
                        signinCtrl.msg = "Sign in failed.";
                    });
            };

            var focus = function () {
                signinCtrl.img = "assets/img/google_signin_buttons/btn_google_signin_light_focus_web@2x.png";
            };

            var blur = function () {
                signinCtrl.img = "assets/img/google_signin_buttons/btn_google_signin_light_normal_web@2x.png";
            };

            var mousedown = function () {
                signinCtrl.img = "assets/img/google_signin_buttons/btn_google_signin_light_pressed_web@2x.png";
            };

            var mouseup = function () {
                signinCtrl.img = "assets/img/google_signin_buttons/btn_google_signin_light_normal_web@2x.png";
            };

            signinCtrl.signin = signin;
            signinCtrl.focus = focus;
            signinCtrl.blur = blur;
            signinCtrl.mousedown = mousedown;
            signinCtrl.mouseup = mouseup;
        },
    ]);
