angular
    .module("instructor.semesters.contracts", ["app.models.contracts", "instructor.semesters.contracts.revisions"])
    .config(function ($stateProvider) {
        $stateProvider.state("instructor.semesters.semester.contracts", {
            url: "/contracts",
            views: {
                "": {
                    templateUrl: "instructor/semesters/contracts/contracts.tmpl.html",
                    controller: "InstructorSemesterContractsCtrl as contractsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Contracts",
            },
        });
    })
    .controller("InstructorSemesterContractsCtrl", [
        "semester",
        "$scope",
        "$state",
        "$stateParams",
        "UsersModel",
        "Loader",
        "ContractsFactory",
        function (semester, $scope, $state, $stateParams, UsersModel, Loader, ContractsFactory) {
            var contractsCtrl = this;
            var semesterId = $stateParams.semesterId;

            contractsCtrl.currentPage = 1;

            contractsCtrl.search = ContractsFactory.getSearch(semester);

            Loader.showLoading("Loading Contracts");
            UsersModel.getInstructorSemesterContracts(semesterId)
                .then(
                    function (contracts) {
                        contractsCtrl.contracts = contracts;
                        contractsCtrl.search.totalItems = contractsCtrl.contracts.length;
                    },
                    function () {
                        $state.go("instructor.semesters");
                    }
                )
                .finally(function () {
                    Loader.hideLoading();
                });

            contractsCtrl.showRevisions = function () {
                $state.go("instructor.semesters.semester.contracts.revisions");
            };

            $scope.$on("contractUpdated", function (event, updatedContract) {
                var index = contractsCtrl.contracts.findIndex(function (contract) {
                    return contract.id === updatedContract.id;
                });

                if (index !== -1) {
                    _.assign(contractsCtrl.contracts[index], updatedContract);
                }
            });

            contractsCtrl.getStatusHTML = ContractsFactory.getStatusHTML;
        },
    ]);
