angular
    .module("admin.semesters.semester.proposals.proposal.approvers.remove", [])
    .controller("RemoveProposalApproverCtrl", [
        "proposalApprover",
        "$uibModalInstance",
        function (proposalApprover, $uibModalInstance) {
            var removeProposalApproverCtrl = this;

            removeProposalApproverCtrl.proposalApprover = proposalApprover;

            removeProposalApproverCtrl.cancel = function () {
                $uibModalInstance.dismiss("cancel");
            };

            removeProposalApproverCtrl.remove = function () {
                $uibModalInstance.close(proposalApprover.id);
            };
        },
    ]);
