angular
    .module("instructor.semesters.warnings", [])
    .config(function ($stateProvider) {
        $stateProvider.state("instructor.semesters.semester.warnings", {
            url: "/warnings",
            views: {
                "": {
                    templateUrl: "instructor/semesters/warnings/warnings.tmpl.html",
                    controller: "InstructorSemesterWarningsCtrl as instructorWarningsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Warnings",
            },
            resolve: {
                warnings: [
                    "$state",
                    "$stateParams",
                    "$q",
                    "Loader",
                    "UsersModel",
                    "WarningsFactory",
                    function ($state, $stateParams, $q, Loader, UsersModel, WarningsFactory) {
                        var deferred = $q.defer();
                        var semesterId = $stateParams.semesterId;

                        Loader.showLoading("Loading Warnings");
                        UsersModel.getInstructorSemesterWarnings(semesterId)
                            .then(
                                function (warnings) {
                                    WarningsFactory.addCustomAttributes(warnings);
                                    deferred.resolve(warnings);
                                },
                                function () {
                                    deferred.reject();
                                    $state.go("instructor.semesters");
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });

                        return deferred.promise;
                    },
                ],
            },
        });
    })
    .controller("InstructorSemesterWarningsCtrl", [
        "warnings",
        "$stateParams",
        "ApproversModel",
        "WarningsFactory",
        function (warnings, $stateParams, ApproversModel, WarningsFactory) {
            var instructorWarningsCtrl = this;

            instructorWarningsCtrl.semesterId = $stateParams.semesterId;

            instructorWarningsCtrl.roles = ApproversModel.roles();
            _.remove(instructorWarningsCtrl.roles, { name: "provost" });
            _.remove(instructorWarningsCtrl.roles, { name: "chancellor" });

            instructorWarningsCtrl.warnings = warnings;

            instructorWarningsCtrl.search = WarningsFactory.getSearch();
            instructorWarningsCtrl.search.totalItems = instructorWarningsCtrl.warnings.length;

            instructorWarningsCtrl.getWarningApprover = WarningsFactory.getWarningApprover;
            instructorWarningsCtrl.getWarningStatusHTML = WarningsFactory.getWarningStatusHTML;
            instructorWarningsCtrl.getWarningApproverStatusHTML = WarningsFactory.getWarningApproverStatusHTML;

            instructorWarningsCtrl.isRevisedAfterResponse = WarningsFactory.isRevisedAfterResponse;
            instructorWarningsCtrl.hasResponded = WarningsFactory.hasResponded;

            instructorWarningsCtrl.showEditWarning = WarningsFactory.showEditWarning;
            instructorWarningsCtrl.showWarning = WarningsFactory.showWarning;
            instructorWarningsCtrl.showWarningApprover = WarningsFactory.showWarningApprover;

            instructorWarningsCtrl.showWarningStatuses = WarningsFactory.showWarningStatuses;
            instructorWarningsCtrl.showWarningApproverStatuses = WarningsFactory.showWarningApproverStatuses;
        },
    ]);
