angular.module("admin.instructors.instructor.predefined-fields", []).controller("InstructorPredfinedFieldsCtrl", [
    "field",
    "$uibModalInstance",
    "InstructorsModel",
    "Loader",
    function (field, $uibModalInstance, InstructorsModel, Loader) {
        var instructorPredefinedFieldsCtrl = this;

        instructorPredefinedFieldsCtrl.label = InstructorsModel.labels()[field];

        instructorPredefinedFieldsCtrl.meta = null;
        instructorPredefinedFieldsCtrl.items = [];
        instructorPredefinedFieldsCtrl.editedItems = [];

        InstructorsModel.getInstructorsMeta().then(function (meta) {
            instructorPredefinedFieldsCtrl.meta = _.find(meta, function (meta) {
                return meta.key == field;
            });

            if (instructorPredefinedFieldsCtrl.meta) {
                instructorPredefinedFieldsCtrl.items = instructorPredefinedFieldsCtrl.meta.value.items;
                instructorPredefinedFieldsCtrl.editedItems = angular.copy(
                    instructorPredefinedFieldsCtrl.meta.value.items
                );
            }
        });

        instructorPredefinedFieldsCtrl.add = function () {
            var item = {
                label: "",
                value: "",
            };

            instructorPredefinedFieldsCtrl.editedItems.push(item);
        };

        instructorPredefinedFieldsCtrl.delete = function (item) {
            _.remove(instructorPredefinedFieldsCtrl.editedItems, item);
        };

        instructorPredefinedFieldsCtrl.save = function () {
            var editedMeta = {};
            editedMeta.key = field;
            editedMeta.value = {};
            editedMeta.value.items = instructorPredefinedFieldsCtrl.editedItems;

            Loader.showLoading("Saving");
            if (instructorPredefinedFieldsCtrl.meta && instructorPredefinedFieldsCtrl.meta.id) {
                editedMeta.id = instructorPredefinedFieldsCtrl.meta.id;
                InstructorsModel.updateInstructorsMeta(editedMeta).finally(function () {
                    $uibModalInstance.close();
                    Loader.hideLoading();
                });
            } else {
                InstructorsModel.createInstructorsMeta(editedMeta).finally(function () {
                    $uibModalInstance.close();
                    Loader.hideLoading();
                });
            }
        };

        instructorPredefinedFieldsCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };
    },
]);
