angular
    .module("admin.semesters.semester.warnings.notes", ["admin.semesters.semester.warnings.notes.delete"])
    .controller("WarningNotesCtrl", [
        "warning",
        "$uibModal",
        "$uibModalInstance",
        "WarningsModel",
        "Loader",
        "AlertFactory",
        function (warning, $uibModal, $uibModalInstance, WarningsModel, Loader, AlertFactory) {
            var warningNotesCtrl = this;

            warningNotesCtrl.warning = warning;

            warningNotesCtrl.deleteWarningNote = function (warningNote) {
                var modalInstance = $uibModal.open({
                    templateUrl: "admin/semesters/warnings/notes/delete/warning-note-delete.tmpl.html",
                    controller: "DeleteWarningNoteCtrl as deleteWarningNoteCtrl",
                    resolve: {
                        warningNote: function () {
                            return warningNote;
                        },
                    },
                });

                modalInstance.result
                    .then(function (warningNoteId) {
                        Loader.showLoading("Deleting Note");
                        WarningsModel.deleteWarningNote(warning.id, warningNoteId)
                            .then(
                                function () {
                                    _.remove(warning.notes, function (warningNote) {
                                        return warningNote.id == warningNoteId;
                                    });
                                },
                                function (result) {
                                    if (result.errors) {
                                        AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                                    }
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });
                    })
                    .catch(function (result) {
                        if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                    });
            };

            warningNotesCtrl.save = function (warningNote) {
                Loader.showLoading("Creating Note");
                WarningsModel.createWarningNote(warning.id, warningNote)
                    .then(
                        function (note) {
                            warning.notes.unshift(note);
                            warningNotesCtrl.warningNote = {
                                note: "",
                            };
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            warningNotesCtrl.cancel = function () {
                $uibModalInstance.dismiss("cancel");
            };
        },
    ]);
