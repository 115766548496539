angular.module("admin.approvers.approver.update", []).controller("UpdateApproverCtrl", [
    "$uibModalInstance",
    "ApproversModel",
    "Loader",
    "approver",
    "AlertFactory",
    function ($uibModalInstance, ApproversModel, Loader, approver, AlertFactory) {
        var updateApproverCtrl = this;

        updateApproverCtrl.approver = approver;

        updateApproverCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        updateApproverCtrl.save = function () {
            Loader.showLoading("Updating Approver");
            ApproversModel.updateDirector(approver)
                .then(
                    function (approver) {
                        $uibModalInstance.close(approver);
                    },
                    function (result) {
                        if (result.errors) {
                            var messages = [];
                            _.forEach(result.errors, function (error) {
                                messages.push(error);
                            });
                            AlertFactory.show(messages.join("\r\n"), "danger");
                        }
                        updateApproverCtrl.cancel();
                    }
                )
                .finally(function () {
                    Loader.hideLoading();
                });
        };

        $uibModalInstance.result.catch(function () {
            $uibModalInstance.close();
        });
    },
]);
