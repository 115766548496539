angular
    .module("admin.instructors.instructor.contracts.contract.instructor", [
        "admin.instructors.instructor.contracts.contract.instructor.revision",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.instructors.instructor.contracts.contract.instructor", {
            url: "/instructor",
            views: {
                "": {
                    templateUrl: "admin/instructors/instructor/contracts/contract/instructor/instructor.tmpl.html",
                    controller: "AdminInstructorContractInstructorCtrl as contractInstructorCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Instructor View",
            },
            resolve: {
                instructorContract: [
                    "contract",
                    "$state",
                    "$q",
                    "InstructorsModel",
                    "Loader",
                    function (contract, $state, $q, InstructorsModel, Loader) {
                        var deferred = $q.defer();

                        Loader.showLoading("Loading Instructor Contract");
                        InstructorsModel.getContract(contract.user_id, contract.id)
                            .then(
                                function (instructorContract) {
                                    deferred.resolve(instructorContract);
                                },
                                function () {
                                    deferred.reject();
                                    $state.go("admin.instructors.instructor");
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });

                        return deferred.promise;
                    },
                ],
            },
        });
    })
    .controller("AdminInstructorContractInstructorCtrl", [
        "instructorContract",
        "$scope",
        "$state",
        "$stateParams",
        "$window",
        "$timeout",
        "Loader",
        function (instructorContract, $scope, $state, $stateParams, $window, $timeout, Loader) {
            var contractInstructorCtrl = this;

            contractInstructorCtrl.revisions = instructorContract.revisions;

            if (contractInstructorCtrl.revisions.length) {
                contractInstructorCtrl.revisionId = instructorContract.revisions[0].id;

                $state.go("admin.instructors.instructor.contracts.contract.instructor.revision", {
                    revisionId: contractInstructorCtrl.revisionId,
                });
            }

            $scope.$on("contract.revisions.update", function (event, revisionId) {
                $timeout(function () {
                    contractInstructorCtrl.revisionId = revisionId;
                });
            });

            contractInstructorCtrl.showRevision = function (revisionId) {
                Loader.showLoading("Loading Revision");
                $timeout(function () {
                    $state.go("admin.instructors.instructor.contracts.contract.instructor.revision", {
                        revisionId: revisionId,
                    });
                    Loader.hideLoading();
                });
            };

            contractInstructorCtrl.print = function () {
                $window.print();
            };
        },
    ]);
