angular.module("app.factories.approvers", []).factory("ApproversFactory", [
    "$q",
    "ApproversModel",
    "UsersFactory",
    function ($q, ApproversModel, UsersFactory) {
        var approversFactory = this;
        var editableApprover;

        function showEdit() {
            var editable = this;
            if (UsersFactory.hasPermission("edit-approvers") && !editable.editing) {
                editable.visible = true;
            }
        }

        function hideEdit() {
            this.visible = false;
        }

        function edit() {
            this.visible = false;
            this.editing = true;
        }

        function cancelEdit() {
            this.value = editableApprover[this.key];
            this.editing = false;
        }

        function saveEdit() {
            var editable = this;
            var deferred = $q.defer();

            if (editableApprover[editable.key] == editable.value) {
                editable.editing = false;
                editable.error = false;
                deferred.resolve();
                return deferred.promise;
            }

            editable.saving = true;

            var approver = {};
            approver.user_id = editableApprover.user_id;
            approver[editable.key] = editable.value;

            ApproversModel.updateApprover(approver)
                .then(
                    function (result) {
                        editableApprover[editable.key] = editable.value;

                        if (editable.key === "role_id") {
                            editableApprover.role.id = result.role.id;
                            editableApprover.role.name = result.role.display_name;
                            editableApprover.role.display_name = result.role.display_name;
                            editableApprover.role.description = result.role.description;
                            editableApprover.role.created_at = result.role.created_at;
                            editableApprover.role.updated_at = result.role.updated_at;
                        }

                        editable.editing = false;
                        editable.error = false;
                        deferred.resolve(result);
                    },
                    function (result) {
                        editable.value = editableApprover[editable.key];
                        editable.error = true;
                        deferred.reject(result);
                    }
                )
                .finally(function () {
                    editable.saving = false;
                });

            return deferred.promise;
        }

        function getEditable(approver) {
            var editable = {};

            _.forOwn(approver, function (value, key) {
                if (!editable[key]) {
                    editable[key] = {};
                    editable[key].key = key;
                    editable[key].value = value;
                    editable[key].visible = false;
                    editable[key].error = false;
                    editable[key].editing = false;
                    editable[key].showEdit = showEdit;
                    editable[key].hideEdit = hideEdit;
                    editable[key].edit = edit;
                    editable[key].cancelEdit = cancelEdit;
                    editable[key].saveEdit = saveEdit;
                }
            });

            editableApprover = approver;

            return editable;
        }

        function label(field) {
            if (labels[field]) {
                return labels[field];
            }

            return field;
        }

        function format(field, value) {
            if (_.includes(ApproversModel.times(), field)) {
                value = $filter("date")(value, "h:mm a");
            } else if (_.includes(ApproversModel.dates(), field)) {
                value = $filter("date")(value, "M/d/yy");
            } else if (_.includes(ApproversModel.booleans(), field)) {
                if (value === true) {
                    value = "Yes";
                } else if (value === false) {
                    value = "No";
                } else {
                    value = "";
                }
            }

            return value;
        }

        approversFactory.getEditable = getEditable;
        approversFactory.label = label;
        approversFactory.format = format;

        return approversFactory;
    },
]);
