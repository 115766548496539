angular.module('app.models.templates', [])
    .service('TemplatesModel', ['$http', '$q', 'API_URL', function($http, $q, API_URL) {
        var model = this,
            templates,
            API = {
                /**
                 * Template
                 */

                getTemplates: function() {
                    return $http.get(API_URL + 'templates');
                },
                getTemplate: function(templateId) {
                    return $http.get(API_URL + 'templates/' + templateId);
                },
                updateTemplate: function(template) {
                    return $http.patch(API_URL + 'templates/' + template.id, template);
                },
            };

        function extract(result) {
            return result.data;
        }

        /**
         * Templates
         */

        function cacheTemplates(result) {
            templates = extract(result);
            return templates;
        }

        model.clearCache = function() {
            templates = null;
        };

        model.getTemplates = function() {
            if (templates) {
                return $q.when(templates);
            } else {
                templates = API.getTemplates().then(cacheTemplates);
                return templates;
            }
        };

        model.getTemplate = function(templateId) {
            var deferred = $q.defer();

            function findTemplate() {
                return _.find(templates, function(template) {
                    return template.id == templateId;
                });
            }

            if (templates) {
                $q.when(templates).then(function() {
                    deferred.resolve(findTemplate());
                });
            } else {
                model.getTemplates().then(function() {
                    deferred.resolve(findTemplate());
                });
            }

            return deferred.promise;
        };

        model.updateTemplate = function(template) {
            var deferred = $q.defer();

            API.updateTemplate(template).then(function(result) {
                result = extract(result);

                _.merge(templates, result);

                deferred.resolve(result);
            }, function(result) {
                deferred.reject(extract(result));
            });

            return deferred.promise;
        };
    }]);