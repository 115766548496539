angular
    .module("admin.semesters.semester.proposals.proposal.approvers.add", [
        "app.models.approvers",
        "angular-toArrayFilter",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.proposals.proposal.approvers.add", {
            url: "/add",
            views: {
                add: {
                    templateUrl: "admin/semesters/proposals/approvers/add/approver-add.tmpl.html",
                    controller: "AddProposalApproverCtrl as addProposalApproverCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Add Approvers",
            },
        });
    })
    .controller("AddProposalApproverCtrl", [
        "proposal",
        "$state",
        "$stateParams",
        "SemestersModel",
        "ApproversModel",
        "ProposalsModel",
        "Loader",
        "ProposalsFactory",
        "AlertFactory",
        function (
            proposal,
            $state,
            $stateParams,
            SemestersModel,
            ApproversModel,
            ProposalsModel,
            Loader,
            ProposalsFactory,
            AlertFactory
        ) {
            var addProposalApproverCtrl = this;
            var semesterId = $stateParams.semesterId;
            var proposalId = $stateParams.proposalId;

            addProposalApproverCtrl.roles = ApproversModel.roles();
            _.remove(addProposalApproverCtrl.roles, { name: "director" });

            Loader.showLoading("Loading Approvers");
            ApproversModel.getApprovers().then(function (approvers) {
                _.remove(approvers, function (approver) {
                    return !_.some(addProposalApproverCtrl.roles, { name: approver.role.name });
                });

                _.forEach(approvers, function (approver) {
                    approver.label =
                        approver.user.first_name +
                        " " +
                        approver.user.last_name +
                        " (Role: " +
                        approver.role.display_name +
                        ")";
                    if (approver.college) {
                        approver.label += " (College: " + approver.college + ")";
                    }
                    if (approver.department) {
                        approver.label += " (Department: " + approver.department + ")";
                    }
                });

                addProposalApproverCtrl.approvers = approvers;
                addProposalApproverCtrl.colleges = _.compact(_.uniq(_.map(approvers, "college")));
                addProposalApproverCtrl.departments = _.compact(_.uniq(_.map(approvers, "department")));

                Loader.hideLoading();
            });

            addProposalApproverCtrl.filterRole = function () {
                return {
                    role: {
                        name: addProposalApproverCtrl.role,
                    },
                };
            };

            addProposalApproverCtrl.filterCollege = function () {
                if (addProposalApproverCtrl.college) {
                    return {
                        college: addProposalApproverCtrl.college,
                    };
                }

                return "";
            };

            addProposalApproverCtrl.filterDepartment = function () {
                if (addProposalApproverCtrl.department) {
                    return {
                        department: addProposalApproverCtrl.department,
                    };
                }

                return "";
            };

            addProposalApproverCtrl.resetValidity = function () {
                addProposalApproverCtrl.addProposalApproverForm.approver.$setValidity("unique", true);
                addProposalApproverCtrl.addProposalApproverForm.role.$setValidity("unique", true);
                addProposalApproverCtrl.addProposalApproverForm.$setPristine();
            };

            addProposalApproverCtrl.addProposalApprover = function (newApprover) {
                addProposalApproverCtrl.addProposalApproverForm.approver.$setValidity(
                    "unique",
                    !_.some(proposal.approvers, ["user_id", newApprover.user_id])
                );
                addProposalApproverCtrl.addProposalApproverForm.role.$setValidity(
                    "unique",
                    !_.some(proposal.approvers, ["role_id", newApprover.role_id])
                );

                if (addProposalApproverCtrl.addProposalApproverForm.$invalid) {
                    return;
                }

                Loader.showLoading("Adding Approver");
                ProposalsModel.addProposalApprover(proposalId, newApprover)
                    .then(
                        function (proposalApprovers) {
                            _.merge(proposal.approvers, proposalApprovers);

                            // ProposalsFactory.addCustomAttributes(proposal);

                            SemestersModel.clearWarningsCache(semesterId);

                            addProposalApproverCtrl.role = "";
                            addProposalApproverCtrl.college = "";
                            addProposalApproverCtrl.department = "";
                            addProposalApproverCtrl.approver = "";

                            addProposalApproverCtrl.resetValidity();
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            addProposalApproverCtrl.returnToApprovers = function () {
                $state.go("admin.semesters.semester.proposals.proposal.approvers", { proposalId: proposalId });
            };
        },
    ]);
