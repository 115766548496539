angular
    .module("admin.semesters.semester.proposals.proposal.approvers.replace.confirm", [])
    .controller("ConfirmReplaceProposalApproverCtrl", [
        "proposalApprover",
        "proposalApproverReplacement",
        "$uibModalInstance",
        function (proposalApprover, proposalApproverReplacement, $uibModalInstance) {
            var confirmReplaceProposalApproverCtrl = this;

            confirmReplaceProposalApproverCtrl.proposalApprover = proposalApprover;
            confirmReplaceProposalApproverCtrl.proposalApproverReplacement = proposalApproverReplacement;
            confirmReplaceProposalApproverCtrl.proposalApprover = proposalApprover;

            confirmReplaceProposalApproverCtrl.cancel = function () {
                $uibModalInstance.dismiss("cancel");
            };

            confirmReplaceProposalApproverCtrl.replace = function () {
                $uibModalInstance.close();
            };
        },
    ]);
