angular
    .module("admin.semesters.semester.proposals.proposal.contracts.edit", ["app.models.contracts", "infinite-scroll"])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.proposals.proposal.contracts.edit", {
            url: "/edit",
            views: {
                "": {
                    templateUrl: "admin/semesters/proposals/contracts/edit/contract-edit.tmpl.html",
                    controller: "EditProposalContractCtrl as editProposalContractCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Add Contracts",
            },
        });
    })
    .controller("EditProposalContractCtrl", [
        "semester",
        "proposal",
        "$state",
        "$stateParams",
        "$timeout",
        "SemestersModel",
        "ProposalsModel",
        "Loader",
        "ContractsFactory",
        "AlertFactory",
        function (
            semester,
            proposal,
            $state,
            $stateParams,
            $timeout,
            SemestersModel,
            ProposalsModel,
            Loader,
            ContractsFactory,
            AlertFactory
        ) {
            var editProposalContractCtrl = this;
            var semesterId = $stateParams.semesterId;
            var proposalId = $stateParams.proposalId;

            editProposalContractCtrl.proposal = proposal;
            editProposalContractCtrl.hasSelectedAll = false;
            editProposalContractCtrl.search = ContractsFactory.getSearch();

            Loader.showLoading("Loading Contracts");
            SemestersModel.getContracts(semesterId).then(function (contracts) {
                var proposalsContracts = [];

                _.remove(contracts, function (contract) {
                    return contract.type !== null;
                });

                _.forEach(semester.proposals, function (proposal) {
                    if (proposal.id != proposalId) {
                        proposalsContracts.push(proposal.contracts);
                    }
                });

                var contractsInProposals = _.flatten(proposalsContracts);

                var contractsNotInProposals = _.differenceBy(contracts, contractsInProposals, "parent_id");

                _.forEach(contractsNotInProposals, function (contract) {
                    contract.selected = false;
                    contract.in_proposal = null;

                    var proposalContract = _.find(proposal.contracts, function (proposalContract) {
                        return proposalContract.id == contract.id;
                    });

                    if (proposalContract) {
                        contract.in_proposal = 1;
                    } else if (contract.is_completed && !contract.is_accepted) {
                        contract.in_proposal = -1;
                    }
                });

                if ($state.current.name === "admin.semesters.semester.contracts.contract") {
                    editProposalContractCtrl.show = true;
                    var contractId = $stateParams.contractId;
                    var index = _.findIndex(editProposalContractCtrl.contracts, function (contract) {
                        return contract.id == contractId;
                    });
                    editProposalContractCtrl.currentPage = index + 1;
                }

                editProposalContractCtrl.contracts = contractsNotInProposals;

                editProposalContractCtrl.filteredContracts = editProposalContractCtrl.contractsNotInProposals;
                editProposalContractCtrl.search.limitTo = 100;
                editProposalContractCtrl.search.totalItems = editProposalContractCtrl.contracts.length;

                Loader.hideLoading();
            });

            editProposalContractCtrl.hasContract = function (contract) {
                var proposalContract = _.find(editProposalContractCtrl.proposal.contracts, function (_contract) {
                    return _contract.id == contract.id;
                });

                if (proposalContract) {
                    return true;
                }

                return false;
            };

            editProposalContractCtrl.hasSelected = function (contract) {
                var selected = _.filter(editProposalContractCtrl.contracts, function (contract) {
                    return contract.selected == true;
                });

                if (selected.length > 0) {
                    return true;
                }

                return false;
            };

            editProposalContractCtrl.addProposalContracts = function (contract) {
                Loader.showLoading("Adding Contract");
                ProposalsModel.addProposalContracts(proposalId, { contracts: [contract.parent_id] })
                    .then(
                        function () {
                            contract.in_proposal = 1;

                            editProposalContractCtrl.proposal.contracts.push(contract);

                            var index = _.findIndex(semester.proposals, function (semesterProposal) {
                                return semesterProposal.id == proposal.id;
                            });

                            if (index >= 0) {
                                semester.proposals[index].contracts.push(contract);
                            }

                            SemestersModel.clearContractsCache(semesterId);
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            editProposalContractCtrl.removeProposalContracts = function (contract) {
                Loader.showLoading("Removing Contract");
                ProposalsModel.removeProposalContracts(proposalId, { contracts: [contract.id] })
                    .then(
                        function (updatedProposal) {
                            contract.in_proposal = -1;

                            _.remove(editProposalContractCtrl.proposal.contracts, function (proposalContract) {
                                return proposalContract.id == contract.id;
                            });

                            var index = _.findIndex(semester.proposals, function (semesterProposal) {
                                return semesterProposal.id == proposal.id;
                            });

                            if (index >= 0) {
                                _.remove(semester.proposals[index].contracts, function (proposalContract) {
                                    return proposalContract.id == contract.id;
                                });
                            }

                            _.merge(proposal, updatedProposal);

                            SemestersModel.clearContractsCache(semesterId);
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            editProposalContractCtrl.addSelectedProposalContracts = function () {
                Loader.showLoading("Adding Contract(s)");

                var contracts = [];

                _.forEach(editProposalContractCtrl.contracts, function (contract) {
                    if (contract.selected && !editProposalContractCtrl.hasContract(contract)) {
                        contracts.push(contract);
                    }
                });

                if (contracts.length == 0) {
                    AlertFactory.show("Please select one or more contracts to add to the proposal.", "danger");
                    Loader.hideLoading();
                    return;
                }

                ProposalsModel.addProposalContracts(proposalId, { contracts: _.map(contracts, "id") })
                    .then(
                        function () {
                            var index = _.findIndex(semester.proposals, function (semesterProposal) {
                                return semesterProposal.id == proposal.id;
                            });

                            _.forEach(contracts, function (contract) {
                                if (!editProposalContractCtrl.hasContract(contract)) {
                                    contract.in_proposal = 1;
                                    editProposalContractCtrl.proposal.contracts.push(contract);
                                }

                                if (index >= 0) {
                                    semester.proposals[index].contracts.push(contract);
                                }
                            });

                            SemestersModel.clearContractsCache(semesterId);
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            editProposalContractCtrl.removeSelectedProposalContracts = function () {
                Loader.showLoading("Removing Contract(s)");

                var contracts = [];

                _.forEach(editProposalContractCtrl.contracts, function (contract) {
                    if (contract.selected && editProposalContractCtrl.hasContract(contract)) {
                        contracts.push(contract);
                    }
                });

                if (contracts.length == 0) {
                    AlertFactory.show("Please select one or more contracts to remove from the proposal.", "danger");
                    Loader.hideLoading();
                    return;
                }

                ProposalsModel.removeProposalContracts(proposalId, { contracts: _.map(contracts, "id") })
                    .then(
                        function (updatedProposal) {
                            var index = _.findIndex(semester.proposals, function (semesterProposal) {
                                return semesterProposal.id == proposal.id;
                            });

                            _.forEach(contracts, function (contract) {
                                contract.in_proposal = -1;
                                _.remove(editProposalContractCtrl.proposal.contracts, function (_contract) {
                                    return _contract.id == contract.id;
                                });

                                if (index >= 0) {
                                    _.remove(semester.proposals[index].contracts, function (proposalContract) {
                                        return proposalContract.id == contract.id;
                                    });
                                }
                            });

                            _.merge(proposal, updatedProposal);

                            SemestersModel.clearContractsCache(semesterId);
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            editProposalContractCtrl.select = function (contract, event, index) {
                if (event.shiftKey && index !== 0) {
                    var contracts = editProposalContractCtrl.filteredContracts;

                    $timeout(function () {
                        for (var i = index - 1; i >= 0; i--) {
                            if (
                                contracts[i].selected != contract.selected &&
                                contracts[i].is_completed &&
                                !contracts[i].is_accepted
                            ) {
                                contracts[i].selected = contract.selected;
                            } else if (
                                contracts[i].selected == contract.selected &&
                                contracts[i].is_completed &&
                                !contracts[i].is_accepted
                            ) {
                                break;
                            }
                        }
                    });
                }
            };

            editProposalContractCtrl.unselectAll = function () {
                editProposalContractCtrl.hasSelectedAll = false;

                _.forEach(editProposalContractCtrl.contracts, function (contract) {
                    contract.selected = false;
                });
            };

            editProposalContractCtrl.toggleSelectAll = function (contracts) {
                editProposalContractCtrl.hasSelectedAll = !editProposalContractCtrl.hasSelectedAll;

                _.forEach(contracts, function (contract) {
                    if (contract.is_completed && !contract.is_accepted) {
                        contract.selected = editProposalContractCtrl.hasSelectedAll;
                    }
                });
            };
        },
    ]);
