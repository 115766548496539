// Declare app level module which depends on views, and components
var app = angular.module("App", [
    "templates",
    "app.filters",
    "app.services",
    "app.directives",

    "app.models.enrollments",
    "app.models.users",
    "app.factories.users",

    "ngSanitize",
    "ngMessages",

    "ui.router",
    "ui.router.state.events",
    "ui.bootstrap",
    "ui.select",

    "ncy-angular-breadcrumb",
    "satellizer",
    "checklist-model",

    "signin",
    "admin",
    "instructor",
    "approver",
]);

// LOCAL
// app.constant("BASE_URL", "https://dll.edu/compensation_5.8/prod/");
// app.constant("API_URL", "https://dll.edu/dll/compensation_5.8/api/");
// app.constant("GOOGLE_CLIENT_ID", "670994551742-cijovphtbdo3hd7ognhal9dcj8slfdto.apps.googleusercontent.com");

// DEV
// app.constant("BASE_URL", "http://lv-o-dll.its.maine.edu/compensation/");
// app.constant("API_URL", "http://lv-o-dll.its.maine.edu/dll/compensation/api/");
// app.constant("GOOGLE_CLIENT_ID", "950123436200-mfm3t804gop4kjdlo4na19qtefhteisd.apps.googleusercontent.com");

// PROD
app.constant("BASE_URL", "https://dllapps.lll.umaine.edu/compensation/");
app.constant("API_URL", "https://dllapps.lll.umaine.edu/dll/compensation/api/");
app.constant("GOOGLE_CLIENT_ID", "950123436200-mfm3t804gop4kjdlo4na19qtefhteisd.apps.googleusercontent.com");

app.run([
    "$rootScope",
    "$http",
    "$state",
    "$auth",
    "$uibModalStack",
    "UsersModel",
    "API_URL",
    "Loader",
    "$location",
    "AlertFactory",
    "AuthFactory",
    function (
        $rootScope,
        $http,
        $state,
        $auth,
        $uibModalStack,
        UsersModel,
        API_URL,
        Loader,
        $location,
        AlertFactory,
        AuthFactory
    ) {
        $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
            $uibModalStack.dismissAll();

            // enumerate routes that don't need authentication
            var routesThatDontRequireAuth = ["/signin"];
            var routesForAdmin = ["/admin"];
            var routesForInstructor = ["/instructor"];
            var routesForApprover = ["/approver"];

            // check if current location matches route
            var routeClean = function (route) {
                return _.find(routesThatDontRequireAuth, function (noAuthRoute) {
                    return _.startsWith(route, noAuthRoute);
                });
            };

            // check if current location matches route
            var routeAdmin = function (route) {
                return _.find(routesForAdmin, function (routeAdmin) {
                    return _.startsWith(route, routeAdmin);
                });
            };

            // check if current location matches route
            var routeInstructor = function (route) {
                return _.find(routesForInstructor, function (routeForInstructor) {
                    return _.startsWith(route, routeForInstructor);
                });
            };

            // check if current location matches route
            var routeApprover = function (route) {
                return _.find(routesForApprover, function (routeForApprovers) {
                    return _.startsWith(route, routeForApprovers);
                });
            };

            // not authenticated if token is expired
            if (!$auth.isAuthenticated() && toState.name !== "signin") {
                event.preventDefault();

                var goToSignin = function () {
                    $rootScope.postSigninState = toState.name;
                    $rootScope.postSigninParams = toParams;
                    $state.go("signin");
                };

                // if there is a token, try to refresh
                if ($auth.getToken()) {
                    Loader.showLoading("Authorizing");
                    $http.get(API_URL + "auth/token").then(
                        function () {
                            Loader.hideLoading();
                            $state.go(toState.name, toParams);
                        },
                        function () {
                            goToSignin();
                        }
                    );
                } else {
                    goToSignin();
                }
            } else if ($auth.isAuthenticated() && $rootScope.postSigninState) {
                event.preventDefault();

                toState = $rootScope.postSigninState;
                toParams = $rootScope.postSigninParams;

                $rootScope.postSigninState = null;
                $rootScope.postSigninParams = null;

                $state.go(toState, toParams);
            } else if ($auth.isAuthenticated()) {
                UsersModel.getProfile().then(function (user) {
                    var roles = _.map(user.roles, "name");
                    var url = $location.url();
                    var forbidden = false;

                    if (routeAdmin(url) && !_.includes(roles, "admin") && !_.includes(roles, "view-only")) {
                        forbidden = true;
                    } else if (routeInstructor(url) && !_.includes(roles, "instructor")) {
                        forbidden = true;
                    } else if (
                        routeApprover(url) &&
                        !_.includes(roles, "approver") &&
                        !_.includes(roles, "approver-member")
                    ) {
                        forbidden = true;
                    }

                    if (forbidden) {
                        event.preventDefault();
                        AlertFactory.show("Forbidden", "danger");
                    }

                    if (forbidden || toState.name == "signin") {
                        if (_.includes(roles, "admin") || _.includes(roles, "view-only")) {
                            $state.go("admin.semesters");
                        } else if (_.includes(roles, "instructor")) {
                            $state.go("instructor.semesters");
                        } else if (_.includes(roles, "approver") || _.includes(roles, "approver-member")) {
                            $state.go("approver.semesters");
                        } else {
                            AuthFactory.logout();
                        }
                    }
                });
            }
        });

        $state.defaultErrorHandler(function (error) {
            console.log(error);
            AlertFactory.show("Page Not Found", "danger");
        });
    },
]);

app.config([
    "$authProvider",
    "$stateProvider",
    "$urlRouterProvider",
    "$httpProvider",
    "$breadcrumbProvider",
    "BASE_URL",
    "API_URL",
    "GOOGLE_CLIENT_ID",
    function (
        $authProvider,
        $stateProvider,
        $urlRouterProvider,
        $httpProvider,
        $breadcrumbProvider,
        BASE_URL,
        API_URL,
        GOOGLE_CLIENT_ID
    ) {
        angular.lowercase = angular.$$lowercase; // textAngular

        $authProvider.baseUrl = null;

        $breadcrumbProvider.setOptions({
            includeAbstract: true,
        });

        $authProvider.google({
            clientId: GOOGLE_CLIENT_ID,
            redirectUri: BASE_URL,
            url: API_URL + "auth/google/callback",
            optionalUrlParams: ["display", "prompt"],
            prompt: "select_account",
        });

        $httpProvider.interceptors.push("TokenInterceptor");

        var resolve = {
            currentUser: [
                "$q",
                "$timeout",
                "$state",
                "Loader",
                "UsersModel",
                function ($q, $timeout, $state, Loader, UsersModel) {
                    var deferred = $q.defer();

                    Loader.showLoading("Loading Profile");
                    UsersModel.getProfile()
                        .then(
                            function (currentUser) {
                                deferred.resolve(currentUser);
                            },
                            function () {
                                $timeout(function () {
                                    $state.go("signin");
                                });
                                deferred.reject();
                            }
                        )
                        .finally(function () {
                            Loader.hideLoading();
                        });

                    return deferred.promise;
                },
            ],
        };

        $stateProvider
            .state("app", {
                url: "",
                abstract: true,
                templateUrl: "app/app.tmpl.html",
                controllerAs: "appCtrl",
                ncyBreadcrumb: {
                    skip: true,
                },
            })
            .state("admin", {
                url: "/admin",
                abstract: true,
                templateUrl: "app/app.tmpl.html",
                controllerAs: "appCtrl",
                ncyBreadcrumb: {
                    label: "Admin",
                },
                resolve: resolve,
            })
            .state("instructor", {
                url: "/instructor",
                abstract: true,
                templateUrl: "app/app.tmpl.html",
                controllerAs: "appCtrl",
                ncyBreadcrumb: {
                    label: "Instructor",
                },
                resolve: resolve,
            })
            .state("approver", {
                url: "/approver",
                abstract: true,
                templateUrl: "app/app.tmpl.html",
                controllerAs: "appCtrl",
                ncyBreadcrumb: {
                    label: "Approver",
                },
                resolve: resolve,
            });

        // if none of the above states are matched, use this as the fallback
        $urlRouterProvider.otherwise("/signin");
    },
]);

app.controller("AppCtrl", [
    "$scope",
    "$rootScope",
    "$auth",
    "$state",
    "UsersModel",
    "AuthFactory",
    function ($scope, $rootScope, $auth, $state, UsersModel, AuthFactory) {
        var appCtrl = this;

        appCtrl.isAuthenticated = $auth.isAuthenticated;
        appCtrl.isNavCollapsed = true;

        // this'll be called on every state change in the app
        $scope.$on("$stateChangeSuccess", function (event, toState, toParams, fromState, fromParams) {
            appCtrl.htmlClasses = toState.name.split(".").join("-");
        });

        $rootScope.$on("showLoading", function (e, message) {
            appCtrl.showLoading = true;
            appCtrl.loadingMessage = message ? message : null;
        });

        $rootScope.$on("hideLoading", function () {
            appCtrl.showLoading = false;
        });

        appCtrl.signout = function () {
            AuthFactory.logout();
        };

        appCtrl.goHome = function () {
            UsersModel.getProfile().then(function (user) {
                var roles = _.map(user.roles, "name");
                if (_.includes(roles, "admin") || _.includes(roles, "view-only")) {
                    $state.go("admin.semesters");
                } else if (_.includes(roles, "instructor")) {
                    $state.go("instructor.semesters");
                } else if (_.includes(roles, "approver") || _.includes(roles, "approver-member")) {
                    $state.go("approver.semesters");
                } else {
                    appCtrl.signout();
                }
            });
        };
    },
]);
