angular.module("admin.semesters.semester.contracts.export.delete", []).controller("DeleteExportContractsCtrl", [
    "spreadsheet",
    "$uibModalInstance",
    "ContractsModel",
    "Loader",
    "AlertFactory",
    function (spreadsheet, $uibModalInstance, ContractsModel, Loader, AlertFactory) {
        var deleteExportContractsCtrl = this;

        deleteExportContractsCtrl.spreadsheet = spreadsheet;

        deleteExportContractsCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        deleteExportContractsCtrl.delete = function () {
            Loader.showLoading("Deleting Spreadsheet");

            var meta = {};
            meta.id = spreadsheet.id;

            ContractsModel.deleteContractsMeta(meta)
                .then(function () {
                    $uibModalInstance.close();
                })
                .catch(function (result) {
                    if (result.errors) {
                        AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                    }
                    deleteExportContractsCtrl.cancel();
                })
                .finally(function () {
                    Loader.hideLoading();
                });
        };
    },
]);
