angular
    .module("admin.instructors", [
        "app.models.instructors",
        "app.factories.instructors",
        "admin.instructors.import",
        "admin.instructors.create",
        "admin.instructors.instructor",
        "admin.instructors.delete",
        "admin.instructors.replace",
        "admin.instructors.notes.create",
        "infinite-scroll",
        "angular.filter",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.instructors", {
            url: "/instructors",
            views: {
                "": {
                    templateUrl: "admin/instructors/instructors.tmpl.html",
                    controller: "AdminInstructorsCtrl as instructorsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Instructors",
            },
        });
    })
    .controller("AdminInstructorsCtrl", [
        "$scope",
        "$filter",
        "$timeout",
        "$uibModal",
        "$q",
        "InstructorsModel",
        "ApproversModel",
        "SemestersModel",
        "InstructorsFactory",
        "AlertFactory",
        "NotesFactory",
        "Loader",
        function (
            $scope,
            $filter,
            $timeout,
            $uibModal,
            $q,
            InstructorsModel,
            ApproversModel,
            SemestersModel,
            InstructorsFactory,
            AlertFactory,
            NotesFactory,
            Loader
        ) {
            var instructorsCtrl = this;

            instructorsCtrl.search = InstructorsFactory.getSearch();

            $scope.$on("$destroy", function () {
                instructorsCtrl.search.cleanUp();
            });

            instructorsCtrl.isLoading = true;

            instructorsCtrl.instructors = InstructorsModel.getInstructors()
                .then(function (instructors) {
                    instructorsCtrl.instructors = instructors;
                })
                .finally(function () {
                    $timeout(function () {
                        instructorsCtrl.isLoading = false;
                    });
                });

            InstructorsModel.getInstructorsMeta().then(function (meta) {
                instructorsCtrl.meta = meta;
            });

            $scope.$on("instructors.update", function (event, updatedInstructor) {
                ApproversModel.clearCache();
                SemestersModel.clearCache();

                if (updatedInstructor) {
                    $q.when(instructorsCtrl.instructors).then(function () {
                        var instructor = _.find(instructorsCtrl.instructors, function (instructor) {
                            return instructor.user_id == updatedInstructor.user_id;
                        });

                        _.assign(instructor, updatedInstructor);
                    });
                }
            });

            instructorsCtrl.replaceInstructors = function (replacement) {
                $uibModal.open({
                    templateUrl: "admin/instructors/replace/instructors-replace.tmpl.html",
                    controller: "ReplaceInstructorsCtrl as replaceInstructorsCtrl",
                    resolve: {
                        replacement: function () {
                            return replacement;
                        },
                        instructors: function () {
                            return instructorsCtrl.filteredInstructors;
                        },
                    },
                });
            };

            instructorsCtrl.exportInstructors = function (data) {
                Loader.showLoading("Exporting Instructors");

                var date = new Date();
                date = $filter("date")(date, "M-d-yyyy");
                var fileName = "Instructors_" + date + ".xlsx";

                alasql.fn.datetime = function (date) {
                    if (date) {
                        date = new Date(date);
                        return $filter("date")(date, "M/d/yyyy");
                    }
                };

                alasql.fn.get = function (value) {
                    if (!value && value !== 0) {
                        return "";
                    }
                    return value;
                };

                alasql
                    .promise(
                        "SELECT " +
                            "get(user->ums_id) AS [Employee ID], " +
                            "get(user->last_name) AS [Last Name], " +
                            "get(user->first_name) AS [First Name], " +
                            "get(user->email) AS Email, " +
                            "get(college) AS College, " +
                            "get(department) AS Department, " +
                            "get(unit) as Unit, " +
                            "get(status) as Status, " +
                            "datetime(status_change_date) as [Status Change Date], " +
                            "get(position_no) AS [Position No.], " +
                            "get(rank) as Rank, " +
                            "get(credit_hr_rate) AS [Credit Hour Rate], " +
                            "get(patfa_service_credits) AS [PATFA Service Credits], " +
                            "get(months_in_work_year) AS [Months in Work Year], " +
                            "get(job_code) AS [Job Code], " +
                            "get(faculty_type) as [Faculty Type], " +
                            "get(is_de_adj_eligible) AS [DE Adj Eligible?], " +
                            "get(is_de_adj_grandfathered) AS [DE Adj Grandfathered?], " +
                            "get(is_online_faculty) AS [Online Faculty?], " +
                            "get(is_gold_faculty) AS [GOLD Faculty?], " +
                            "get(has_fhc_fee) AS [FHC Fee?], " +
                            "get(address) AS Address, " +
                            "get(city) AS City, " +
                            "get(state) AS State, " +
                            "get(zip) AS Zip, " +
                            "get(notes_content) AS Notes, " +
                            "datetime(created_at) AS Created, " +
                            "datetime(updated_at) AS Updated " +
                            'INTO XLSX("' +
                            fileName +
                            '", {headers:true}) FROM ?',
                        [data]
                    )
                    .then(function () {
                        Loader.hideLoading();
                    })
                    .catch(function (err) {
                        AlertFactory.show("Export Error", "danger");
                        console.log(err);
                        Loader.hideLoading();
                    });
            };

            instructorsCtrl.createInstructorsNote = function () {
                instructorsCtrl.search.sortCol = _.find(instructorsCtrl.search.sortProps, { value: "notes_content" });

                var modalInstance = $uibModal.open({
                    templateUrl: "admin/instructors/notes/create/instructors-note-create.tmpl.html",
                    controller: "CreateInstructorsNoteCtrl as createInstructorsNoteCtrl",
                    resolve: {
                        instructors: function () {
                            return instructorsCtrl.filteredInstructors;
                        },
                    },
                });

                modalInstance.result
                    .then(function (note) {
                        var instructors = _.values(_.map(instructorsCtrl.filteredInstructors, "user_id"));

                        Loader.showLoading("Creating Note");
                        InstructorsModel.createInstructorsNote(instructors, note)
                            .then(
                                function () {
                                    _.forEach(instructorsCtrl.filteredInstructors, function (instructor) {
                                        instructor.has_notes = true;
                                        instructor.notes_content = note.note + " " + instructor.notes_content;
                                    });
                                    $scope.$emit("instructors.update");
                                },
                                function (result) {
                                    AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });
                    })
                    .catch(function (result) {
                        if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                    });
            };

            instructorsCtrl.showNotes = NotesFactory.showNotes;
            instructorsCtrl.getRowClass = InstructorsFactory.getRowClass;
        },
    ]);
