angular
    .module("app.services", [])
    .factory("$exceptionHandler", [
        "$injector",
        "$log",
        "$window",
        "API_URL",
        function ($injector, $log, $window, API_URL) {
            function error(exception, cause) {
                // preserve the default behaviour which will log the error
                // to the console, and allow the application to continue running.
                $log.error.apply($log, arguments);

                // var $http = $injector.get('$http');

                // var exceptionData = {
                //     message: exception.toString(),
                //     cause: (cause || ''),
                //     url: $window.location.href,
                //     stackTrace: []
                // };

                // if (typeof exception != 'object') {
                //     $http.post(API_URL + 'logger', exceptionData).catch(function(result) {
                //         $log.warn("Error logging failure.");
                //         $log.log(result);
                //     });
                //     return;
                // }

                // StackTrace.fromError(exception).then(function(arrayStack) {

                //     exceptionData.stackTrace = arrayStack;

                //     $http.post(API_URL + 'logger', exceptionData).catch(function(result) {
                //         $log.warn("Error logging failure.");
                //         $log.log(result);
                //     });
                // }).catch(function(result) {
                //     $log.warn("Error logging failure.");
                //     $log.log(result);
                // });
            }

            return error;
        },
    ])
    .factory("AuthFactory", [
        "$auth",
        "$state",
        "Loader",
        "UsersModel",
        "InstructorsModel",
        "ApproversModel",
        "SemestersModel",
        function ($auth, $state, Loader, UsersModel, InstructorsModel, ApproversModel, SemestersModel) {
            return {
                logout: function () {
                    UsersModel.setCurrentUser(null);

                    UsersModel.clearCache();
                    InstructorsModel.clearCache();
                    ApproversModel.clearCache();
                    SemestersModel.clearCache();

                    $auth.logout();

                    Loader.hideLoading(true);

                    $state.go("signin");
                },
            };
        },
    ])
    .factory("Loader", [
        "$timeout",
        "$rootScope",
        "$q",
        function ($timeout, $rootScope) {
            var count = 0;

            var LOADERAPI = {
                showLoading: function (message) {
                    count++;
                    $rootScope.$emit("showLoading", message);
                },
                hideLoading: function (hideAll) {
                    $timeout(function () {
                        count--;
                        if (hideAll || count <= 0) {
                            $rootScope.$emit("hideLoading");
                            count = 0;
                        }
                    });
                },
            };

            return LOADERAPI;
        },
    ])
    .factory("AlertFactory", [
        "$uibModal",
        function ($uibModal) {
            var ALERT_API = {
                show: function (message, context) {
                    if (!message || (Array.isArray(message) && message.length === 0)) {
                        return;
                    }

                    if (_.isObject(message)) {
                        message = _.values(message).join("\r\n");
                    }

                    $uibModal.open({
                        templateUrl: "common/templates/alert.tmpl.html",
                        controller: function ($uibModalInstance) {
                            var modalCtrl = this;

                            modalCtrl.message = message;
                            modalCtrl.context = context;

                            modalCtrl.cancel = function () {
                                $uibModalInstance.dismiss("cancel");
                            };

                            $uibModalInstance.result.catch(function () {
                                $uibModalInstance.close();
                            });
                        },
                        controllerAs: "modalCtrl",
                    });
                },
            };

            return ALERT_API;
        },
    ])
    .factory("LSFactory", [
        function () {
            var LSAPI = {
                clear: function () {
                    return localStorage.clear();
                },
                get: function (key) {
                    return JSON.parse(localStorage.getItem(key));
                },
                set: function (key, data) {
                    return localStorage.setItem(key, JSON.stringify(data));
                },
                delete: function (key) {
                    return localStorage.removeItem(key);
                },
            };

            return LSAPI;
        },
    ])
    .factory("TokenInterceptor", [
        "$q",
        "$injector",
        function ($q, $injector) {
            var requests = [];
            var inFlightAuthRequest = null;
            return {
                request: function (config) {
                    /*
                     * Override Satellizer and add Authorization header if token is present, even if expired
                     */
                    var $auth = $injector.get("$auth");
                    var token = $auth.getToken();
                    if (token) {
                        config.headers.Authorization = "Bearer " + token;
                    }

                    return config;
                },
                response: function (response) {
                    // Need to use $injector.get to bring in $auth or else we get
                    // a circular dependency error
                    var $auth = $injector.get("$auth");

                    // called for http codes up to 300
                    var token = response.headers("Authorization");
                    if ("undefined" !== typeof token && null !== token) {
                        $auth.setToken(token.split(" ")[1]);
                    }

                    return response;
                },
                responseError: function (rejection) {
                    // Need to use $injector.get to bring in $auth and $state or else we get
                    // a circular dependency error
                    var AuthFactory = $injector.get("AuthFactory");
                    var AlertFactory = $injector.get("AlertFactory");
                    var API_URL = $injector.get("API_URL");
                    var response = rejection.data;
                    var message;
                    var deferred;
                    var request;

                    switch (rejection.status) {
                        case 403:
                            message = rejection.statusText;
                            if (rejection.data.message) {
                                message += "\r\n" + rejection.data.message;
                            }
                            AlertFactory.show(message, "danger");
                            AuthFactory.logout();
                            break;
                        case 500:
                            message = rejection.statusText;
                            if (rejection.data.message) {
                                message += "\r\n" + rejection.data.message;
                            }
                            AlertFactory.show(message, "danger");
                            break;
                        case 503:
                            message = rejection.statusText;
                            if (rejection.data.message) {
                                message += "\r\n" + rejection.data.message;
                            }
                            AlertFactory.show(message, "danger");
                            break;
                    }

                    function retryRequest($http, config, deferred) {
                        function successCallback(response) {
                            deferred.resolve(response);
                        }

                        function errorCallback(response) {
                            deferred.reject(response);
                        }
                        $http(config).then(successCallback, errorCallback);
                    }

                    if (inFlightAuthRequest && response.error === "token_expired") {
                        var tokenRefreshURL = API_URL + "auth/token";
                        if (rejection.config.url !== tokenRefreshURL) {
                            deferred = $q.defer();
                            request = {
                                config: rejection.config,
                                deferred: deferred,
                            };
                            requests.push(request);
                            return deferred.promise;
                        }
                    } else if (!inFlightAuthRequest && response.error === "token_expired") {
                        deferred = $q.defer();
                        var $http = $injector.get("$http");
                        request = {
                            config: rejection.config,
                            deferred: deferred,
                        };
                        requests.push(request);
                        inFlightAuthRequest = $http.get(API_URL + "auth/token").then(
                            function () {
                                inFlightAuthRequest = null;
                                angular.forEach(requests, function (request) {
                                    retryRequest($http, request.config, request.deferred);
                                });
                                requests = [];
                            },
                            function () {
                                inFlightAuthRequest = null;
                                angular.forEach(requests, function (request) {
                                    request.deferred.reject(rejection);
                                });
                                requests = [];

                                deferred.reject(rejection);

                                AuthFactory.logout();
                            }
                        );

                        return deferred.promise;
                    } else if (response.error === "token_invalid" || response.error === "token_not_provided") {
                        AlertFactory.show("Session has expired", "danger");
                        AuthFactory.logout();
                    }

                    return $q.reject(rejection);
                },
            };
        },
    ]);
