angular.module("admin.notes.delete", []).controller("DeleteNoteCtrl", [
    "note",
    "link",
    "$uibModalInstance",
    function (note, link, $uibModalInstance) {
        var deleteNoteCtrl = this;

        deleteNoteCtrl.note = note;
        deleteNoteCtrl.link = link;

        deleteNoteCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        deleteNoteCtrl.delete = function () {
            $uibModalInstance.close(note.id);
        };
    },
]);
