angular.module("admin.instructors.delete", []).controller("DeleteInstructorCtrl", [
    "instructor",
    "$state",
    "$uibModalInstance",
    "InstructorsModel",
    "UsersModel",
    "SemestersModel",
    "Loader",
    "AlertFactory",
    function (
        instructor,
        $state,
        $uibModalInstance,
        InstructorsModel,
        UsersModel,
        SemestersModel,
        Loader,
        AlertFactory
    ) {
        var deleteInstructorCtrl = this;

        deleteInstructorCtrl.instructor = instructor;

        deleteInstructorCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        deleteInstructorCtrl.delete = function () {
            Loader.showLoading("Deleting Instructor");
            InstructorsModel.deleteInstructor(instructor)
                .then(
                    function () {
                        UsersModel.clearCache();
                        SemestersModel.clearCache();
                        $state.go("admin.instructors");
                    },
                    function (result) {
                        if (result.errors) {
                            var messages = [];
                            _.forEach(result.errors, function (error) {
                                messages.push(error);
                            });
                            AlertFactory.show(messages.join("\r\n"), "danger");
                        }
                    }
                )
                .finally(function () {
                    deleteInstructorCtrl.cancel();
                    Loader.hideLoading();
                });
        };

        $uibModalInstance.result.catch(function () {
            $uibModalInstance.close();
        });
    },
]);
