angular.module('admin.instructors.import', [])
    .config(function($stateProvider) {
        $stateProvider
            .state('admin.instructors.import', {
                url: '/import',
                views: {
                    'import': {
                        templateUrl: 'admin/instructors/import/instructors-import.tmpl.html',
                        controller: 'ImportInstructorsCtrl as importInstructorsCtrl'
                    }
                },
                ncyBreadcrumb: {
                    label: 'Import Instructors'
                }
            });
    })
    .directive('fileModel', ['$parse', function($parse) {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var model = $parse(attrs.fileModel);
                var modelSetter = model.assign;

                element.bind('change', function() {
                    scope.$apply(function() {
                        modelSetter(scope, element[0].files[0]);
                    });
                });
            }
        };
    }])
    .controller('ImportInstructorsCtrl', ['$scope', '$state', '$filter', 'SemestersModel', 'InstructorsModel', 'Loader', 'AlertFactory',
        function($scope, $state, $filter, SemestersModel, InstructorsModel, Loader, AlertFactory) {
            var importInstructorsCtrl = this;
            importInstructorsCtrl.instructors = {};
            importInstructorsCtrl.update_contracts = false;

            Loader.showLoading('Loading Semesters');
            SemestersModel.getSemesters().then(function(semesters) {
                importInstructorsCtrl.semesters = semesters;
                Loader.hideLoading();
            });

            importInstructorsCtrl.importInstructors = function() {
                Loader.showLoading('Importing Instructors');

                var fd = new FormData();
                fd.append('file', importInstructorsCtrl.file);
                fd.append('updateContracts', importInstructorsCtrl.updateContracts);

                InstructorsModel.importInstructors(fd).then(function(result) {
                    if (result) {
                        if (result.successes && result.successes.length) {
                            importInstructorsCtrl.importInstructorsForm.successes = result.successes;
                        }
                        if (result.errors && result.errors.length) {
                            importInstructorsCtrl.importInstructorsForm.errors = result.errors;
                        }
                        if (result.data && result.data.length) {
                            importInstructorsCtrl.importInstructorsForm.data = result.data;
                        }
                        $scope.$emit('instructors.update');
                    } else {
                        $state.go('admin.instructors');
                    }
                }, function(result) {
                    if (result.errors) {
                        AlertFactory.show(_.values(result.errors).join("\r\n"), 'danger');
                    }
                }).finally(function() {
                    $scope.$emit('instructors.update');
                    Loader.hideLoading();
                });
            };

            importInstructorsCtrl.exportResults = function(data) {
                if (!data.length) {
                    AlertFactory.show('No data', 'danger');
                    return;
                }

                Loader.showLoading('Exporting Results');

                var date = $filter('date')(new Date(), 'M-d-yy');

                var fileName = 'Import Instructors Results_' + date + '.xlsx';

                alasql.fn.datetime = function(date) {
                    if (date) {
                        date = new Date(date);
                        return $filter('date')(date, 'M/d/yyyy');
                    }
                    return '';
                };

                alasql.fn.get = function(value) {
                    if (!value && value !== 0) {
                        return '';
                    }
                    return value;
                };

                alasql.promise('SELECT * INTO XLSX("' + fileName + '", {headers:true}) FROM ?', [data])
                    .then(function() {
                        Loader.hideLoading();
                    }).catch(function(err) {
                        AlertFactory.show('Export Error', 'danger');
                        console.log(err);
                        Loader.hideLoading();
                    });
            };
        }
    ]);