angular
    .module("approver.semesters.proposals.proposal", ["app.models.users", "app.models.approvals"])
    .config(function ($stateProvider) {
        $stateProvider.state("approver.semesters.semester.proposals.proposal", {
            url: "/{proposalId:int}",
            views: {
                "": {
                    templateUrl: "approver/semesters/proposals/proposal/proposal.tmpl.html",
                    controller: "ApproverProposalCtrl as approverProposalCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Proposal",
            },
            resolve: {
                proposal: [
                    "$state",
                    "$stateParams",
                    "$q",
                    "UsersModel",
                    "Loader",
                    function ($state, $stateParams, $q, UsersModel, Loader) {
                        var semesterId = $stateParams.semesterId;
                        var proposalId = $stateParams.proposalId;

                        var deferred = $q.defer();

                        Loader.showLoading("Loading Proposal");
                        UsersModel.getApproverSemesterProposal(semesterId, proposalId)
                            .then(
                                function (proposal) {
                                    deferred.resolve(proposal);
                                },
                                function () {
                                    deferred.reject();
                                    $state.go("approver.semesters.semester.proposals", { semesterId: semesterId });
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });

                        return deferred.promise;
                    },
                ],
            },
        });
    })
    .controller("ApproverProposalCtrl", [
        "currentUser",
        "proposal",
        "$stateParams",
        "$uibModal",
        "$window",
        "UsersModel",
        "SemestersModel",
        "Loader",
        "AlertFactory",
        "ProposalsFactory",
        "ProposalApproversFactory",
        "ApprovalsModel",
        "ApprovalsFactory",
        function (
            currentUser,
            proposal,
            $stateParams,
            $uibModal,
            $window,
            UsersModel,
            SemestersModel,
            Loader,
            AlertFactory,
            ProposalsFactory,
            ProposalApproversFactory,
            ApprovalsModel,
            ApprovalsFactory
        ) {
            var approverProposalCtrl = this;

            var semesterId = $stateParams.semesterId;
            var proposalId = $stateParams.proposalId;

            var approvals = [];

            approverProposalCtrl.proposal = proposal;

            approverProposalCtrl.isApprover =
                approverProposalCtrl.proposal.approver.user_id == currentUser.id ? true : false;

            function getApprovals() {
                approvals = [];

                _.forEach(approverProposalCtrl.proposal.contracts, function (contract) {
                    var approval = ProposalApproversFactory.getApproval(proposal.approver, contract);

                    if (approval && approval.status !== null) {
                        approvals.push(approval);
                    }
                });
            }

            getApprovals();

            function getApproval(contract) {
                var approval = _.find(approvals, function (approval) {
                    return approval.contract_id == contract.parent_id;
                });

                return approval ? approval : null;
            }

            function getApprovalStatuses(contract) {
                var approvalStatuses = _.flatten(
                    _.map(
                        _.filter(proposal.approvals, function (approval) {
                            return approval.contract_id == contract.parent_id;
                        }),
                        "statuses"
                    )
                );

                if (approvalStatuses.length > 0) {
                    return approvalStatuses;
                }

                return null;
            }

            approverProposalCtrl.hasResponded = function () {
                return _.includes(["Approved", "Declined", "Needs Revised"], proposal.approver.status);
            };

            approverProposalCtrl.hasApproval = function (contract) {
                var approval = getApproval(contract);

                if (approval) {
                    return true;
                }

                return false;
            };

            approverProposalCtrl.hasApprovalStatuses = function (contract) {
                if (getApprovalStatuses(contract)) {
                    return true;
                }

                return false;
            };

            approverProposalCtrl.hasApprovalMessage = function (contract) {
                var approval = getApproval(contract);

                if (approval && approval.message) {
                    return true;
                }

                return false;
            };

            approverProposalCtrl.showApproval = function (contract) {
                var approval = getApproval(contract);

                var modalInstance = $uibModal.open({
                    templateUrl: "approver/semesters/proposals/proposal/approvals/approval.tmpl.html",
                    controller: [
                        "$uibModalInstance",
                        "contract",
                        "approval",
                        "hasResponded",
                        function ($uibModalInstance, contract, approval, hasResponded) {
                            var modalCtrl = this;

                            modalCtrl.contract = contract;
                            modalCtrl.approval = approval;
                            modalCtrl.hasResponded = hasResponded;

                            modalCtrl.message = approval && approval.message ? approval.message : null;

                            modalCtrl.save = function () {
                                $uibModalInstance.close(modalCtrl.message);
                            };

                            modalCtrl.cancel = function () {
                                $uibModalInstance.dismiss("cancel");
                            };
                        },
                    ],
                    controllerAs: "modalCtrl",
                    resolve: {
                        contract: function () {
                            return contract;
                        },
                        approval: function () {
                            return approval;
                        },
                        hasResponded: function () {
                            return approverProposalCtrl.hasResponded();
                        },
                    },
                });

                modalInstance.result.then(
                    function (message) {
                        approval.message = message;
                    },
                    function (result) {
                        if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                    }
                );
            };

            approverProposalCtrl.showApprovalStatuses = function (contract) {
                ApprovalsModel.getContractApprovalStatuses(contract.parent_id)
                    .then(function (statuses) {
                        modalCtrl.statuses = statuses;
                    })
                    .finally(function () {
                        modalCtrl.isLoading = false;
                    });

                var modalInstance = $uibModal.open({
                    templateUrl: "approver/semesters/proposals/proposal/approvals/approval-statuses.tmpl.html",
                    controller: [
                        "contract",
                        "approvalStatuses",
                        "$uibModalInstance",
                        function (contract, approvalStatuses, $uibModalInstance) {
                            var modalCtrl = this;

                            modalCtrl.contract = contract;
                            modalCtrl.approvalStatuses = approvalStatuses;

                            modalCtrl.cancel = function () {
                                $uibModalInstance.dismiss("cancel");
                            };
                        },
                    ],
                    controllerAs: "modalCtrl",
                    resolve: {
                        contract: function () {
                            return contract;
                        },
                        approvalStatuses: function () {
                            return getApprovalStatuses(contract);
                        },
                    },
                });

                modalInstance.result.catch(function (result) {
                    if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                });
            };

            approverProposalCtrl.getApprovalStatus = function (contract) {
                var approval = getApproval(contract);

                if (approval) {
                    return approval.status;
                }

                return null;
            };

            approverProposalCtrl.approveContract = function (contract) {
                approvals.push({
                    contract_id: contract.parent_id,
                    status: 1,
                    message: null,
                });
            };

            approverProposalCtrl.declineContract = function (contract) {
                approvals.push({
                    contract_id: contract.parent_id,
                    status: 0,
                    message: null,
                });

                approverProposalCtrl.showApproval(contract);
            };

            approverProposalCtrl.resetContract = function (contract) {
                _.remove(approvals, function (approval) {
                    return approval.contract_id == contract.parent_id;
                });
            };

            approverProposalCtrl.createApprovals = function () {
                var errors = [];

                var editedApprovals = [];

                _.forEach(approverProposalCtrl.proposal.contracts, function (contract) {
                    var approval = getApproval(contract);

                    if (!approval) {
                        errors.push(
                            "Please approve or decline the contract for " +
                                contract.user_first_name +
                                " " +
                                contract.user_last_name +
                                " &mdash; " +
                                contract.subject +
                                " " +
                                contract.course_catalog +
                                " (" +
                                contract.section_no +
                                ")."
                        );
                    } else if (approval && approval.status === 0 && !approval.message) {
                        errors.push(
                            "Please provide a reason for declining the contract for " +
                                contract.user_first_name +
                                " " +
                                contract.user_last_name +
                                " &mdash; " +
                                contract.subject +
                                " " +
                                contract.course_catalog +
                                " (" +
                                contract.section_no +
                                ")."
                        );
                    }

                    var editApproval = angular.copy(approval);

                    editApproval.contract_id = contract.id;

                    editedApprovals.push(editApproval);
                });

                if (errors.length) {
                    AlertFactory.show(errors.join("\r\n"), "danger");
                    return false;
                }

                Loader.showLoading("Updating Proposal");
                UsersModel.createApprovals(proposalId, editedApprovals)
                    .then(
                        function (updatedProposal) {
                            $window.scrollTo(0, 0);

                            _.merge(proposal, updatedProposal);

                            getApprovals();

                            SemestersModel.clearCache();

                            Loader.hideLoading();
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            approverProposalCtrl.exportProposalContracts = ProposalsFactory.exportProposalContracts;
            approverProposalCtrl.showContractApprovalStatuses = ApprovalsFactory.showContractApprovalStatuses;
        },
    ]);
