angular
    .module("admin.audits", ["app.models.audits", "infinite-scroll", "angular.filter", "app.filters"])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.audits", {
            url: "/audits",
            views: {
                "": {
                    templateUrl: "admin/audits/audits.tmpl.html",
                    controller: "AdminAuditsCtrl as auditsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Audits",
            },
            cache: false,
        });
    })
    .controller("AdminAuditsCtrl", [
        "$timeout",
        "$location",
        "Loader",
        "AuditsModel",
        "RolesModel",
        "ContractsModel",
        "InstructorsModel",
        "ApproversModel",
        "UsersModel",
        "LSFactory",
        "ContractsFactory",
        "InstructorsFactory",
        "ApproversFactory",
        "UsersFactory",
        function (
            $timeout,
            $location,
            Loader,
            AuditsModel,
            RolesModel,
            ContractsModel,
            InstructorsModel,
            ApproversModel,
            UsersModel,
            LSFactory,
            ContractsFactory,
            InstructorsFactory,
            ApproversFactory,
            UsersFactory
        ) {
            var auditsCtrl = this;

            var roles = [];
            RolesModel.getRoles().then(function (result) {
                roles = result;
            });

            var params = $location.search();

            auditsCtrl.types = [
                {
                    key: "App\\Contract",
                    value: "Contract",
                },
                {
                    key: "App\\User",
                    value: "User",
                },
                {
                    key: "App\\Instructor",
                    value: "Instructor",
                },
                {
                    key: "App\\Approver",
                    value: "Approver",
                },
            ];

            auditsCtrl.events = ["created", "updated", "deleted"];

            auditsCtrl.contractFields = [];
            _.forEach(ContractsModel.fields(), function (field) {
                if (!_.includes(["created_at", "updated_at"], field)) {
                    auditsCtrl.contractFields.push({
                        value: field,
                        label: ContractsModel.labels()[field],
                        group: ContractsModel.groups()[field],
                    });
                }
            });

            auditsCtrl.instructorFields = [];
            _.forEach(InstructorsModel.fields(), function (field) {
                if (!_.includes(["created_at", "updated_at"], field)) {
                    auditsCtrl.instructorFields.push({
                        value: field,
                        label: InstructorsModel.labels()[field],
                        group: InstructorsModel.groups()[field],
                    });
                }
            });

            auditsCtrl.approverFields = [];
            _.forEach(ApproversModel.fields(), function (field) {
                if (!_.includes(["created_at", "updated_at"], field)) {
                    auditsCtrl.approverFields.push({
                        value: field,
                        label: ApproversModel.labels()[field],
                        group: ApproversModel.groups()[field],
                    });
                }
            });

            auditsCtrl.userFields = [];
            _.forEach(UsersModel.fields(), function (field) {
                if (!_.includes(["created_at", "updated_at"], field)) {
                    auditsCtrl.userFields.push({
                        value: field,
                        label: UsersModel.labels()[field],
                        group: UsersModel.groups()[field],
                    });
                }
            });

            if (!_.isEmpty(params)) {
                if (params.page) {
                    auditsCtrl.page = parseInt(params.page);
                }

                if (params.type) {
                    auditsCtrl.type = _.find(auditsCtrl.types, function (type) {
                        return params.type == type.key;
                    });
                }

                if (params.id) {
                    auditsCtrl.id = params.id;
                }

                if (params.event) {
                    auditsCtrl.event = params.event;
                }

                if (params["field[]"]) {
                    if (auditsCtrl.type.value == "Contract") {
                        auditsCtrl.field = _.filter(auditsCtrl.contractFields, function (field) {
                            return _.includes(params["field[]"], field.value);
                        });
                    } else if (auditsCtrl.type.value == "Instructor") {
                        auditsCtrl.field = _.filter(auditsCtrl.instructorFields, function (field) {
                            return _.includes(params["field[]"], field.value);
                        });
                    } else if (auditsCtrl.type.value == "Approver") {
                        auditsCtrl.field = _.filter(auditsCtrl.approverFields, function (field) {
                            return _.includes(params["field[]"], field.value);
                        });
                    } else if (auditsCtrl.type.value == "User") {
                        auditsCtrl.field = _.filter(auditsCtrl.userFields, function (field) {
                            return _.includes(params["field[]"], field.value);
                        });
                    }
                }

                updateQueryString();
            }

            function updateQueryString() {
                $timeout(function () {
                    LSFactory.set("auditsQuery", params);
                    $location.search(params);
                });
            }

            function getAudits() {
                Loader.showLoading("Loading Audits");
                AuditsModel.getAudits(params).then(
                    function (result) {
                        auditsCtrl.audits = result;
                        auditsCtrl.users = result.users;

                        if (params && params["user[]"]) {
                            auditsCtrl.user = _.filter(auditsCtrl.users, function (user) {
                                return _.includes(params["user[]"], user.id);
                            });
                        }

                        Loader.hideLoading();
                    },
                    function () {
                        Loader.hideLoading();
                    }
                );
            }

            auditsCtrl.label = function (type, field) {
                if (type == "Contract") {
                    $labels = ContractsModel.labels();
                } else if (type == "Instructor") {
                    $labels = InstructorsModel.labels();
                } else if (type == "Approver") {
                    $labels = ApproversModel.labels();
                } else if (type == "User") {
                    $labels = UsersModel.labels();
                }

                if ($labels[field]) {
                    return $labels[field];
                }

                return field;
            };

            auditsCtrl.format = function (type, field, value) {
                if (type == "Contract") {
                    value = ContractsFactory.format(field, value);
                } else if (type == "Instructor") {
                    value = InstructorsFactory.format(field, value);
                } else if (type == "Approver") {
                    value = ApproversFactory.format(field, value);
                } else if (type == "User") {
                    value = UsersFactory.format(field, value);
                }

                if (field == "role_id") {
                    var role = _.find(roles, function (role) {
                        return role.id == value;
                    });

                    if (role) {
                        value = role.display_name + " (" + value + ")";
                    }
                }

                if (!value && value !== 0) {
                    value = "NULL";
                }

                return value;
            };

            auditsCtrl.get = function (type, id, auditable) {
                value = "N/A";

                if (auditable) {
                    if (type == "Contract") {
                        value =
                            ' <a href="#!/admin/semesters/' +
                            auditable.semester_id +
                            "/contracts/" +
                            id +
                            '">' +
                            auditable.subject +
                            " " +
                            auditable.course_catalog +
                            " (" +
                            auditable.section_no +
                            ")" +
                            "</a>";
                    } else {
                        value =
                            ' <a href="#!/admin/instructors/' +
                            id +
                            '">' +
                            auditable.user.first_name +
                            " " +
                            auditable.user.last_name +
                            "</a>";
                    }
                }

                return value;
            };

            auditsCtrl.search = function (field) {
                function searchAudits() {
                    params = {};

                    if (auditsCtrl.page) {
                        params.page = parseInt(auditsCtrl.page);
                    }

                    if (auditsCtrl.type) {
                        params.type = auditsCtrl.type.key;
                    }

                    if (auditsCtrl.id) {
                        params.id = auditsCtrl.id;
                    }

                    if (auditsCtrl.event) {
                        params.event = auditsCtrl.event;
                    }

                    if (auditsCtrl.user) {
                        params["user[]"] = _.map(auditsCtrl.user, "id");
                    }

                    if (auditsCtrl.field) {
                        params["field[]"] = _.map(auditsCtrl.field, "value");
                    }

                    updateQueryString();

                    getAudits();
                }

                if (field == "type") {
                    auditsCtrl.id = null;
                    auditsCtrl.users = null;
                    auditsCtrl.field = null;
                }

                searchAudits();
            };

            auditsCtrl.clear = function (field) {
                if (field) {
                    if (field == "type") {
                        auditsCtrl.type = null;
                        auditsCtrl.id = null;
                        auditsCtrl.user = null;
                        auditsCtrl.field = null;
                    }
                    if (field == "id") {
                        auditsCtrl.id = null;
                    }
                    if (field == "event") {
                        auditsCtrl.event = null;
                    }
                } else {
                    auditsCtrl.type = null;
                    auditsCtrl.id = null;
                    auditsCtrl.event = null;
                    auditsCtrl.user = null;
                    auditsCtrl.field = null;
                }
                auditsCtrl.page = 1;
                auditsCtrl.search();
            };

            getAudits();

            auditsCtrl.getAudits = getAudits;
        },
    ]);
