angular
    .module("admin.instructors.instructor.contracts.contract.revisions.revision", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.instructors.instructor.contracts.contract.revisions.revision", {
            url: "/{revisionId:int}",
            views: {
                "": {
                    templateUrl:
                        "admin/instructors/instructor/contracts/contract/revisions/revision/contract-revision.tmpl.html",
                    controller: "AdminInstructorContractRevisionCtrl as contractRevisionCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Revision",
            },
        });
    })
    .controller("AdminInstructorContractRevisionCtrl", [
        "contract",
        "$scope",
        "$stateParams",
        "ContractsFactory",
        function (contract, $scope, $stateParams, ContractsFactory) {
            var contractRevisionCtrl = this;
            var revisionId = $stateParams.revisionId;
            var modified = [];

            var revision = _.find(contract.revisions, function (revision) {
                return revisionId == revision.id;
            });

            if (!revision) {
                revision = contract.revisions[0];
                revisionId = revision.id;
            }

            revision.isRevision = true;

            $scope.$emit("revisionIdUpdated", revisionId);

            $scope.contract = angular.copy(revision);

            var index = _.findIndex(contract.revisions, function (revision) {
                return revisionId == revision.id;
            });

            var prevRevision = contract.revisions[index + 1];

            if (prevRevision) {
                modified = _.pickBy(prevRevision, function (value, key) {
                    return revision[key] !== value;
                });
            }

            contractRevisionCtrl.isModified = function (key) {
                return _.includes(_.keys(modified), key);
            };

            contractRevisionCtrl.getModified = function (key) {
                var value = _.find(modified, function (value, _key) {
                    return key == _key;
                });

                value = ContractsFactory.format(key, value);

                if (!value && value !== 0 && value !== false) {
                    return "NULL";
                }

                return value;
            };
        },
    ]);
