angular
    .module("admin.users.user", ["app.models.roles", "admin.users.audits", "admin.users.delete"])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.users.user", {
            url: "/{userId:int}",
            views: {
                "": {
                    templateUrl: "admin/users/user/user.tmpl.html",
                    controller: "UserCtrl as userCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "User",
            },
            resolve: {
                user: [
                    "$q",
                    "$stateParams",
                    "Loader",
                    "UsersModel",
                    function ($q, $stateParams, Loader, UsersModel) {
                        var deferred = $q.defer();
                        var userId = $stateParams.userId;

                        Loader.showLoading("Loading User");
                        UsersModel.getUser(userId)
                            .then(
                                function (user) {
                                    deferred.resolve(user);
                                },
                                function () {
                                    deferred.reject();
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });

                        return deferred.promise;
                    },
                ],
            },
        });
    })
    .controller("UserCtrl", [
        "currentUser",
        "user",
        "$rootScope",
        "$scope",
        "$state",
        "$timeout",
        "UsersModel",
        "UsersFactory",
        "RolesModel",
        "SemestersModel",
        "InstructorsModel",
        "ApproversModel",
        "$uibModal",
        "AlertFactory",
        function (
            currentUser,
            user,
            $rootScope,
            $scope,
            $state,
            $timeout,
            UsersModel,
            UsersFactory,
            RolesModel,
            SemestersModel,
            InstructorsModel,
            ApproversModel,
            $uibModal,
            AlertFactory
        ) {
            var userCtrl = this;

            userCtrl.currentUser = currentUser;

            userCtrl.limit = 50;
            userCtrl.isDisabled = true;

            user.editable = UsersFactory.getEditable(user);
            userCtrl.user = user;

            RolesModel.getRoles().then(function (roles) {
                var rolesList = [];
                _.forEach(roles, function (role) {
                    if (
                        role.status == "unlocked" ||
                        (role.status == "locked" && _.includes(_.map(user.roles, "name"), role.name))
                    ) {
                        role.permsList = _.map(role.perms, "display_name").join("<br>");
                        rolesList.push(role);
                    }
                });
                userCtrl.rolesList = rolesList;
            });

            SemestersModel.getSemesters().then(function (semesters) {
                if (semesters.length > 0) {
                    userCtrl.semester = semesters[0];
                }
            });

            var rolesTimeout;

            userCtrl.updateRoles = function () {
                userCtrl.is_role_loading = true;

                if (rolesTimeout) {
                    $timeout.cancel(rolesTimeout);
                }

                rolesTimeout = $timeout(
                    function () {
                        var editedUser = {};

                        editedUser.id = userCtrl.user.id;

                        editedUser.roles = userCtrl.user.editable.roles.value;

                        UsersModel.updateUser(editedUser).then(function (result) {
                            if (userCtrl.currentUser.id == userCtrl.user.id) {
                                userCtrl.currentUser.roles = result.roles;
                            }

                            userCtrl.user.rolesList = _.map(result.roles, "display_name").join(", ");

                            userCtrl.editedUser = angular.copy(userCtrl.user);

                            $rootScope.$broadcast("update-roles");

                            userCtrl.is_role_loading = false;

                            userCtrl.user.updated_at = moment().format();
                        });
                    },
                    500,
                    false
                );
            };

            userCtrl.saveEdit = function (editable) {
                editable.saveEdit().then(
                    function (result) {
                        InstructorsModel.clearCache();
                        ApproversModel.clearCache();
                        SemestersModel.clearCache();
                    },
                    function (result) {
                        if (result.errors) {
                            AlertFactory.show(_(result.errors).values().flatten().value().join("\r\n"), "danger");
                        }

                        $timeout(function () {
                            userCtrl.userForm[editable.key].$invalid = true;
                        });
                    }
                );
            };

            userCtrl.deleteUser = function (user) {
                $uibModal
                    .open({
                        animation: true,
                        templateUrl: "admin/users/user/delete/user-delete.tmpl.html",
                        controller: "DeleteUserCtrl as deleteUserCtrl",
                        resolve: {
                            user: function () {
                                return user;
                            },
                        },
                    })
                    .result.then(
                        function () {
                            $scope.$emit("users.update");
                            $state.go("admin.users");
                        },
                        function () {}
                    );
            };

            userCtrl.setLimit = function (max) {
                if (userCtrl.limit < max) {
                    $timeout(function () {
                        userCtrl.limit += 50;
                    });
                }
            };

            userCtrl.isInstructor = function (user) {
                if (!user) {
                    return false;
                }

                return _.includes(_.map(user.roles, "name"), "instructor");
            };

            userCtrl.hasPermission = UsersFactory.hasPermission;
        },
    ]);
