angular
    .module("admin.semesters.semester.contracts.import", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.contracts.import", {
            url: "/import",
            views: {
                import: {
                    templateUrl: "admin/semesters/contracts/import/contracts-import.tmpl.html",
                    controller: "ImportContractsCtrl as importContractsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Import Schedule of Classes",
            },
        });
    })
    .controller("ImportContractsCtrl", [
        "semester",
        "$scope",
        "$state",
        "$stateParams",
        "$filter",
        "SemestersModel",
        "Loader",
        "AlertFactory",
        function (semester, $scope, $state, $stateParams, $filter, SemestersModel, Loader, AlertFactory) {
            var importContractsCtrl = this;
            var semesterId = $stateParams.semesterId;
            importContractsCtrl.contracts = {};

            importContractsCtrl.importContracts = function () {
                Loader.showLoading("Importing SOC");

                var fd = new FormData();
                fd.append("file", importContractsCtrl.file);
                fd.append("excludeCancelled", importContractsCtrl.excludeCancelled);

                importContractsCtrl.importContractsForm.successes = null;
                importContractsCtrl.importContractsForm.errors = null;
                importContractsCtrl.importContractsForm.error = null;

                SemestersModel.importContracts(semesterId, fd)
                    .then(
                        function (result) {
                            if (result) {
                                if (result.successes && result.successes.length) {
                                    importContractsCtrl.importContractsForm.successes = result.successes;
                                }
                                if (result.errors && result.errors.length) {
                                    importContractsCtrl.importContractsForm.errors = result.errors;
                                }
                                if (result.data && result.data.length) {
                                    importContractsCtrl.importContractsForm.data = result.data;
                                }
                                $scope.$emit("contracts.import");
                            } else {
                                $state.go("admin.semesters.semester.contracts");
                            }
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            importContractsCtrl.exportResults = function (data) {
                if (!data.length) {
                    AlertFactory.show("No data", "danger");
                    return;
                }

                Loader.showLoading("Exporting Results");

                var term = semester.term.name.substring(0, 2).toUpperCase();
                var year = String(semester.year).substring(2, 4);
                var date = $filter("date")(new Date(), "M-d-yy");

                var fileName = term + year + "_Import SOC Results_" + date + ".xlsx";

                alasql.fn.datetime = function (date) {
                    if (date) {
                        date = new Date(date);
                        return $filter("date")(date, "M/d/yyyy");
                    }
                    return "";
                };

                alasql.fn.get = function (value) {
                    if (!value && value !== 0) {
                        return "";
                    }
                    return value;
                };

                alasql
                    .promise('SELECT * INTO XLSX("' + fileName + '", {headers:true}) FROM ?', [data])
                    .then(function () {
                        Loader.hideLoading();
                    })
                    .catch(function (err) {
                        AlertFactory.show("Export Error", "danger");
                        console.log(err);
                        Loader.hideLoading();
                    });
            };
        },
    ]);
