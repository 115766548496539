angular
    .module("admin.semesters.semester.proposals.proposal.approvers.statuses", [])
    .controller("ProposalApproverStatusesCtrl", [
        "proposalApprover",
        "$uibModalInstance",
        function (proposalApprover, $uibModalInstance) {
            var proposalApproverStatusesCtrl = this;

            proposalApproverStatusesCtrl.proposalApprover = proposalApprover;

            proposalApproverStatusesCtrl.getPanelClass = function (status) {
                var panelClass = "panel-default";

                if (status == "Approved") {
                    panelClass = "panel-success";
                } else if (status == "Declined") {
                    panelClass = "panel-danger";
                } else if (status == "Needs Revised") {
                    panelClass = "panel-warning";
                }

                return panelClass;
            };

            proposalApproverStatusesCtrl.cancel = function () {
                $uibModalInstance.dismiss("cancel");
            };
        },
    ]);
