angular
    .module("admin.semesters.semester.proposals.create", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.proposals.create", {
            url: "/create",
            views: {
                create: {
                    templateUrl: "admin/semesters/proposals/create/proposal-create.tmpl.html",
                    controller: "CreateProposalCtrl as createProposalCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Create Proposal",
            },
        });
    })
    .controller("CreateProposalCtrl", [
        "$state",
        "$stateParams",
        "SemestersModel",
        "Loader",
        "AlertFactory",
        function ($state, $stateParams, SemestersModel, Loader, AlertFactory) {
            var createProposalCtrl = this;
            var semesterId = $stateParams.semesterId;

            createProposalCtrl.returnToProposals = function () {
                $state.go("admin.semesters.semester.proposals", { semesterId: semesterId });
            };

            createProposalCtrl.createProposal = function (newProposal) {
                Loader.showLoading("Creating Proposal");
                SemestersModel.createProposal(semesterId, newProposal)
                    .then(
                        function (proposal) {
                            $state.go("admin.semesters.semester.proposals.proposal.approvers", {
                                proposalId: proposal.id,
                            });
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };
        },
    ]);
