angular.module("app.models.approvals", []).service("ApprovalsModel", [
    "$http",
    "$q",
    "API_URL",
    function ($http, $q, API_URL) {
        var model = this,
            approvals,
            API = {
                getApprovals: function (proposalId, approverId) {
                    return $http.get(API_URL + "approvals");
                },
                getApprover: function (approverId) {
                    return $http.get(API_URL + "approvers/" + approverId);
                },
                createApprovals: function (approvals) {
                    return $http.post(API_URL + "approvals", approvals);
                },
                updateApprover: function (approver) {
                    return $http.patch(API_URL + "approvers/" + approver.id, approver);
                },
                deleteApprover: function (approver) {
                    return $http.delete(API_URL + "approvers/" + approver.id);
                },
                addContracts: function (approver) {
                    return $http.patch(API_URL + "approvers/" + approver.id, approver);
                },

                /*
                 * Statuses
                 */
                getApprovalStatuses: function (approvalId) {
                    return $http.get(API_URL + "approvals/" + approvalId + "/statuses");
                },

                getContractApprovalStatuses: function (contractId) {
                    return $http.get(API_URL + "contracts/" + contractId + "/approvals/statuses");
                },
            };

        /*
         * Statuses
         */

        model.getApprovalStatuses = function (approvalId) {
            var deferred = $q.defer();

            API.getApprovalStatuses(approvalId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.getContractApprovalStatuses = function (contractId) {
            var deferred = $q.defer();

            API.getContractApprovalStatuses(contractId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        var settings = {
            showClass: true,
            showInstructor: true,
            showCompensation: true,
        };

        model.getSettings = function () {
            return settings;
        };

        model.updateSettings = function (_settings) {
            settings = _settings;
        };

        function extract(result) {
            return result.data;
        }

        function cacheApprovers(result) {
            approvers = extract(result);

            return approvers;
        }

        model.getApprovers = function (semesterId) {
            if (approvers) {
                return $q.when(approvers);
            } else {
                return API.getApprovers(semesterId).then(cacheApprovers);
            }
        };

        model.getApprover = function (approverId) {
            var deferred = $q.defer();

            function findApprover() {
                return _.find(approvers, function (approver) {
                    return approver.id === approverId;
                });
            }

            if (approvers) {
                deferred.resolve(findApprover());
            } else {
                API.getApprover(approverId).then(function (approver) {
                    deferred.resolve(extract(approver));
                });
            }

            return deferred.promise;
        };

        model.createApprovals = function (approvals) {
            var deferred = $q.defer();

            API.createApprovals(approvals).then(
                function (result) {
                    var approvals = extract(result);
                    deferred.resolve(approvals);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.updateApprover = function (approver) {
            API.updateApprover(approver);
        };

        model.deleteApprover = function (approver) {
            var deferred = $q.defer();

            API.deleteApprover(approver)
                .success(function () {
                    _.remove(approvers, function (_approver) {
                        return _approver.id === approver.id;
                    });
                })
                .finally(function () {
                    deferred.resolve();
                });

            return deferred.promise;
        };

        model.addContracts = function (contracts) {
            API.addContracts(contracts);
        };

        model.clearCache = function () {
            approvals = null;
        };
    },
]);
