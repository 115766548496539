angular
    .module("admin.notes", ["app.models.notes", "app.factories.notes", "admin.notes.delete"])
    .controller("NotesCtrl", [
        "noteable",
        "noteableType",
        "link",
        "$uibModalInstance",
        "UsersModel",
        "NotesModel",
        "Loader",
        "AlertFactory",
        "NotesFactory",
        function (
            noteable,
            noteableType,
            link,
            $uibModalInstance,
            UsersModel,
            NotesModel,
            Loader,
            AlertFactory,
            NotesFactory
        ) {
            var notesCtrl = this;

            notesCtrl.currentUser = UsersModel.getCurrentUser();

            notesCtrl.isCreatingNote = false;

            noteable.notes_unread = 0;

            notesCtrl.noteable = noteable;

            notesCtrl.link = link;

            Loader.showLoading("Loading Notes");

            NotesModel.getNotes(noteable.parent_id || noteable.id || noteable.user_id, noteableType).then(function (
                notes
            ) {
                notesCtrl.notes = notes;

                notesCtrl.isCreatingNote = notes.length ? false : true;

                Loader.hideLoading();
            });

            notesCtrl.showCreateNote = function () {
                notesCtrl.note = "";
                notesCtrl.isCreatingNote = true;
            };

            notesCtrl.deleteNote = function (note) {
                NotesFactory.deleteNote(noteable, note)
                    .then(function (noteId) {
                        _.remove(notesCtrl.notes, function (note) {
                            return note.id == noteId;
                        });
                        noteable.has_notes = notesCtrl.notes.length > 0;
                    })
                    .catch(function () {});
            };

            notesCtrl.save = function (note) {
                Loader.showLoading("Creating Note");

                NotesModel.createNote(noteable.parent_id || noteable.id || noteable.user_id, noteableType, note)
                    .then(
                        function (note) {
                            notesCtrl.notes.unshift(note);
                            noteable.has_notes = true;
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            notesCtrl.cancel = function () {
                $uibModalInstance.dismiss("cancel");
            };
        },
    ]);
