angular
    .module("admin.semesters.semester.proposals.proposal.approvers.replace", [
        "app.models.approvers",
        "admin.semesters.semester.proposals.proposal.approvers.replace.confirm",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.proposals.proposal.approvers.replace", {
            url: "/{proposalApproverId:int}/replace",
            views: {
                replace: {
                    templateUrl: "admin/semesters/proposals/approvers/replace/approver-replace.tmpl.html",
                    controller: "ReplaceProposalApproverCtrl as replaceProposalApproverCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Replace Aprover",
            },
        });
    })
    .controller("ReplaceProposalApproverCtrl", [
        "proposal",
        "$state",
        "$stateParams",
        "$uibModal",
        "SemestersModel",
        "ApproversModel",
        "ProposalsModel",
        "Loader",
        "AlertFactory",
        function (
            proposal,
            $state,
            $stateParams,
            $uibModal,
            SemestersModel,
            ApproversModel,
            ProposalsModel,
            Loader,
            AlertFactory
        ) {
            var replaceProposalApproverCtrl = this;
            var semesterId = $stateParams.semesterId;
            var proposalId = $stateParams.proposalId;
            var proposalApproverId = $stateParams.proposalApproverId;

            var proposalApprover = _.find(proposal.approvers, function (proposalApprover) {
                return proposalApprover.id == proposalApproverId;
            });

            replaceProposalApproverCtrl.proposalApprover = proposalApprover;

            replaceProposalApproverCtrl.roles = ApproversModel.roles();
            _.remove(replaceProposalApproverCtrl.roles, { name: "director" });

            replaceProposalApproverCtrl.role = proposalApprover.role.name;

            Loader.showLoading("Loading Approvers");
            ApproversModel.getApprovers().then(function (approvers) {
                _.forEach(approvers, function (approver) {
                    approver.label =
                        approver.user.first_name +
                        " " +
                        approver.user.last_name +
                        " (Role: " +
                        approver.role.display_name +
                        ")";
                    if (approver.college) {
                        approver.label += " (College: " + approver.college + ")";
                    }
                    if (approver.department) {
                        approver.label += " (Department: " + approver.department + ")";
                    }
                });

                replaceProposalApproverCtrl.approvers = approvers;
                replaceProposalApproverCtrl.colleges = _.compact(_.uniq(_.map(approvers, "college")));
                replaceProposalApproverCtrl.departments = _.compact(_.uniq(_.map(approvers, "department")));

                Loader.hideLoading();
            });

            replaceProposalApproverCtrl.filterRole = function () {
                return {
                    role: {
                        name: replaceProposalApproverCtrl.role,
                    },
                };
            };

            replaceProposalApproverCtrl.filterCollege = function () {
                if (replaceProposalApproverCtrl.college) {
                    return {
                        college: replaceProposalApproverCtrl.college,
                    };
                }

                return "";
            };

            replaceProposalApproverCtrl.filterDepartment = function () {
                if (replaceProposalApproverCtrl.department) {
                    return {
                        department: replaceProposalApproverCtrl.department,
                    };
                }

                return "";
            };

            replaceProposalApproverCtrl.returnToApprovers = function () {
                $state.go("admin.semesters.semester.proposals.proposal.approvers");
            };

            replaceProposalApproverCtrl.replaceProposalApprover = function (proposalApproverReplacement) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl:
                        "admin/semesters/proposals/approvers/replace/confirm/approver-replace-confirm.tmpl.html",
                    controller: "ConfirmReplaceProposalApproverCtrl as confirmReplaceProposalApproverCtrl",
                    resolve: {
                        proposalApprover: function () {
                            return proposalApprover;
                        },
                        proposalApproverReplacement: function () {
                            return proposalApproverReplacement;
                        },
                    },
                });

                modalInstance.result
                    .then(function () {
                        Loader.showLoading("Replacing Contract");
                        ProposalsModel.replaceProposalApprover(
                            proposalId,
                            proposalApprover.id,
                            proposalApproverReplacement
                        )
                            .then(
                                function (updatedProposal) {
                                    _.merge(proposal, updatedProposal);

                                    SemestersModel.clearContractsCache(semesterId);

                                    replaceProposalApproverCtrl.returnToApprovers();
                                },
                                function (result) {
                                    if (result.errors) {
                                        AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                                    }
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });
                    })
                    .catch(function (res) {
                        if (["cancel", "backdrop click", "escape key press"].indexOf(res) === -1) throw res;
                    });
            };

            replaceProposalApproverCtrl.resetForm = function () {
                replaceProposalApproverCtrl.replaceProposalApproverForm.approver.$setValidity("unique", true);
                replaceProposalApproverCtrl.replaceProposalApproverForm.role.$setValidity("isEqual", true);
                replaceProposalApproverCtrl.replaceProposalApproverForm.$setPristine();
            };
        },
    ]);
