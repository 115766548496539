angular
    .module("admin.semesters.semester.contracts.contract.revisions.create", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.contracts.contract.revisions.create", {
            url: "/create",
            views: {
                create: {
                    templateUrl:
                        "admin/semesters/contracts/contract/revisions/create/contract-revision-create.tmpl.html",
                    controller: "CreateContractRevisionCtrl as createRevisionCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Create Revision",
            },
        });
    })
    .controller("CreateContractRevisionCtrl", [
        "contract",
        "$scope",
        "$state",
        "$uibModal",
        "ContractsModel",
        "Loader",
        "AlertFactory",
        "ContractsFactory",
        function (contract, $scope, $state, $uibModal, ContractsModel, Loader, AlertFactory, ContractsFactory) {
            var createRevisionCtrl = this;
            var revision = angular.copy(contract);

            revision.type = null;
            revision.isCreating = true;
            revision.editable = ContractsFactory.getEditable(revision, true);
            createRevisionCtrl.revision = revision;
            $scope.contract = revision;

            function createRevision(revision) {
                Loader.showLoading("Creating Revision");

                // remove unnecessary objects
                editedRevision = _.omitBy(angular.copy(revision), function (value) {
                    return _.isObject(value) && !_.isDate(value);
                });

                ContractsModel.createContractRevision(editedRevision)
                    .then(
                        function (revision) {
                            _.assign(contract, revision);

                            $scope.$emit("revisionCreated", revision);

                            $scope.$emit("revisionIdUpdated", revision.id);

                            $scope.$emit("contracts.update");

                            $state.go("admin.semesters.semester.contracts.contract.revisions.revision", {
                                revisionId: revision.id,
                            });
                        },
                        function (result) {
                            AlertFactory.show(result.errors, "danger");
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            }

            createRevisionCtrl.preview = function (revision) {
                var modalInstance = $uibModal.open({
                    templateUrl: "common/templates/emails/email.tmpl.html",
                    controller: [
                        "$uibModalInstance",
                        "ContractsModel",
                        function ($uibModalInstance, ContractsModel) {
                            var contractCtrl = this;

                            Loader.showLoading("Loading Email Preview");
                            ContractsModel.previewContractRevisionEmail(revision)
                                .then(
                                    function (message) {
                                        contractCtrl.subject = message.subject;
                                        contractCtrl.view = message.view;
                                    },
                                    function () {
                                        $state.go("admin.semesters.semester.contracts");
                                    }
                                )
                                .finally(function () {
                                    Loader.hideLoading();
                                });

                            contractCtrl.save = function () {
                                $uibModalInstance.close(contractCtrl.note);
                            };

                            contractCtrl.close = function () {
                                $uibModalInstance.dismiss("cancel");
                            };
                        },
                    ],
                    controllerAs: "contractCtrl",
                    size: "lg",
                });

                modalInstance.result
                    .then(function (note) {
                        createRevisionCtrl.revision.note = note;
                        createRevision(createRevisionCtrl.revision);
                    })
                    .catch(function (result) {
                        if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                    });
            };

            createRevisionCtrl.createRevision = createRevision;
        },
    ]);
