angular.module("admin.semesters.semester.contracts.contract.notes.delete", []).controller("DeleteContractNoteCtrl", [
    "contract",
    "note",
    "$uibModalInstance",
    function (contract, note, $uibModalInstance) {
        var deleteContractNoteCtrl = this;

        deleteContractNoteCtrl.contract = contract;
        deleteContractNoteCtrl.note = note;

        deleteContractNoteCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        deleteContractNoteCtrl.delete = function () {
            $uibModalInstance.close();
        };
    },
]);
