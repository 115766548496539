angular.module("app.models.notes", []).service("NotesModel", [
    "$http",
    "$q",
    "API_URL",
    function ($http, $q, API_URL) {
        var model = this,
            API = {
                getNotes: function (noteableId, noteableType) {
                    return $http.get(API_URL + "notes", {
                        params: {
                            noteable_id: noteableId,
                            noteable_type: noteableType,
                        },
                    });
                },
                createNote: function (noteableId, noteableType, note) {
                    return $http.post(API_URL + "notes", {
                        noteable_id: noteableId,
                        noteable_type: noteableType,
                        note: note,
                    });
                },
                createNotes: function (noteableIds, noteableType, note) {
                    return $http.post(API_URL + "notes", {
                        noteable_ids: noteableIds,
                        noteable_type: noteableType,
                        note: note,
                    });
                },
                deleteNote: function (noteId) {
                    return $http.delete(API_URL + "notes/" + noteId);
                },
            };

        function extract(result) {
            return result.data;
        }

        model.getNotes = function (noteableId, noteableType) {
            var deferred = $q.defer();

            API.getNotes(noteableId, noteableType).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.createNote = function (noteableId, noteableType, note) {
            var deferred = $q.defer();

            API.createNote(noteableId, noteableType, note).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.createNotes = function (noteableIds, noteableType, note) {
            var deferred = $q.defer();

            API.createNotes(noteableIds, noteableType, note).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteNote = function (noteId) {
            var deferred = $q.defer();

            API.deleteNote(noteId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };
    },
]);
