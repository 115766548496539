angular.module('admin.users.delete', [])
    .controller('DeleteUserCtrl', ['user', '$scope', '$state', '$stateParams', '$uibModalInstance', 'UsersModel', 'Loader', 'AlertFactory',
        function(user, $scope, $state, $stateParams, $uibModalInstance, UsersModel, Loader, AlertFactory) {
            var deleteUserCtrl = this;
            var userId = $stateParams.userId;

            deleteUserCtrl.user = user;

            deleteUserCtrl.cancel = function() {
                $uibModalInstance.dismiss('cancel');
            };

            deleteUserCtrl.delete = function() {
                Loader.showLoading('Deleting User');
                UsersModel.deleteUser(userId).then(function() {
                    $uibModalInstance.close();
                }, function(result) {
                    if (result.errors) {
                        var messages = [];
                        _.forEach(result.errors, function(error) {
                            messages.push(error);
                        });
                        AlertFactory.show(messages.join("\r\n"), 'danger');
                    }
                }).finally(function() {
                    deleteUserCtrl.cancel();
                    Loader.hideLoading();
                });
            };

            $uibModalInstance.result.catch(function() { $uibModalInstance.dismiss('cancel'); });
        }
    ]);