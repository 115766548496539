angular.module("app.models.approvers", []).service("ApproversModel", [
    "$http",
    "$q",
    "API_URL",
    function ($http, $q, API_URL) {
        var model = this,
            approvers,
            roles,
            proposals,
            API = {
                /**
                 * Approvers
                 */

                getApprovers: function () {
                    return $http.get(API_URL + "approvers");
                },
                createApprover: function (approver) {
                    return $http.post(API_URL + "approvers", approver);
                },
                importApprovers: function (approvers) {
                    return $http.post(API_URL + "approvers/import", approvers, {
                        transformRequest: angular.identity,
                        headers: { "Content-Type": undefined },
                    });
                },
                updateApprover: function (approver) {
                    return $http.patch(API_URL + "approvers/" + approver.user_id, approver);
                },
                updateDirector: function (approver) {
                    return $http.patch(API_URL + "approvers/" + approver.user_id + "/director", approver);
                },
                deleteApprover: function (approver) {
                    return $http.delete(API_URL + "approvers/" + approver.user_id);
                },

                /**
                 * Approver Roles
                 */

                getRoles: function () {
                    return $http.get(API_URL + "approvers/roles");
                },

                /**
                 * Approver Audits
                 */

                getAudits: function (approverId, params) {
                    return $http.get(API_URL + "approvers/" + approverId + "/audits", { params: params });
                },

                /**
                 * Approver Proposals
                 */

                getProposals: function (userId) {
                    return $http.get(API_URL + "approvers/" + userId + "/proposals");
                },
                addContracts: function (approver) {
                    return $http.patch(API_URL + "approvers/" + approver.user_id, approver);
                },
                createApprovals: function (userId, proposalId, approvals) {
                    return $http.post(API_URL + "approvers/" + userId + "/proposals/" + proposalId, approvals);
                },

                /**
                 * Approver Members
                 */

                addApproverMember: function (userId, member) {
                    return $http.post(API_URL + "approvers/" + userId + "/members", member);
                },
                removeApproverMember: function (userId, memberId) {
                    return $http.delete(API_URL + "approvers/" + userId + "/members/" + memberId);
                },
            };

        model.roles = function () {
            return [
                {
                    name: "chair",
                    display_name: "Chair/Director",
                    html: "Chair/Director",
                },
                {
                    name: "dean",
                    display_name: "Dean/Assoc.",
                    html: "Dean/Assoc.",
                },
                {
                    name: "grad-dean",
                    display_name: "Grad Dean",
                    html: "Grad Dean",
                },
                {
                    name: "provost",
                    display_name: "Provost",
                    html: "Provost",
                },
                {
                    name: "chancellor",
                    display_name: "Chancellor",
                    html: "Chancellor",
                },
                {
                    name: "director",
                    display_name: "DLL Director of Operations",
                    html: "DLL Director<br>of Operations",
                },
            ];
        };

        model.fields = function () {
            return ["user_id", "role_id", "college", "department", "is_archived", "created_at", "updated_at"];
        };

        model.labels = function () {
            return {
                user_id: "User ID",
                role_id: "Role ID",
                college: "College",
                department: "Dept",
                is_archived: "Archived?",
            };
        };

        model.groups = function () {
            return {
                user_id: "Approver",
                role_id: "Approver",
                college: "Approver",
                department: "Approver",
                is_archived: "Approver",
            };
        };

        model.dates = function () {
            return ["created_at", "updated_at"];
        };

        model.times = function () {
            return [];
        };

        model.booleans = function () {
            return ["is_archived"];
        };

        function extract(result) {
            return result.data;
        }

        function cacheRoles(result) {
            roles = extract(result);

            return roles;
        }

        function cacheApprovers(result) {
            approvers = extract(result);

            return approvers;
        }

        function cacheProposals(result) {
            proposals = extract(result);

            return proposals;
        }

        /**
         * Approvers
         */

        model.getApprovers = function () {
            if (approvers) {
                return $q.when(approvers);
            } else {
                approvers = API.getApprovers().then(cacheApprovers);
                return approvers;
            }
        };

        model.getApprover = function (approverId) {
            var deferred = $q.defer();
            var approver;

            function findApprover() {
                var approver = _.find(approvers, function (approver) {
                    return approver.user_id == approverId;
                });

                if (approver) {
                    deferred.resolve(approver);
                } else {
                    deferred.reject();
                }
            }

            if (approvers) {
                $q.when(approvers).then(function () {
                    findApprover();
                });
            } else {
                model.getApprovers().then(function () {
                    findApprover();
                });
            }

            return deferred.promise;
        };

        model.createApprover = function (approver) {
            var deferred = $q.defer();

            API.createApprover(approver).then(
                function (result) {
                    approver = extract(result);
                    approvers.push(approver);
                    deferred.resolve(approver);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.importApprovers = function (approvers) {
            var deferred = $q.defer();

            API.importApprovers(approvers).then(
                function (result) {
                    var data = extract(result);
                    approvers = data.approvers;
                    cacheApprovers({ data: approvers });

                    model.getApprovers().then(function (approvers) {
                        deferred.resolve(approvers);
                    });
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.updateApprover = function (approver) {
            var deferred = $q.defer();

            API.updateApprover(approver).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.updateDirector = function (approver) {
            var deferred = $q.defer();

            API.updateDirector(approver).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteApprover = function (approver) {
            var deferred = $q.defer();

            API.deleteApprover(approver).then(
                function (result) {
                    _.remove(approvers, function (_approver) {
                        return _approver.user_id === approver.user_id;
                    });
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /**
         * Approver Roles
         */

        model.getRoles = function () {
            if (roles) {
                return $q.when(roles);
            } else {
                return API.getRoles().then(cacheRoles);
            }
        };

        /**
         * Approver Audits
         */

        model.getAudits = function (approverId, params) {
            var deferred = $q.defer();

            API.getAudits(approverId, params).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.addContracts = function (contracts) {
            API.addContracts(contracts);
        };

        model.getProposals = function (userId) {
            if (proposals) {
                return $q.when(proposals);
            } else {
                proposals = API.getProposals(userId).then(cacheProposals);
                return proposals;
            }
        };

        model.getProposal = function (userId, proposalId) {
            var deferred = $q.defer();
            var proposal;

            function findProposal() {
                var proposal = _.find(proposals, function (proposal) {
                    return proposal.id == proposalId;
                });

                if (proposal) {
                    deferred.resolve(proposal);
                } else {
                    deferred.reject();
                }
            }

            if (proposals) {
                $q.when(proposals).then(function () {
                    findProposal();
                });
            } else {
                model.getProposals(userId).then(function () {
                    findProposal();
                });
            }

            return deferred.promise;
        };

        model.createApprovals = function (userId, proposalId, approvals) {
            var deferred = $q.defer();

            API.createApprovals(approvals).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.addApproverMember = function (userId, member) {
            var deferred = $q.defer();

            API.addApproverMember(userId, member).then(
                function (result) {
                    var member = extract(result);
                    model.getApprover(userId).then(function (approver) {
                        approver.members.push(member);
                        deferred.resolve(member);
                    });
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.removeApproverMember = function (userId, approverMemberId) {
            var deferred = $q.defer();

            API.removeApproverMember(userId, approverMemberId).then(
                function (result) {
                    var member = extract(result);
                    model.getApprover(userId).then(function (approver) {
                        _.remove(approver.members, function (approverMember) {
                            return approverMember.id === approverMemberId;
                        });
                        deferred.resolve(member);
                    });
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.clearCache = function () {
            approvers = null;
            roles = null;
            proposals = null;
        };
    },
]);
