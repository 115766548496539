angular
    .module("instructor.semesters.contracts.revisions", ["instructor.semesters.contracts.revisions.revision"])
    .config(function ($stateProvider) {
        $stateProvider.state("instructor.semesters.semester.contracts.revisions", {
            // abstract: true,
            url: "/{contractId:int}",
            views: {
                "": {
                    templateUrl: "instructor/semesters/contracts/revisions/revisions.tmpl.html",
                    controller: "InstructorRevisionsCtrl as instructorRevisionsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Revisions",
            },
            resolve: {
                contract: [
                    "$state",
                    "$stateParams",
                    "$q",
                    "UsersModel",
                    "Loader",
                    function ($state, $stateParams, $q, UsersModel, Loader) {
                        var deferred = $q.defer();

                        var contractId = $stateParams.contractId;

                        Loader.showLoading("Loading Contract");
                        UsersModel.getInstructorContract(contractId)
                            .then(
                                function (instructorContract) {
                                    deferred.resolve(instructorContract);
                                },
                                function () {
                                    deferred.reject();
                                    $state.go("instructor.semesters.semester.contracts");
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });

                        return deferred.promise;
                    },
                ],
            },
        });
    })
    .controller("InstructorRevisionsCtrl", [
        "contract",
        "$scope",
        "$state",
        "$stateParams",
        "$window",
        "$timeout",
        "Loader",
        function (contract, $scope, $state, $stateParams, $window, $timeout, Loader) {
            var instructorRevisionsCtrl = this;

            instructorRevisionsCtrl.revisionId = $stateParams.contractId;

            instructorRevisionsCtrl.contract = contract;

            instructorRevisionsCtrl.revisions = contract.revisions;

            instructorRevisionsCtrl.showRevision = function (revisionId) {
                Loader.showLoading("Loading Revision");
                $timeout(function () {
                    $state.go("instructor.semesters.semester.contracts.revisions.revision", {
                        revisionId: revisionId,
                    });
                    Loader.hideLoading();
                });
            };

            instructorRevisionsCtrl.showRevision(contract.revisions[0].id);

            instructorRevisionsCtrl.print = function () {
                $window.print();
            };

            $scope.$on("revisionIdUpdated", function (event, revisionId) {
                $timeout(function () {
                    instructorRevisionsCtrl.revisionId = revisionId;
                });
            });
        },
    ]);
