angular
    .module("admin.semesters.semester.proposals.proposal.approvals", ["app.factories.approvals"])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.proposals.proposal.approvals", {
            url: "/approvals",
            views: {
                "": {
                    templateUrl: "admin/semesters/proposals/approvals/approvals.tmpl.html",
                    controller: "AdminProposalApprovalsCtrl as proposalApprovalsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Approvals",
            },
        });
    })
    .controller("AdminProposalApprovalsCtrl", [
        "proposal",
        "$stateParams",
        "$filter",
        "ProposalsModel",
        "Loader",
        "ProposalApproversFactory",
        "ContractsFactory",
        function (proposal, $stateParams, $filter, ProposalsModel, Loader, ProposalApproversFactory, ContractsFactory) {
            var proposalApprovalsCtrl = this;

            var proposalId = $stateParams.proposalId;

            proposalApprovalsCtrl.proposal = proposal;

            proposalApprovalsCtrl.search = ContractsFactory.getSearch();
            proposalApprovalsCtrl.search.sort("user_last_name");

            proposalApprovalsCtrl.tableColStyle = {
                width: 100 / (proposal.approvers.length + 2) + "%",
            };

            proposalApprovalsCtrl.getContractStatus = function (contract) {
                var status =
                    contract.statuses && contract.statuses.length ? _.first(contract.statuses).status : contract.status;

                var reminders =
                    contract.revisions && contract.revisions.length
                        ? contract.revisions[0].reminders
                        : contract.reminders;

                if (status == "Accepted") {
                    status = '<span class="text-success"><i class="fa fa-check-circle"></i> ' + status + "</span>";
                } else if (status.indexOf("Sent") !== -1) {
                    if (reminders > 1) {
                        status = status + " " + reminders;
                    }
                    status = '<span><i class="fa fa-envelope"></i> ' + status + "</span>";
                }

                return status;
            };

            proposalApprovalsCtrl.getContractStatusesHTML = function (contract) {
                var html = "";

                var contractStatuses =
                    contract.statuses && contract.statuses.length ? contract.statuses : [contract.status];

                _.forEach(contractStatuses, function (status) {
                    html += status.status + ", " + $filter("date")(status.created_at, "M/d/yy, h:mm a") + "<br>";
                });

                return html;
            };

            proposalApprovalsCtrl.showContractReminders = function (contract) {
                var status =
                    contract.statuses && contract.statuses.length ? _.first(contract.statuses).status : contract.status;

                if (contract.is_canc || contract.lesson_status == "Cancelled") {
                    return false;
                } else if (status.indexOf("Sent") !== -1) {
                    return true;
                }

                return false;
            };

            proposalApprovalsCtrl.sendContractReminder = function (contract) {
                Loader.showLoading("Sending Reminder");
                ProposalsModel.sendProposalContractReminder(proposalId, contract.id)
                    .then(
                        function (updatedContract) {
                            _.assign(contract, updatedContract);
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            proposalApprovalsCtrl.hasApprovalStatuses = ProposalApproversFactory.hasApprovalStatuses;
            proposalApprovalsCtrl.hasApprovalMessage = ProposalApproversFactory.hasApprovalMessage;
            proposalApprovalsCtrl.getApprovalStatus = ProposalApproversFactory.getApprovalStatus;

            proposalApprovalsCtrl.showApproval = ProposalApproversFactory.showApproval;
            proposalApprovalsCtrl.showApprovalStatuses = ProposalApproversFactory.showApprovalStatuses;

            proposalApprovalsCtrl.getRowClass = ContractsFactory.getRowClass;
        },
    ]);
