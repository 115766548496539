angular.module("admin.semesters.semester.proposals.proposal.revision", []).controller("ProposalRevisionCtrl", [
    "proposal",
    "$uibModalInstance",
    "BASE_URL",
    "Loader",
    "TemplatesModel",
    function (proposal, $uibModalInstance, BASE_URL, Loader, TemplatesModel) {
        var proposalRevisionCtrl = this;

        proposalRevisionCtrl.proposal = proposal;
        proposalRevisionCtrl.BASE_URL = BASE_URL;

        Loader.showLoading("Loading Template");
        TemplatesModel.getTemplates()
            .then(
                function (templates) {
                    var emailTemplates = _.groupBy(templates, "type").email;
                    var template = _.find(emailTemplates, function (emailTemplate) {
                        return emailTemplate.name == "proposal_revision";
                    });
                    proposalRevisionCtrl.template = template;
                },
                function () {
                    $state.go("admin.semesters.semester.proposals");
                }
            )
            .finally(function () {
                Loader.hideLoading();
            });

        proposalRevisionCtrl.send = function () {
            $uibModalInstance.close();
        };

        proposalRevisionCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };
    },
]);
