angular
    .module("admin.semesters.semester.proposals.proposal.approvers.approver.contracts", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.proposals.proposal.approvers.approver.contracts", {
            url: "/contracts",
            views: {
                "": {
                    templateUrl: "admin/semesters/proposals/approvers/contracts/contracts.tmpl.html",
                    controller: "AdminSemesterProposalApproverContractsCtrl as contractsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Contracts",
            },
        });
    })
    .controller("AdminSemesterProposalApproverContractsCtrl", [
        "proposal",
        "$stateParams",
        "ProposalApproversFactory",
        "ContractsFactory",
        "ProposalsFactory",
        function (proposal, $stateParams, ProposalApproversFactory, ContractsFactory, ProposalsFactory) {
            var contractsCtrl = this;

            var proposalApproverId = $stateParams.proposalApproverId;

            var proposalApprover = _.find(proposal.approvers, function (proposalApprover) {
                return proposalApprover.id == proposalApproverId;
            });

            _.map(proposal.contracts, function (contract) {
                contract.has_approval = ProposalApproversFactory.hasApproval(proposalApprover, contract);
                contract.approval_status = ProposalApproversFactory.getApprovalStatus(proposalApprover, contract);
            });

            contractsCtrl.search = ContractsFactory.getSearch();
            contractsCtrl.search.sort("has_approval");

            contractsCtrl.proposal = proposal;
            contractsCtrl.proposalApprover = proposalApprover;

            contractsCtrl.hasApproval = ProposalApproversFactory.hasApproval;
            contractsCtrl.hasApprovalMessage = ProposalApproversFactory.hasApprovalMessage;
            contractsCtrl.getApprovalStatus = ProposalApproversFactory.getApprovalStatus;
            contractsCtrl.showApproval = ProposalApproversFactory.showApproval;
            contractsCtrl.showApprovalStatuses = ProposalApproversFactory.showApprovalStatuses;

            contractsCtrl.getRowClass = ContractsFactory.getRowClass;

            contractsCtrl.getStatusHTML = ContractsFactory.getStatusHTML;
        },
    ]);
