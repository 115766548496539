angular.module("app.models.enrollments", []).service("EnrollmentsModel", [
    "$rootScope",
    "$http",
    "$q",
    "API_URL",
    function ($rootScope, $http, $q, API_URL) {
        var model = this,
            enrollments = [],
            API = {
                getEnrollments: function (semesterId) {
                    return $http.get(API_URL + "semesters/" + semesterId + "/enrollments");
                },
                getEnrollment: function (enrollmentId) {
                    return $http.get(API_URL + "enrollments/" + enrollmentId);
                },
                createEnrollment: function (enrollment) {
                    return $http.post(API_URL + "semesters/" + enrollment.semester_id + "/enrollments", enrollment);
                },
                updateEnrollment: function (enrollment) {
                    return $http.patch(API_URL + "enrollments/" + enrollment.id, enrollment);
                },
                deleteEnrollment: function (enrollment) {
                    return $http.delete(API_URL + "enrollments" + enrollment.id);
                },
            };

        function extract(result) {
            return result.data;
        }

        function cacheEnrollments(result, semesterId) {
            enrollments[semesterId] = extract(result);

            return enrollments[semesterId];
        }

        model.fields = function (semester) {
            return [
                "department",
                "subject",
                "course_catalog",
                "course_title",

                "section_no",
                "section_mode",
                "section_component",
                "section_offer_no",
                "section_min_units",
                "section_max_units",

                "lesson_no",
                "lesson_status",
                "lesson_comb_sects_id",
                "lesson_session",

                "lesson_start_date",
                "lesson_end_date",

                "lesson_enrl_total",
                "lesson_enrl_cap",

                "stu_cr_hrs",

                "is_waitlisted",
                "waitlist_total",
            ];
        };

        model.fillable = function () {
            var fillable = ["exported_at"];

            return _.concat(model.fields(), fillable);
        };

        model.protected = function () {
            return ["created_at", "updated_at"];
        };

        model.labels = function () {
            return {
                college: "College",
                department: "Dept",
                subject: "Subject",
                course_catalog: "Catalog",
                course_title: "Course Title",
                course: "Course",

                section_no: "Section",
                section_mode: "Mode",
                section_offer_no: "Offer Nbr",
                section_component: "Component",
                section_min_units: "Min Units",
                section_max_units: "Max Units",

                lesson_no: "Class Nbr",
                lesson_status: "Class Status",
                lesson_campus: "Campus",
                lesson_comb_sects_id: "Comb Sects ID",
                lesson_session: "Session",

                lesson_start_date: "Start Date",
                lesson_end_date: "End Date",

                lesson_enrl_total: "Tot Enrl",
                lesson_enrl_cap: "Enrl Cap",

                stu_cr_hrs: "Stu Cr Hrs",

                is_waitlisted: "Wait List",
                waitlist_total: "# on Wait List",
            };
        };

        model.groups = function () {
            return {};
        };

        model.dates = function () {
            return ["lesson_start_date", "lesson_end_date"];
        };

        model.times = function () {
            return [];
        };

        model.numbers = function () {
            return [
                "course_catalog",

                "lesson_no",

                "section_no",
                "section_offer_no",
                "section_min_units",
                "section_max_units",

                "lesson_enrl_total",
                "lesson_enrl_cap",

                "stu_cr_hrs",

                "waitlist_total",
            ];
        };

        model.booleans = function () {
            return ["is_waitlisted"];
        };

        model.statuses = function () {
            return [];
        };

        model.getEnrollments = function (semesterId) {
            if (enrollments[semesterId]) {
                return $q.when(enrollments[semesterId]);
            } else {
                return API.getEnrollments(semesterId).then(function (result) {
                    return cacheEnrollments(result, semesterId);
                });
            }
        };

        model.getEnrollmentsBySemester = function (semesterId) {
            if (enrollments[semesterId]) {
                return $q.when(enrollments[semesterId]);
            } else {
                return API.getEnrollments(semesterId).then(function (result) {
                    return cacheEnrollments(result, semesterId);
                });
            }
        };

        model.getSemesterEnrollment = function (semesterId, enrollmentId) {
            var deferred = $q.defer();

            function findEnrollment() {
                return _.find(enrollments[semesterId], function (enrollment) {
                    return enrollment.id === enrollmentId;
                });
            }

            if (enrollments[semesterId]) {
                deferred.resolve(findEnrollment());
            } else {
                API.getEnrollment(enrollmentId).then(function (enrollment) {
                    deferred.resolve(extract(enrollment));
                });
            }

            return deferred.promise;
        };

        model.createEnrollment = function (enrollment) {
            var deferred = $q.defer();

            API.createEnrollment(enrollment).then(
                function (result) {
                    var enrollment = extract(result);
                    enrollments[enrollment.semester_id].push(enrollment);
                    deferred.resolve(enrollment);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.updateEnrollment = function (enrollment) {
            var deferred = $q.defer();

            API.updateEnrollment(enrollment)
                .then(function (result) {
                    deferred.resolve(extract(result));
                })
                .finally(function () {
                    deferred.resolve(enrollment);
                });

            return deferred.promise;
        };

        model.deleteEnrollment = function (enrollment) {
            var deferred = $q.defer();

            API.deleteEnrollment(enrollment)
                .success(function () {
                    _.remove(enrollments, function (_enrollment) {
                        return _enrollment.id === enrollment.id;
                    });
                })
                .finally(function () {
                    deferred.resolve();
                });

            return deferred.promise;
        };

        model.setCurrentEnrollment = function (enrollment) {
            currentEnrollment = enrollment;
        };

        model.getCurrentEnrollment = function () {
            return currentEnrollment;
        };

        model.updateEnrollments = function (_enrollments) {
            model.enrollments = _enrollments;
            $rootScope.$broadcast("enrollments.update");
        };

        model.clearCache = function () {
            enrollments = [];
        };
    },
]);
