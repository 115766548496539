angular
    .module("admin.templates.contracts", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.templates.contracts", {
            url: "/contracts",
            views: {
                "": {
                    templateUrl: "admin/templates/contracts/contracts.tmpl.html",
                    controller: "ContractTemplatesCtrl as contractTemplatesCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Contracts",
            },
        });
    })
    .controller("ContractTemplatesCtrl", [
        "templates",
        "$window",
        "SemestersModel",
        "InstructorsModel",
        "TemplatesModel",
        "Loader",
        "AlertFactory",
        "$timeout",
        function (
            templates,
            $window,
            SemestersModel,
            InstructorsModel,
            TemplatesModel,
            Loader,
            AlertFactory,
            $timeout
        ) {
            var contractTemplatesCtrl = this;
            contractTemplatesCtrl.isEditing = true;

            var contractTemplates = _.groupBy(templates, "type").contract;

            contractTemplatesCtrl.templateOptions = [
                {
                    name: null,
                    displayName: "Contract",
                    template: "contract",
                },
                {
                    name: "instructor_change",
                    displayName: "Change in Instructor",
                    template: "instructor_change",
                },
                {
                    name: "class_correction",
                    displayName: "Information Adjustment",
                    template: "class_correction",
                },
                {
                    name: "salary_correction",
                    displayName: "Salary Correction",
                    template: "salary_correction",
                },
                {
                    name: "canc",
                    displayName: "Cancelled Course",
                    template: "canc",
                },
                {
                    name: "de_adj",
                    displayName: "DE Adjustment",
                    template: "de_adj",
                },
                {
                    name: "prorated_salary",
                    displayName: "Prorated Salary",
                    template: "prorated_salary",
                },
                {
                    name: "salary_adj",
                    displayName: "Salary Adjustment",
                    template: "salary_adj",
                },
            ];

            contractTemplatesCtrl.templateOption = contractTemplatesCtrl.templateOptions[0];

            contractTemplatesCtrl.selectTemplate = function (templateOption) {
                var selectedTemplate = _.find(contractTemplates, function (contractTemplate) {
                    return contractTemplate.name == templateOption.name;
                });

                if (
                    !contractTemplatesCtrl.isViewingRevisions &&
                    contractTemplatesCtrl.selectedTemplate &&
                    contractTemplatesCtrl.selectedTemplate.id == selectedTemplate.id
                ) {
                    return;
                }

                contractTemplatesCtrl.selectedTemplate = angular.copy(selectedTemplate);

                contractTemplatesCtrl.revisions = _.filter(contractTemplates, {
                    name: contractTemplatesCtrl.selectedTemplate.name,
                });

                $timeout(function () {
                    contractTemplatesCtrl.contractTemplatesForm.$setPristine();
                });
            };

            contractTemplatesCtrl.updateTemplate = function (template) {
                Loader.showLoading("Updating Template");
                TemplatesModel.updateTemplate(template)
                    .then(
                        function (template) {
                            SemestersModel.clearCache();
                            InstructorsModel.clearCache();
                            contractTemplates = _.groupBy(templates, "type").contract;
                            contractTemplatesCtrl.selectTemplate(contractTemplatesCtrl.templateOption);
                            $window.scrollTo(0, 0);
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            contractTemplatesCtrl.selectTemplate(contractTemplatesCtrl.templateOption);

            contractTemplatesCtrl.edit = function () {
                contractTemplatesCtrl.isEditing = true;
                contractTemplatesCtrl.isViewing = false;
                contractTemplatesCtrl.isViewingRevisions = false;

                contractTemplatesCtrl.selectTemplate(contractTemplatesCtrl.templateOption);
            };

            contractTemplatesCtrl.view = function () {
                contractTemplatesCtrl.isEditing = false;
                contractTemplatesCtrl.isViewing = true;
                contractTemplatesCtrl.isViewingRevisions = false;

                contractTemplatesCtrl.selectTemplate(contractTemplatesCtrl.templateOption);
            };

            contractTemplatesCtrl.viewRevisions = function () {
                contractTemplatesCtrl.isEditing = false;
                contractTemplatesCtrl.isViewing = false;
                contractTemplatesCtrl.isViewingRevisions = true;

                contractTemplatesCtrl.selectTemplate(contractTemplatesCtrl.templateOption);
            };
        },
    ]);
