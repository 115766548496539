angular.module("admin.semesters.semester.proposals.delete", []).controller("DeleteProposalCtrl", [
    "proposal",
    "$uibModalInstance",
    function (proposal, $uibModalInstance) {
        var deleteProposalCtrl = this;

        deleteProposalCtrl.proposal = proposal;

        deleteProposalCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        deleteProposalCtrl.delete = function () {
            $uibModalInstance.close();
        };
    },
]);
