angular
    .module("admin.semesters.semester.proposals.proposal.contracts", [
        "admin.semesters.semester.proposals.proposal.contracts.edit",
        "admin.semesters.semester.proposals.proposal.contracts.remove",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.proposals.proposal.contracts", {
            url: "/contracts",
            views: {
                "": {
                    templateUrl: "admin/semesters/proposals/contracts/contracts.tmpl.html",
                    controller: "AdminProposalContractsCtrl as proposalContractsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Contracts",
            },
        });
    })
    .controller("AdminProposalContractsCtrl", [
        "semester",
        "proposal",
        "$stateParams",
        "$uibModal",
        "SemestersModel",
        "ProposalsModel",
        "Loader",
        "AlertFactory",
        "ProposalsFactory",
        "ContractsFactory",
        function (
            semester,
            proposal,
            $stateParams,
            $uibModal,
            SemestersModel,
            ProposalsModel,
            Loader,
            AlertFactory,
            ProposalsFactory,
            ContractsFactory
        ) {
            var proposalContractsCtrl = this;

            var semesterId = $stateParams.semesterId;
            var proposalId = $stateParams.proposalId;

            proposalContractsCtrl.proposal = proposal;

            proposalContractsCtrl.search = ContractsFactory.getSearch();
            proposalContractsCtrl.search.sort("user_last_name");

            proposalContractsCtrl.removeProposalContract = function (contract) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: "admin/semesters/proposals/contracts/remove/contract-remove.tmpl.html",
                    controller: "RemoveProposalContractCtrl as removeProposalContractCtrl",
                    resolve: {
                        contract: function () {
                            return contract;
                        },
                    },
                });

                modalInstance.result
                    .then(function () {
                        Loader.showLoading("Removing Contract");
                        ProposalsModel.removeProposalContracts(proposalId, { contracts: [contract.id] })
                            .then(
                                function (updatedProposal) {
                                    var index = _.findIndex(semester.proposals, function (semesterProposal) {
                                        return semesterProposal.id == proposal.id;
                                    });

                                    if (index >= 0) {
                                        _.remove(semester.proposals[index].contracts, function (proposalContract) {
                                            return proposalContract.id == contract.id;
                                        });
                                    }

                                    _.remove(proposal.contracts, function (proposalContract) {
                                        return proposalContract.id == contract.id;
                                    });

                                    _.merge(proposal, updatedProposal);

                                    SemestersModel.clearContractsCache(semesterId);
                                },
                                function (result) {
                                    if (result.errors) {
                                        AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                                    }
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });
                    })
                    .catch(function (res) {
                        if (["cancel", "backdrop click", "escape key press"].indexOf(res) === -1) throw res;
                    });
            };

            proposalContractsCtrl.hasApproved = function () {
                if (!proposalContractsCtrl.proposal) {
                    return false;
                }

                var statuses = _.map(proposalContractsCtrl.proposal.approvers, "status");

                if (_.includes(statuses, "Approved")) {
                    return true;
                }

                return false;
            };

            proposalContractsCtrl.exportProposalContracts = ProposalsFactory.exportProposalContracts;
            proposalContractsCtrl.getRowClass = ContractsFactory.getRowClass;
        },
    ]);
