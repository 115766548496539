angular.module("app.factories.courses", []).factory("CoursesFactory", [
    "$timeout",
    "$location",
    "CoursesModel",
    "LSFactory",
    "Loader",
    function ($timeout, $location, CoursesModel, LSFactory, Loader) {
        var coursesFactory = this;
        var ctrl = null;
        var params = $location.search();

        function updateQueryString(params) {
            LSFactory.set("coursesQuery", params);
            $location.search(params);
        }

        function updateParams() {
            params = {};

            if (ctrl.page) {
                params.page = parseInt(ctrl.page);
            }

            if (ctrl.sortBy) {
                params.sort_by = ctrl.sortBy;
                params.sort_order = ctrl.sortOrder;
            }

            if (ctrl.subject) {
                params.subject = ctrl.subject.key;
            }

            if (ctrl.catalog) {
                params.catalog = ctrl.catalog.key;
            }

            if (ctrl.section) {
                params.section = ctrl.section.key;
            }

            if (ctrl.employee) {
                params.employee = ctrl.employee.key;
            }

            updateQueryString(params);
        }

        function selectOption(selectOptions, optionKey) {
            var selectOption = _.find(selectOptions, function (option) {
                return option.key == params[optionKey];
            });

            ctrl[optionKey] = selectOption;
        }

        function processResult(result, selectKey, optionKey) {
            var selectOptions = _.map(result, function (item) {
                return {
                    key: item,
                    value: item,
                };
            });

            ctrl[selectKey] = selectOptions;

            selectOption(selectOptions, optionKey);
        }

        function getCourses() {
            Loader.showLoading("Loading Course Histories");
            CoursesModel.getCourses(params)
                .then(function (courses) {
                    ctrl.courses = courses;
                })
                .finally(function () {
                    Loader.hideLoading();
                });
        }

        function getCourse(contractId) {
            Loader.showLoading("Loading Course History");
            CoursesModel.getCourse(contractId, params)
                .then(function (courses) {
                    ctrl.courses = courses;
                })
                .finally(function () {
                    Loader.hideLoading();
                });
        }

        function getSubjects() {
            CoursesModel.getSubjects(params).then(function (result) {
                processResult(result, "subjects", "subject");
            });
        }

        function getCatalogs() {
            CoursesModel.getCatalogs(params).then(function (result) {
                processResult(result, "catalogs", "catalog");
            });
        }

        function getSections() {
            var newParams = _.assign({}, params);

            if (ctrl.contract) {
                _.merge(newParams, {
                    subject: ctrl.contract.subject,
                    catalog: ctrl.contract.course_catalog,
                });
            }

            CoursesModel.getSections(newParams).then(function (result) {
                processResult(result, "sections", "section");
            });
        }

        function getEmployees() {
            var newParams = _.assign({}, params);

            if (ctrl.contract) {
                _.merge(newParams, {
                    subject: ctrl.contract.subject,
                    catalog: ctrl.contract.course_catalog,
                });
            }

            return CoursesModel.getEmployees(newParams).then(
                function (result) {
                    ctrl.employees = _.map(result, function (employee) {
                        return {
                            key: employee.user_ums_id,
                            value:
                                "(" +
                                employee.user_ums_id +
                                ") " +
                                employee.user_last_name +
                                ", " +
                                employee.user_first_name,
                        };
                    });

                    selectOption(ctrl.employees, "employee");
                },
                function () {
                    return [];
                }
            );
        }

        function updateFields(field) {
            var fieldMappings = {
                all: [getSections, getEmployees],
                subject: [getCatalogs, getSections, getEmployees],
                catalog: [getSubjects, getSections, getEmployees],
                section: [getEmployees],
                employee: [getSections],
            };

            if (!ctrl.contract) {
                fieldMappings.all.push(getSubjects, getCatalogs);
                fieldMappings.section.push(getSubjects, getCatalogs);
                fieldMappings.employee.push(getSubjects, getCatalogs);
            }

            var functionsToCall = fieldMappings[field] || [];
            functionsToCall.forEach(function (func) {
                func();
            });
        }

        function search(field) {
            updateParams();

            if (ctrl.contract) {
                getCourse(ctrl.contract.parent_id);
            } else {
                getCourses();
            }

            updateFields(field);
        }

        function clear(field) {
            ctrl.page = 1;

            if (field) {
                ctrl[field] = null;
                search(field);
            } else {
                ctrl.subject = null;
                ctrl.catalog = null;
                ctrl.section = null;
                ctrl.employee = null;
                search("all");
            }
        }

        function sort(col) {
            ctrl.page = 1;

            if (ctrl.sortBy == col && ctrl.sortOrder == "asc") {
                ctrl.sortOrder = "desc";
            } else {
                ctrl.sortOrder = "asc";
            }

            ctrl.sortBy = col;

            search();
        }

        function initSearch(coursesCtrl) {
            ctrl = coursesCtrl;

            ctrl.page = parseInt(params.page);
            ctrl.sortBy = params.sort_by ? params.sort_by : "course";
            ctrl.sortOrder = params.sort_order ? params.sort_order : "asc";

            if (ctrl.contract) {
                getCourse(ctrl.contract.parent_id);
            } else {
                getCourses();
                getSubjects();
                getCatalogs();
            }

            getSections();
            getEmployees();

            ctrl.params = params;
        }

        coursesFactory.initSearch = initSearch;
        coursesFactory.search = search;
        coursesFactory.clear = clear;
        coursesFactory.sort = sort;

        return coursesFactory;
    },
]);
