angular
    .module("admin.semesters.semester.contracts.create", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.contracts.create", {
            url: "/create",
            views: {
                create: {
                    templateUrl: "admin/semesters/contracts/create/contract-create.tmpl.html",
                    controller: "CreateContractCtrl as createContractCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Create Contract",
            },
        });
    })
    .controller("CreateContractCtrl", [
        "$stateParams",
        "$scope",
        "SemestersModel",
        "Loader",
        "AlertFactory",
        function ($stateParams, $scope, SemestersModel, Loader, AlertFactory) {
            var createContractCtrl = this;
            var semesterId = $stateParams.semesterId;

            function createContract(contract) {
                Loader.showLoading("Crreating Contract");
                SemestersModel.createContract(semesterId, contract)
                    .then(
                        function (contract) {
                            $scope.$emit("contracts.create", contract);
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            }

            createContractCtrl.createContract = createContract;
        },
    ]);
