angular
    .module("admin.semesters.semester.enrollments", [
        "app.factories.enrollments",
        "admin.semesters.semester.enrollments.import",
        "admin.semesters.semester.enrollments.delete",
        "infinite-scroll",
        "angular.filter",
    ])
    .config([
        "$stateProvider",
        function ($stateProvider) {
            $stateProvider.state("admin.semesters.semester.enrollments", {
                url: "/enrollments",
                views: {
                    "": {
                        templateUrl: "admin/semesters/enrollments/enrollments.tmpl.html",
                        controller: "AdminSemesterEnrollmentsCtrl as enrollmentsCtrl",
                    },
                },
                ncyBreadcrumb: {
                    label: "Enrollments",
                },
            });
        },
    ])
    .controller("AdminSemesterEnrollmentsCtrl", [
        "$scope",
        "$stateParams",
        "$window",
        "$timeout",
        "$filter",
        "$uibModal",
        "SemestersModel",
        "Loader",
        "AlertFactory",
        "EnrollmentsFactory",
        function (
            $scope,
            $stateParams,
            $window,
            $timeout,
            $filter,
            $uibModal,
            SemestersModel,
            Loader,
            AlertFactory,
            EnrollmentsFactory
        ) {
            var enrollmentsCtrl = this;
            var semesterId = $stateParams.semesterId;

            enrollmentsCtrl.tab = "all";

            enrollmentsCtrl.setActiveTab = function (tab) {
                if (enrollmentsCtrl.tab == tab) {
                    return;
                }

                enrollmentsCtrl.tab = tab;

                enrollmentsCtrl.search.pageYOffset = null;
                enrollmentsCtrl.search.limitTo = 100;

                enrollmentsCtrl.pageYOffset = null;
                enrollmentsCtrl.limitTo = 100;

                $timeout(function () {
                    enrollmentsCtrl.fixHeadUpdate = tab;
                });
            };

            enrollmentsCtrl.sortType = "group";
            enrollmentsCtrl.limitTo = 100;
            enrollmentsCtrl.pageYOffset = null;
            enrollmentsCtrl.minLimitTo = null;

            enrollmentsCtrl.groupByProps = [
                {
                    label: "Section",
                    value: "section_no",
                },
                {
                    label: "Section (Range)",
                    value: "section_no_range",
                },
                {
                    label: "Dept",
                    value: "department",
                },
                {
                    label: "Session",
                    value: "lesson_session",
                },
                {
                    label: "Subject",
                    value: "subject",
                },
                {
                    label: "Course",
                    value: "course",
                },
            ];

            enrollmentsCtrl.groupBy = enrollmentsCtrl.groupByProps[0];

            function getEnrollments(exported_at) {
                enrollmentsCtrl.isLoading = true;

                enrollmentsCtrl.search = EnrollmentsFactory.getSearch();

                enrollmentsCtrl.exported_ats = [];
                enrollmentsCtrl.exported_at = null;

                SemestersModel.getEnrollments(semesterId, exported_at)
                    .then(function (result) {
                        var enrollments = result.data;
                        if (enrollments.length) {
                            _.forEach(enrollments, function (enrollment) {
                                enrollment.course = enrollment.subject + " " + parseInt(enrollment.course_catalog);
                                enrollment.stu_cr_hrs = enrollment.section_min_units * enrollment.lesson_enrl_total;
                            });

                            _.forEach(result.exported_ats, function (exported_at) {
                                enrollmentsCtrl.exported_ats.push({
                                    label: $filter("date")(new Date(exported_at), "M/d/yy"),
                                    value: exported_at,
                                });
                            });

                            var key = 0;
                            if (exported_at) {
                                key = _.findKey(result.exported_ats, function (_exported_at) {
                                    return exported_at == _exported_at;
                                });
                            }

                            enrollmentsCtrl.exported_at = enrollmentsCtrl.exported_ats[key].value;
                            enrollmentsCtrl.enrollments = enrollments;

                            enrollmentsCtrl.search.limitTo = 100;
                            enrollmentsCtrl.limitTo = 100;

                            enrollmentsCtrl.filterEnrollmentsByExportedAt();
                            enrollmentsCtrl.filterEnrollmentsByGroup();
                        }
                    })
                    .finally(function () {
                        $timeout(function () {
                            enrollmentsCtrl.isLoading = false;
                        });
                    });
            }

            getEnrollments();

            // Need for import
            $scope.$on("enrollments.update", function (event, exported_at) {
                getEnrollments(exported_at);
            });

            enrollmentsCtrl.exportEnrls = function (data) {
                Loader.showLoading("Exporting Enrollments");
                SemestersModel.getSemester(semesterId)
                    .then(function (semester) {
                        var term = semester.term.name.substring(0, 2).toUpperCase();
                        var year = String(semester.year);
                        year = year.substring(2, 4);
                        var exportedAt = enrollmentsCtrl.exported_at;
                        exportedAt = $filter("date")(exportedAt, "M-d-yyyy");
                        var date = new Date();
                        date = $filter("date")(date, "M-d-yyyy");
                        var fileName = term + year + "_Enrl_All_" + exportedAt + "_" + date + ".xlsx";

                        alasql.fn.datetime = function (date) {
                            if (date) {
                                date = new Date(date);
                                return $filter("date")(date, "M/d/yyyy");
                            }
                        };

                        alasql.fn.boolean = function (value) {
                            if (value) {
                                return "Y";
                            }
                            return "N";
                        };

                        alasql.fn.get = function (value) {
                            if (!value && value !== 0) {
                                return "";
                            }
                            return value;
                        };

                        alasql
                            .promise(
                                "SELECT " +
                                    "get(department) AS Dept, " +
                                    "get(course) AS Course, " +
                                    "get(course_title) AS [Course Title], " +
                                    "get(section_no) AS Section, " +
                                    "get(section_mode) AS Mode, " +
                                    "get(section_component) AS Component, " +
                                    "get(section_offer_no) as [Offer Nbr], " +
                                    "get(section_min_units) AS [Min Units], " +
                                    "get(section_max_units) AS [Max Units], " +
                                    "get(lesson_no) as [Class Nbr], " +
                                    "get(lesson_status) AS [Class Status], " +
                                    "get(lesson_comb_sects_id) AS [Comb Sects ID], " +
                                    "get(lesson_session) AS Session, " +
                                    "datetime(lesson_start_date) AS [Start Date], " +
                                    "datetime(lesson_end_date) AS [End Date], " +
                                    "get(lesson_enrl_total) AS [Tot Enrl], " +
                                    "get(lesson_enrl_cap) AS [Enrl Cap], " +
                                    "get(stu_cr_hrs) AS [Credit Hours], " +
                                    "boolean(is_waitlisted) AS [Wait List], " +
                                    "get(waitlist_total) AS [# on Wait List] " +
                                    'INTO XLSX("' +
                                    fileName +
                                    '", {headers:true}) FROM ?',
                                [data]
                            )
                            .then(function () {
                                Loader.hideLoading();
                            })
                            .catch(function (err) {
                                AlertFactory.show("Export Error", "danger");
                                console.log(err);
                                Loader.hideLoading();
                            });
                    })
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            enrollmentsCtrl.exportEnrlsByGroup = function (data) {
                Loader.showLoading("Exporting Enrollments");
                SemestersModel.getSemester(semesterId)
                    .then(function (semester) {
                        var term = semester.term.name.substring(0, 2).toUpperCase();
                        var year = String(semester.year);
                        year = year.substring(2, 4);
                        var exportedAt = enrollmentsCtrl.exported_at;
                        exportedAt = $filter("date")(exportedAt, "M-d-yy");
                        var date = new Date();
                        date = $filter("date")(date, "M-d-yy");
                        var groupBy = enrollmentsCtrl.getGroupByLabel();
                        var fileName = term + year + "_Enrl_" + groupBy + "_" + exportedAt + "_" + date + ".xlsx";

                        var groupLabel = enrollmentsCtrl.getGroupByLabel();

                        alasql.fn.datetime = function (date) {
                            if (date) {
                                date = new Date(date);
                                return $filter("date")(date, "M/d/yyyy");
                            }
                        };

                        data = alasql(
                            "SELECT " +
                                "[group] AS [" +
                                groupLabel +
                                "], " +
                                "lesson_enrl_total_sum AS [Sum of Tot Enrl], " +
                                "stu_cr_hrs_sum AS [Sum of Credit Hours], " +
                                "group_count AS [Count of " +
                                groupLabel +
                                "] " +
                                "FROM ?",
                            [data]
                        );

                        var summary = {};
                        summary[groupLabel] = "Total";
                        summary["Sum of Tot Enrl"] = _(enrollmentsCtrl.filteredEnrollmentsByGroup).sumBy(
                            "lesson_enrl_total_sum"
                        );
                        summary["Sum of Credit Hours"] = _(enrollmentsCtrl.filteredEnrollmentsByGroup).sumBy(
                            "stu_cr_hrs_sum"
                        );
                        summary["Count of " + groupLabel] = _(enrollmentsCtrl.filteredEnrollmentsByGroup).sumBy(
                            "group_count"
                        );

                        data.unshift(summary);

                        alasql
                            .promise('SELECT * INTO XLSX("' + fileName + '", {headers:true}) FROM ?', [data])
                            .then(function () {
                                Loader.hideLoading();
                            })
                            .catch(function (err) {
                                AlertFactory.show("Export Error", "danger");
                                console.log(err);
                                Loader.hideLoading();
                            });
                    })
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            enrollmentsCtrl.resetLimitTo = function () {
                if (enrollmentsCtrl.limitTo > enrollmentsCtrl.minLimitTo) {
                    $timeout(function () {
                        enrollmentsCtrl.limitTo = enrollmentsCtrl.minLimitTo || enrollmentsCtrl.limitTo;
                    });
                }
            };

            enrollmentsCtrl.setLimitTo = function (totalItems) {
                if (enrollmentsCtrl.limitTo < totalItems) {
                    if (!enrollmentsCtrl.pageYOffset) {
                        enrollmentsCtrl.pageYOffset = $window.pageYOffset;
                    }

                    if (enrollmentsCtrl.pageYOffset && !enrollmentsCtrl.minLimitTo) {
                        enrollmentsCtrl.minLimitTo = enrollmentsCtrl.limitTo;
                    }

                    enrollmentsCtrl.limitTo += 25;
                }
            };

            enrollmentsCtrl.filterEnrollmentsByGroup = function () {
                enrollmentsCtrl.limitTo = 25;

                if (enrollmentsCtrl.enrollments) {
                    var groupBy = enrollmentsCtrl.getGroupByValue();

                    if (enrollmentsCtrl.prevEnrollments) {
                        var index = _.findIndex(enrollmentsCtrl.exported_ats, function (exported_at) {
                            return enrollmentsCtrl.exported_at == exported_at.value;
                        });

                        var prev_exported_at = enrollmentsCtrl.exported_ats[index];

                        if (enrollmentsCtrl.exported_ats[index + 1]) {
                            prev_exported_at = enrollmentsCtrl.exported_ats[index + 1];
                        }

                        enrollmentsCtrl.prevEnrollmentsByGroup = _(enrollmentsCtrl.prevEnrollments)
                            .filter({ exported_at: prev_exported_at.value })
                            .groupBy(groupBy)
                            .map(function (objs, key) {
                                return {
                                    group: key,
                                    group_count: objs.length,
                                    lesson_enrl_total_sum: _.sumBy(objs, "lesson_enrl_total"),
                                    stu_cr_hrs_sum: _.sumBy(objs, "stu_cr_hrs"),
                                };
                            })
                            .value();
                    }

                    enrollmentsCtrl.enrollmentsByGroup = _(enrollmentsCtrl.enrollments)
                        .filter({ exported_at: enrollmentsCtrl.exported_at })
                        .groupBy(groupBy)
                        .map(function (objs, key) {
                            return {
                                group: key,
                                group_count: objs.length,
                                lesson_enrl_total_sum: _.sumBy(objs, "lesson_enrl_total"),
                                stu_cr_hrs_sum: _.sumBy(objs, "stu_cr_hrs"),
                            };
                        })
                        .value();
                }
            };

            enrollmentsCtrl.getGroupByLabel = function () {
                if (enrollmentsCtrl.groupBy.value === "section_no_range") {
                    return "Section";
                }
                return enrollmentsCtrl.groupBy.label;
            };

            enrollmentsCtrl.getGroupByValue = function () {
                if (enrollmentsCtrl.groupBy.value === "section_no_range") {
                    return "section_no";
                }
                return enrollmentsCtrl.groupBy.value;
            };

            enrollmentsCtrl.filterEnrollmentsByExportedAt = function () {
                if (enrollmentsCtrl.enrollments) {
                    var index = _.findIndex(enrollmentsCtrl.exported_ats, function (exported_at) {
                        return enrollmentsCtrl.exported_at == exported_at.value;
                    });

                    var exported_at = enrollmentsCtrl.exported_ats[index];

                    if (enrollmentsCtrl.exported_ats[index + 1]) {
                        exported_at = enrollmentsCtrl.exported_ats[index + 1];
                    }

                    enrollmentsCtrl.prevEnrollments = _.filter(enrollmentsCtrl.enrollments, {
                        exported_at: exported_at.value,
                    });
                }
            };

            enrollmentsCtrl.deleteEnrollments = function () {
                var exported_at = enrollmentsCtrl.exported_at;

                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: "admin/semesters/enrollments/delete/enrollments-delete.tmpl.html",
                    controller: "DeleteEnrollmentsCtrl as deleteEnrollmentsCtrl",
                    resolve: {
                        exported_at: function () {
                            return exported_at;
                        },
                        status: function () {
                            return status;
                        },
                    },
                });

                modalInstance.result
                    .then(function () {
                        Loader.showLoading("Deleting Enrollments");
                        SemestersModel.deleteEnrollments(semesterId, exported_at).then(function () {
                            getEnrollments();
                            Loader.hideLoading();
                        });
                    })
                    .catch(function (res) {
                        if (["cancel", "backdrop click", "escape key press"].indexOf(res) === -1) throw res;
                    });
            };

            enrollmentsCtrl.getEnrlTotalSum = function (enrollmentByGroup) {
                var prevEnrollmentByGroup = _.find(
                    enrollmentsCtrl.prevEnrollmentsByGroup,
                    function (prevEnrollmentByGroup) {
                        return prevEnrollmentByGroup.group === enrollmentByGroup.group;
                    }
                );

                if (!prevEnrollmentByGroup) {
                    return enrollmentByGroup.lesson_enrl_total_sum;
                }

                var operator = ' <span class="text-success">(+';
                var diff = enrollmentByGroup.lesson_enrl_total_sum - prevEnrollmentByGroup.lesson_enrl_total_sum;
                if (diff === 0) {
                    return enrollmentByGroup.lesson_enrl_total_sum;
                }
                if (diff < 0) {
                    operator = ' <span class="text-danger">(';
                }

                return enrollmentByGroup.lesson_enrl_total_sum + operator + diff + ")</span>";
            };

            enrollmentsCtrl.getEnrlTotal = function (enrollment) {
                var prevEnrollment = _.find(enrollmentsCtrl.prevEnrollments, function (prevEnrollment) {
                    return prevEnrollment.lesson_no === enrollment.lesson_no;
                });

                if (!prevEnrollment) {
                    return enrollment.lesson_enrl_total;
                }

                var operator = ' <span class="text-success">(+';
                var diff = enrollment.lesson_enrl_total - prevEnrollment.lesson_enrl_total;
                if (diff === 0) {
                    return enrollment.lesson_enrl_total;
                }
                if (diff < 0) {
                    operator = ' <span class="text-danger">(';
                }

                return enrollment.lesson_enrl_total + operator + diff + ")</span>";
            };

            enrollmentsCtrl.sort = function (sortType) {
                enrollmentsCtrl.limitTo = enrollmentsCtrl.minLimitTo;

                enrollmentsCtrl.sortReverse = !enrollmentsCtrl.sortReverse;

                enrollmentsCtrl.sortType = sortType;

                if (sortType === "") enrollmentsCtrl.orderBy = "group";
            };

            enrollmentsCtrl.searchGroupBy = function () {
                var filter = {};

                if (enrollmentsCtrl.groupBy && enrollmentsCtrl.groupBy.value == "section_no_range") {
                    return function (enrollment) {
                        if (enrollmentsCtrl.searchGT && enrollmentsCtrl.searchLT) {
                            return (
                                parseInt(enrollment.group) >= parseInt(enrollmentsCtrl.searchGT) &&
                                parseInt(enrollment.group) <= parseInt(enrollmentsCtrl.searchLT)
                            );
                        } else if (enrollmentsCtrl.searchGT) {
                            return parseInt(enrollment.group) >= parseInt(enrollmentsCtrl.searchGT);
                        } else if (enrollmentsCtrl.searchLT) {
                            return parseInt(enrollment.group) <= parseInt(enrollmentsCtrl.searchLT);
                        }
                    };
                }

                if (enrollmentsCtrl.groupBySearch && enrollmentsCtrl.groupBy && enrollmentsCtrl.groupBy.value) {
                    filter.group = enrollmentsCtrl.groupBySearch;
                }

                return filter;
            };

            enrollmentsCtrl.getEnrollments = getEnrollments;
        },
    ]);
