angular
    .module("admin.approvers.create", ["app.models.terms"])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.approvers.create", {
            url: "/create",
            views: {
                create: {
                    templateUrl: "admin/approvers/create/approver-create.tmpl.html",
                    controller: "CreateApproverCtrl as createApproverCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Create Approver",
            },
        });
    })
    .controller("CreateApproverCtrl", [
        "$state",
        "$timeout",
        "ApproversModel",
        "Loader",
        "AlertFactory",
        function ($state, $timeout, ApproversModel, Loader, AlertFactory) {
            var createApproverCtrl = this;

            Loader.showLoading("Loading Roles");
            ApproversModel.getRoles().then(function (roles) {
                createApproverCtrl.roles = roles;
                Loader.hideLoading();
            });

            function returnToApprovers() {
                $state.go("admin.approvers");
            }

            function cancelCreating() {
                returnToApprovers();
            }

            function createApprover(approver) {
                Loader.showLoading("Creating Approver");
                ApproversModel.createApprover(approver)
                    .then(
                        function (approver) {
                            $state.go("admin.approvers.approver", { approverId: approver.user_id });
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_(result.errors).values().flatten().value().join("\r\n"), "danger");

                                $timeout(function () {
                                    _.forOwn(result.errors, function (value, key) {
                                        if (createApproverCtrl.createApproverForm[key]) {
                                            createApproverCtrl.createApproverForm[key].$invalid = true;
                                        }
                                    });
                                });
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            }

            function resetForm() {
                createApproverCtrl.newApprover = {
                    ums_id: "",
                    first_name: "",
                    last_name: "",
                    email: "",
                    role_id: "",
                    college: "",
                    department: "",
                };
            }

            createApproverCtrl.cancelCreating = cancelCreating;
            createApproverCtrl.createApprover = createApprover;

            resetForm();
        },
    ]);
