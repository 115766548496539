angular
    .module("admin.instructors.instructor.contracts.contract.courses", ["app.models.courses"])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.instructors.instructor.contracts.contract.courses", {
            url: "/courses",
            views: {
                "": {
                    templateUrl: "admin/instructors/instructor/contracts/contract/courses/courses.tmpl.html",
                    controller: "AdminInstructorContractCoursesCtrl as coursesCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Course History",
            },
            cache: false,
        });
    })
    .controller("AdminInstructorContractCoursesCtrl", [
        "contract",
        "$state",
        "CoursesFactory",
        "ContractsFactory",
        "NotesFactory",
        function (contract, $state, CoursesFactory, ContractsFactory, NotesFactory) {
            var coursesCtrl = this;

            coursesCtrl.contract = contract;

            coursesCtrl.showContract = function (contract) {
                $state.go("admin.instructors.instructor.contracts.contract", {
                    semesterId: contract.semester_id,
                    contractId: contract.parent_id,
                });
            };

            CoursesFactory.initSearch(coursesCtrl);
            coursesCtrl.search = CoursesFactory.search;
            coursesCtrl.clear = CoursesFactory.clear;
            coursesCtrl.sort = CoursesFactory.sort;
            coursesCtrl.getRowClass = ContractsFactory.getRowClass;
            coursesCtrl.showNotes = NotesFactory.showNotes;

            coursesCtrl.getStatusPopoverHTML = ContractsFactory.getStatusPopoverHTML;
            coursesCtrl.getStatusHTML = ContractsFactory.getStatusHTML;
        },
    ]);
