angular.module("app.models.instructors", []).service("InstructorsModel", [
    "$http",
    "$q",
    "API_URL",
    function ($http, $q, API_URL) {
        var model = this,
            instructorsMeta,
            instructors,
            API = {
                /**
                 * Instructors
                 */
                getInstructors: function () {
                    return $http.get(API_URL + "instructors");
                },
                getInstructor: function (instructorId) {
                    return $http.get(API_URL + "instructors/" + instructorId);
                },
                getInstructorByUmsId: function (umsId) {
                    return $http.get(API_URL + "instructors/ums/" + umsId);
                },
                createInstructor: function (instructor) {
                    return $http.post(API_URL + "instructors", instructor);
                },
                updateInstructor: function (instructor) {
                    return $http.patch(API_URL + "instructors/" + instructor.user_id, instructor);
                },
                deleteInstructor: function (instructor) {
                    return $http.delete(API_URL + "instructors/" + instructor.user_id);
                },
                importInstructors: function (instructors) {
                    return $http.post(API_URL + "instructors/import", instructors, {
                        transformRequest: angular.identity,
                        headers: { "Content-Type": undefined },
                    });
                },
                replaceInstructors: function (data) {
                    return $http.patch(API_URL + "instructors/replace", data);
                },

                /**
                 * Instructors Meta
                 */

                getInstructorsMeta: function () {
                    return $http.get(API_URL + "instructors/meta");
                },
                createInstructorsMeta: function (meta) {
                    return $http.post(API_URL + "instructors/meta", meta);
                },
                updateInstructorsMeta: function (meta) {
                    return $http.patch(API_URL + "instructors/meta/" + meta.id, meta);
                },
                deleteInstructorsMeta: function (meta) {
                    return $http.delete(API_URL + "instructors/meta/" + meta.id);
                },

                /**
                 * Instructor Audits
                 */

                getAudits: function (instructorId, params) {
                    return $http.get(API_URL + "instructors/" + instructorId + "/audits", { params: params });
                },

                /*
                 * Innstructor Contracts
                 */

                getContract: function (instructorId, contractId) {
                    return $http.get(API_URL + "instructors/" + instructorId + "/contracts/" + contractId);
                },

                getContracts: function (instructorId) {
                    return $http.get(API_URL + "instructors/" + instructorId + "/contracts");
                },

                /**
                 * Instructor Notes
                 */
                createInstructorsNote: function (data) {
                    return $http.post(API_URL + "instructors/notes", data);
                },
                createInstructorNote: function (instructorId, note) {
                    return $http.post(API_URL + "instructors/" + instructorId + "/notes", note);
                },
                deleteInstructorNote: function (instructorId, instructorNoteId) {
                    return $http.delete(API_URL + "instructors/" + instructorId + "/notes/" + instructorNoteId);
                },
            };

        model.predefinedFields = function () {
            return ["college", "department", "unit", "status", "position_no", "rank", "job_code", "faculty_type"];
        };

        model.fields = function () {
            return [
                "user_id",
                "address",
                "city",
                "state",
                "zip",
                "rank",
                "college",
                "department",
                "unit",
                "status",
                "last_review_date",
                "position_no",
                "credit_hr_rate",
                "patfa_service_credits",
                "months_in_work_year",
                "job_code",
                "faculty_type",
                "is_online_faculty",
                "is_gold_faculty",
                "is_de_adj_eligible",
                "is_de_adj_grandfathered",
                "has_fhc_fee",
                "created_at",
                "updated_at",
            ];
        };

        model.fillable = function () {
            var fillable = ["notes_content"];

            return _.concat(model.fields(), fillable);
        };

        model.protected = function () {
            return ["notes_content", "created_at", "updated_at"];
        };

        model.labels = function () {
            return {
                user_id: "User ID",
                address: "Address",
                city: "City",
                state: "State",
                zip: "Zip",
                rank: "Rank",
                college: "College",
                department: "Dept",
                unit: "Unit",
                status: "Status",
                last_review_date: "Last Review Date",
                position_no: "Position No.",
                credit_hr_rate: "Credit Hour Rate",
                patfa_service_credits: "PATFA Service Credits",
                months_in_work_year: "Months in Work Year",
                job_code: "Job Code",
                faculty_type: "Faculty Type",
                is_online_faculty: "Online Faculty?",
                is_gold_faculty: "GOLD Faculty?",
                is_de_adj_eligible: "DE Adj Eligible?",
                is_de_adj_grandfathered: "DE Adj Grandfathered?",
                has_fhc_fee: "FHC Fee?",
                notes_content: "Notes",
                created_at: "Created",
                updated_at: "Updated",
            };
        };

        model.groups = function () {
            return {
                "user.ums_id": "User",
                "user.first_name": "User",
                "user.last_name": "User",
                "user.email": "User",

                user_id: "Instructor",
                address: "Instructor",
                city: "Instructor",
                state: "Instructor",
                zip: "Instructor",
                rank: "Instructor",
                college: "Instructor",
                department: "Instructor",
                unit: "Instructor",
                status: "Instructor",
                last_review_date: "Instructor",
                position_no: "Instructor",
                credit_hr_rate: "Instructor",
                patfa_service_credits: "Instructor",
                months_in_work_year: "Instructor",
                job_code: "Instructor",
                faculty_type: "Instructor",
                is_online_faculty: "Instructor",
                is_gold_faculty: "Instructor",
                is_de_adj_eligible: "Instructor",
                is_de_adj_grandfathered: "Instructor",
                has_fhc_fee: "Instructor",
                notes_content: "Instructor",

                created_at: "Confirmation",
                updated_at: "Confirmation",
            };
        };

        model.dates = function () {
            return ["last_review_date", "created_at", "updated_at"];
        };

        model.times = function () {
            return [];
        };

        model.numbers = function () {
            return ["user.ums_id", "credit_hr_rate", "patfa_service_credits", "months_in_work_year"];
        };

        model.decimals = function () {
            return ["credit_hr_rate"];
        };

        model.booleans = function () {
            return [
                "is_online_faculty",
                "is_gold_faculty",
                "is_de_adj_eligible",
                "is_de_adj_grandfathered",
                "has_fhc_fee",
            ];
        };

        model.statuses = function () {
            return [];
        };

        function extract(result) {
            return result.data;
        }

        function cacheInstructors(result) {
            instructors = extract(result);

            return instructors;
        }

        function cacheContracts(instructor, result) {
            instructor.contracts = extract(result);

            return instructor.contracts;
        }

        model.clearCache = function () {
            instructors = null;
        };

        model.getInstructors = function () {
            if (instructors) {
                return $q.when(instructors);
            } else {
                instructors = API.getInstructors().then(cacheInstructors);
                return instructors;
            }
        };

        model.getInstructor = function (instructorId) {
            var deferred = $q.defer();

            API.getInstructor(instructorId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.getInstructorByUmsId = function (umsId) {
            var deferred = $q.defer();

            API.getInstructorByUmsId(umsId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.createInstructor = function (instructor) {
            var deferred = $q.defer();

            API.createInstructor(instructor).then(
                function (result) {
                    instructor = extract(result);
                    instructors.push(instructor);
                    deferred.resolve(instructor);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.updateInstructor = function (instructor) {
            var deferred = $q.defer();

            API.updateInstructor(instructor).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function () {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteInstructor = function (instructor) {
            var deferred = $q.defer();

            API.deleteInstructor(instructor).then(
                function (result) {
                    _.remove(instructors, function (_instructor) {
                        return _instructor.user_id === instructor.user_id;
                    });
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.importInstructors = function (updatedInstructors) {
            var deferred = $q.defer();

            API.importInstructors(updatedInstructors).then(
                function (result) {
                    var data = extract(result);

                    _.forEach(data.instructors, function (updatedInstructor) {
                        var instructor = _.find(instructors, function (instructor) {
                            return instructor.user_id == updatedInstructor.user_id;
                        });

                        if (instructor) {
                            _.merge(instructor, updatedInstructor);
                        } else {
                            instructors.push(updatedInstructor);
                        }
                    });

                    deferred.resolve(data);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.replaceInstructors = function (filteredInstructors, replacement) {
            var deferred = $q.defer();

            var data = replacement;
            data.instructors = filteredInstructors;

            API.replaceInstructors(data).then(
                function (result) {
                    var instructorIds = extract(result);
                    if (instructorIds) {
                        var instructor;

                        _.forEach(instructorIds, function (instructorId) {
                            instructor = _.find(instructors, function (instructor) {
                                return instructor.user_id == instructorId;
                            });

                            if (instructor) {
                                _.assign(instructor, replacement);
                                instructor.updated_at = moment().format();
                            }
                        });
                    }
                    deferred.resolve(instructorIds);
                },
                function (result) {
                    deferred.resolve(extract(result));
                }
            );

            return deferred.promise;
        };

        /**
         * Instructor Audits
         */

        model.getAudits = function (instructorId, params) {
            var deferred = $q.defer();

            API.getAudits(instructorId, params).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /**
         * Instructor Contracts
         */

        model.getContracts = function (instructorId) {
            var deferred = $q.defer();

            model.getInstructor(instructorId).then(
                function (instructor) {
                    if (instructor.contracts) {
                        $q.when(instructor.contracts).then(function () {
                            deferred.resolve(instructor.contracts);
                        });
                    } else {
                        instructor.contracts = API.getContracts(instructorId).then(function (result) {
                            return cacheContracts(instructor, result);
                        });
                        deferred.resolve(instructor.contracts);
                    }
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        };

        model.getContract = function (instructorId, contractId) {
            var deferred = $q.defer();

            API.getContract(instructorId, contractId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /**
         * Instructors Meta
         */

        function cacheInstructorsMeta(result) {
            instructorsMeta = extract(result);

            return instructorsMeta;
        }

        model.createInstructorsMeta = function (meta) {
            var deferred = $q.defer();

            API.createInstructorsMeta(meta).then(
                function (result) {
                    meta = extract(result);
                    instructorsMeta.push(meta);
                    deferred.resolve(meta);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.getInstructorsMeta = function () {
            if (instructorsMeta) {
                return $q.when(instructorsMeta);
            } else {
                instructorsMeta = API.getInstructorsMeta().then(function (result) {
                    return cacheInstructorsMeta(result);
                });
                return instructorsMeta;
            }
        };

        model.updateInstructorsMeta = function (meta) {
            var deferred = $q.defer();

            API.updateInstructorsMeta(meta).then(
                function (result) {
                    meta = extract(result);
                    var instructorMeta = _.find(instructorsMeta, function (instructorMeta) {
                        return instructorMeta.id == meta.id;
                    });
                    if (instructorMeta) {
                        _.assign(instructorMeta, meta);
                    }
                    deferred.resolve(meta);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteInstructorsMeta = function (meta) {
            var deferred = $q.defer();

            API.deleteInstructorsMeta(meta).then(
                function (result) {
                    _.remove(instructorsMeta, function (_meta) {
                        return _meta.id === meta.id;
                    });
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /*
         * Instructor Notes
         */

        model.createInstructorsNote = function (instructors, newNote) {
            var deferred = $q.defer();

            var data = newNote;
            data.instructors = instructors;

            API.createInstructorsNote(data).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.createInstructorNote = function (instructorId, note) {
            var deferred = $q.defer();

            API.createInstructorNote(instructorId, note).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteInstructorNote = function (instructorId, instructorNoteId) {
            var deferred = $q.defer();

            API.deleteInstructorNote(instructorId, instructorNoteId).then(
                function () {
                    deferred.resolve();
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };
    },
]);
