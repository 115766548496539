angular
    .module("admin.templates.emails", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.templates.emails", {
            url: "/emails",
            views: {
                "": {
                    templateUrl: "admin/templates/emails/emails.tmpl.html",
                    controller: "EmailTemplatesCtrl as emailTemplatesCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Emails",
            },
        });
    })
    .controller("EmailTemplatesCtrl", [
        "templates",
        "$window",
        "TemplatesModel",
        "Loader",
        "AlertFactory",
        "$timeout",
        function (templates, $window, TemplatesModel, Loader, AlertFactory, $timeout) {
            var emailTemplatesCtrl = this;
            emailTemplatesCtrl.isEditing = true;

            var emailTemplates = _.groupBy(templates, "type").email;

            emailTemplatesCtrl.templateOptions = [
                {
                    name: "contract",
                    displayName: "Contract",
                    template: "contract",
                    group: "Contract",
                },
                {
                    name: "instructor_change",
                    displayName: "Change in Instructor",
                    template: "instructor_change",
                    group: "Contract",
                },
                {
                    name: "class_correction",
                    displayName: "Information Adjustment",
                    template: "class_correction",
                    group: "Contract",
                },
                {
                    name: "salary_correction",
                    displayName: "Salary Correction",
                    template: "salary_correction",
                    group: "Contract",
                },
                {
                    name: "canc",
                    displayName: "Cancelled Course",
                    template: "canc",
                    group: "Contract",
                },
                {
                    name: "de_adj",
                    displayName: "DE Adjustment",
                    template: "de_adj",
                    group: "Contract",
                },
                {
                    name: "prorated_salary",
                    displayName: "Prorated Salary",
                    template: "prorated_salary",
                    group: "Contract",
                },
                {
                    name: "salary_adj",
                    displayName: "Salary Adjustment",
                    template: "salary_adj",
                    group: "Contract",
                },
                {
                    name: "proposal",
                    displayName: "Proposal",
                    template: "proposal",
                    group: "Proposal",
                },
                {
                    name: "proposal_revision",
                    displayName: "Proposal Revision",
                    template: "proposal_revision",
                    group: "Proposal",
                },
                {
                    name: "warning_alert",
                    displayName: "Warning Alert",
                    template: "warning_alert",
                    group: "Warning",
                },
                {
                    name: "warning",
                    displayName: "Warning",
                    template: "warning",
                    group: "Warning",
                },
                {
                    name: "warning_decision",
                    displayName: "Warning Decision",
                    template: "warning_decision",
                    group: "Warning",
                },
            ];

            emailTemplatesCtrl.templateOption = emailTemplatesCtrl.templateOptions[0];

            emailTemplatesCtrl.selectTemplate = function (templateOption) {
                var selectedTemplate = _.find(emailTemplates, function (emailTemplate) {
                    return emailTemplate.name == templateOption.name;
                });

                if (
                    !emailTemplatesCtrl.isViewingRevisions &&
                    emailTemplatesCtrl.selectedTemplate &&
                    emailTemplatesCtrl.selectedTemplate.id == selectedTemplate.id
                ) {
                    return;
                }

                emailTemplatesCtrl.selectedTemplate = angular.copy(selectedTemplate);

                emailTemplatesCtrl.revisions = _.filter(emailTemplates, {
                    name: emailTemplatesCtrl.selectedTemplate.name,
                });

                $timeout(function () {
                    emailTemplatesCtrl.emailTemplatesForm.$setPristine();
                });
            };

            emailTemplatesCtrl.updateTemplate = function (template) {
                Loader.showLoading("Updating Template");
                TemplatesModel.updateTemplate(template)
                    .then(
                        function (templates) {
                            emailTemplates = _.groupBy(templates, "type").email;
                            emailTemplatesCtrl.selectTemplate(emailTemplatesCtrl.templateOption);
                            $window.scrollTo(0, 0);
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            emailTemplatesCtrl.selectTemplate(emailTemplatesCtrl.templateOption);

            emailTemplatesCtrl.edit = function () {
                emailTemplatesCtrl.isEditing = true;
                emailTemplatesCtrl.isViewing = false;
                emailTemplatesCtrl.isViewingRevisions = false;

                emailTemplatesCtrl.selectTemplate(emailTemplatesCtrl.templateOption);
            };

            emailTemplatesCtrl.view = function () {
                emailTemplatesCtrl.isEditing = false;
                emailTemplatesCtrl.isViewing = true;
                emailTemplatesCtrl.isViewingRevisions = false;

                emailTemplatesCtrl.selectTemplate(emailTemplatesCtrl.templateOption);
            };

            emailTemplatesCtrl.viewRevisions = function () {
                emailTemplatesCtrl.isEditing = false;
                emailTemplatesCtrl.isViewing = false;
                emailTemplatesCtrl.isViewingRevisions = true;

                emailTemplatesCtrl.selectTemplate(emailTemplatesCtrl.templateOption);
            };
        },
    ]);
