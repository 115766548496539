angular
    .module("admin.users", [
        "app.models.users",
        "infinite-scroll",
        "angular.filter",
        "admin.users.create",
        "admin.users.user",
    ])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.users", {
            url: "/users",
            views: {
                "": {
                    templateUrl: "admin/users/users.tmpl.html",
                    controller: "AdminUsersCtrl as usersCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Users",
            },
        });
    })
    .controller("AdminUsersCtrl", [
        "$scope",
        "$window",
        "$timeout",
        "UsersModel",
        function ($scope, $window, $timeout, UsersModel) {
            var usersCtrl = this;

            usersCtrl.limitTo = 25;
            usersCtrl.pageYOffset = null;
            usersCtrl.minLimitTo = null;

            usersCtrl.searchProps = [
                {
                    label: "All",
                    value: null,
                },
                {
                    label: "Last Name",
                    value: "last_name",
                },
                {
                    label: "First Name",
                    value: "first_name",
                },
                {
                    label: "Email",
                    value: "email",
                },
                {
                    label: "Roles",
                    value: "rolesList",
                },
            ];

            usersCtrl.searchCol = usersCtrl.searchProps[0];

            usersCtrl.sortType = "last_name";

            function getUsers() {
                usersCtrl.isLoading = true;

                UsersModel.getUsers()
                    .then(function (users) {
                        _.forEach(users, function (user) {
                            user.rolesList = _.map(user.roles, "display_name").join(", ");
                        });

                        usersCtrl.users = users;
                    })
                    .finally(function () {
                        $timeout(function () {
                            usersCtrl.isLoading = false;
                        });
                    });
            }

            getUsers();

            $scope.$on("users.update", function () {
                getUsers();
            });

            usersCtrl.setLimitTo = function (totalItems) {
                if (usersCtrl.limitTo < totalItems) {
                    if (!usersCtrl.pageYOffset) {
                        usersCtrl.pageYOffset = $window.pageYOffset;
                    }

                    if (usersCtrl.pageYOffset && !usersCtrl.minLimitTo) {
                        usersCtrl.minLimitTo = usersCtrl.limitTo;
                    }

                    usersCtrl.limitTo += 25;
                }
            };

            usersCtrl.sort = function (sortType) {
                usersCtrl.limitTo = 25;
                usersCtrl.sortReverse = !usersCtrl.sortReverse;
                usersCtrl.sortType = sortType;
            };

            usersCtrl.filterSearch = function () {
                var filter = {};

                if (usersCtrl.searchCol.value) {
                    filter = _.set(filter, usersCtrl.searchCol.value, usersCtrl.search);
                } else {
                    filter = usersCtrl.search;
                }

                return filter;
            };

            usersCtrl.resetLimitTo = function () {
                if (usersCtrl.limitTo > usersCtrl.minLimitTo) {
                    $timeout(function () {
                        usersCtrl.limitTo = usersCtrl.minLimitTo || usersCtrl.limitTo;
                    });
                }
            };

            var window = angular.element($window);

            var scrollYHandler = _.throttle(function (e) {
                if (
                    usersCtrl.pageYOffset &&
                    $window.pageYOffset + usersCtrl.pageYOffset * 0.25 < usersCtrl.pageYOffset
                ) {
                    usersCtrl.resetLimitTo();
                }
            }, 100);

            window.on("scroll", scrollYHandler);

            $scope.$on("$destroy", function () {
                window.off("scroll", scrollYHandler);
            });
        },
    ]);
