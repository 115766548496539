angular.module("app.factories.proposals", []).factory("ProposalsFactory", [
    "$window",
    "$timeout",
    "$filter",
    "ProposalsModel",
    "Loader",
    function ($window, $timeout, $filter, ProposalsModel, Loader) {
        var proposalsFactory = this;

        var dates = ProposalsModel.dates();
        var times = ProposalsModel.times();
        var numbers = ProposalsModel.numbers();
        var booleans = ProposalsModel.booleans();
        var statuses = ProposalsModel.statuses();

        function getSearchProperties() {
            return [
                {
                    label: "All",
                    value: null,
                    is_hidden: 0,
                },
                {
                    label: "ID",
                    value: "id",
                    is_hidden: 0,
                    group: "Proposal",
                },
                {
                    label: "Title",
                    value: "title",
                    is_hidden: 0,
                    group: "Proposal",
                },
                {
                    label: "Approval Status",
                    value: "is_approved",
                    is_hidden: 0,
                    group: "Status",
                },
                {
                    label: "Acceptance Status",
                    value: "is_accepted",
                    is_hidden: 0,
                    group: "Status",
                },
                {
                    label: "Is Routing?",
                    value: "is_routing",
                    is_hidden: 0,
                    group: "Confirmation",
                },
                {
                    label: "Created",
                    value: "created_at",
                    is_hidden: 0,
                    group: "Confirmation",
                },
                {
                    label: "Updated",
                    value: "max_updated_at",
                    is_hidden: 0,
                    group: "Confirmation",
                },
            ];
        }

        function getSortProperties() {
            return [];
        }

        function getStatusPopoverHTML(statusAttribute) {
            var html = "";

            if (statusAttribute == "is_approved") {
                html =
                    '<div><span class="text-success"><i class="fa fa-lg fa-check-circle"></i></span>&mdash;All approved</div>';
                html +=
                    '<div><span class="text-warning"><i class="fa fa-lg fa-exclamation-triangle"></i></span>&mdash;At least one approved</div>';
                html +=
                    '<div><span class="text-danger"><i class="fa fa-lg fa-exclamation-circle"></i></span>&mdash;None approved</div>';
            }

            if (statusAttribute == "is_accepted") {
                html =
                    '<div><span class="text-success"><i class="fa fa-lg fa-check-circle"></i></span>&mdash;All accepted</div>';
                html +=
                    '<div><span class="text-warning"><i class="fa fa-lg fa-exclamation-triangle"></i></span>&mdash;At least one accepted</div>';
                html +=
                    '<div><span class="text-danger"><i class="fa fa-lg fa-exclamation-circle"></i></span>&mdash;None accepted</div>';
            }

            return html;
        }

        function getStatusHTML(status) {
            var html = "";

            if (status === true) {
                html = '<span class="text-success"><i class="fa fa-lg fa-check-circle"></i></span>';
            } else if (status === null) {
                html = '<span class="text-danger"><i class="fa fa-lg fa-exclamation-circle"></i></span>';
            } else if (status === false) {
                html = '<span class="text-warning"><i class="fa fa-lg fa-exclamation-triangle"></i></span>';
            }

            return html;
        }

        function getBooleanHTML(status) {
            var html = "";

            if (status === true) {
                html = '<span class="text-success"><i class="fa fa-lg fa-check-circle"></i></span>';
            } else if (status === false) {
                html = '<span class="text-danger"><i class="fa fa-lg fa-exclamation-circle"></i></span>';
            }

            return html;
        }

        function format(prop, value) {
            if (_.includes(times, prop)) {
                return $filter("date")(value, "h:mm a");
            }

            if (_.includes(dates, prop)) {
                return $filter("date")(value, "M/d/yy");
            }

            if (_.includes(booleans, prop)) {
                return value === true ? "Yes" : value === false ? "No" : "";
            }

            if (_.includes(statuses, prop)) {
                return getStatusHTML(value);
            }

            return value;
        }

        function getSearch() {
            var searchProps = getSearchProperties();
            var sortProps = getSortProperties();
            var currentSortType = "max_updated_at";

            var search = {
                searchProps: searchProps,
                sortProps: sortProps,
                searchCol: searchProps[0],
                sortCol: sortProps[0],
                sortType: "max_updated_at",
                sortReverse: true,
                limitTo: 25,
                totalItems: 0,
                orderBy: "max_updated_at",
                value: "",
                currentPage: 1,
                dates: dates,
                time: times,
                numbers: numbers,
                booleans: booleans,
                statuses: statuses,
                filters: [],
                searchCols: [],
                get: function () {
                    var filter = {};

                    if (this.searchCol.value) {
                        value = this.value;

                        if (_.includes(this.dates, this.searchCol.value)) {
                            value = angular.fromJson(angular.toJson(this.value));
                        }

                        if (!value && value !== 0 && value !== false) {
                            value = null;
                        }

                        filter = _.set(filter, this.searchCol.value, value);
                    } else {
                        filter = this.value;
                    }

                    return filter;
                },
                dateFilter: function () {
                    var filter = {};

                    if (!_.includes(this.dates, this.searchCol.value)) {
                        return filter;
                    }

                    if (this.searchCol.value) {
                        var value = this.value;
                        if (!value) {
                            value = null;
                        }
                        filter[this.searchCol.value] = value;
                    }

                    return filter;
                },
                stringComparator: function (a, b) {
                    return typeof a === "string" && typeof b === "string"
                        ? a.toUpperCase() === b.toUpperCase()
                        : angular.equals(a, b);
                },
                numberComparator: function (a, b) {
                    return a == b;
                },
                dateComparator: function (a, b) {
                    return angular.equals(new Date(a).setHours(0, 0, 0, 0), new Date(b).setHours(0, 0, 0, 0));
                },
                timeComparator: function (a, b) {
                    var dateA = new Date(a);
                    var dateB = new Date(b);

                    if (!b) {
                        return a == b;
                    }

                    return (
                        angular.equals(dateA.getHours(), dateB.getHours()) &&
                        angular.equals(dateA.getMinutes(), dateB.getMinutes())
                    );
                },
                updateSortCol: function () {
                    var searchCol = this.searchCol;
                    if (this.searchCol.value) {
                        var index = _.findIndex(this.sortProps, function (property) {
                            return property.value == searchCol.value;
                        });
                        if (index >= 0) {
                            this.sortCol = this.sortProps[index];
                        }
                    }
                    this.value = null;
                },
                pageYOffset: null,
                minLimitTo: null,
                resetLimitTo: function () {
                    if (this.limitTo > this.minLimitTo) {
                        $timeout(function () {
                            search.limitTo = search.minLimitTo || search.limitTo;
                        });
                    }
                },
                setLimitTo: function (totalItems) {
                    if (this.limitTo < totalItems) {
                        if (!this.pageYOffset) {
                            this.pageYOffset = $window.pageYOffset;
                        }

                        if (this.pageYOffset && !this.minLimitTo) {
                            this.minLimitTo = this.limitTo;
                        }

                        this.limitTo += 25;
                    }
                },
                statusOrder: function (item) {
                    var value = _.get(item, currentSortType);
                    if (value === null) return 1;
                    if (value === false) return 2;
                    if (value === true) return 3;
                    return 4;
                },
                numberOrder: function (item) {
                    return +_.get(item, currentSortType);
                },
                sort: function (sortType) {
                    this.limitTo = this.minLimitTo || this.limitTo;
                    this.sortReverse = !this.sortReverse;
                    this.sortType = sortType;

                    currentSortType = sortType;

                    if (_.includes(this.numbers, sortType)) {
                        this.orderBy = [this.numberOrder, sortType];
                    } else if (_.includes(this.statuses, sortType)) {
                        this.orderBy = [this.statusOrder, sortType];
                    } else if (sortType == "semester") {
                        this.orderBy = ["semester.year", "semester.term.weight"];
                    } else {
                        this.orderBy = sortType;
                    }

                    this.currentPage = 1;
                },
                format: format,
                cleanUp: function () {
                    window.off("scroll", scrollYHandler);
                },
            };

            var window = angular.element($window);

            var scrollYHandler = _.throttle(function (e) {
                if (search.pageYOffset && $window.pageYOffset + search.pageYOffset * 0.25 < search.pageYOffset) {
                    search.resetLimitTo();
                }
            }, 100);

            window.on("scroll", scrollYHandler);

            return search;
        }

        function exportProposalContracts(proposal) {
            Loader.showLoading("Exporting Proposal");

            var term = proposal.semester.term.name.substring(0, 2).toUpperCase();
            var year = String(proposal.semester.year).substring(2, 4);
            var date = $filter("date")(new Date(), "M-d-yyyy");

            var fileName = term + year + "_" + proposal.title + "_" + date + ".xlsx";

            alasql.fn.datetime = function (date) {
                if (date) {
                    date = new Date(date);
                    return $filter("date")(date, "M/d/yyyy");
                }
            };

            alasql.fn.get = function (value) {
                if (!value && value !== 0) {
                    return "";
                }
                return value;
            };

            alasql
                .promise(
                    "SELECT " +
                        "get(user_ums_id) AS [Employee ID], " +
                        "get(user_last_name) AS [Last Name], " +
                        "get(user_first_name) AS [First Name], " +
                        "get(instructor_unit) AS Unit, " +
                        "get(instructor_rank) AS Rank, " +
                        "get(section_max_units) AS [Max Units], " +
                        "get(salary) AS Salary, " +
                        "get(payments) AS [No. Payments], " +
                        "datetime(pay_start_date) AS [Pay Start Date], " +
                        "datetime(pay_end_date) AS [Pay End Date], " +
                        "get(subject) AS Subject, " +
                        "get(course_catalog) AS Catalog, " +
                        "get(section_no) AS Section, " +
                        "get(lesson_comb_sects_id) AS [Comb Sects ID], " +
                        "get(course_title) AS [Course Title], " +
                        "datetime(lesson_start_date_one) AS [Start Date 1], " +
                        "datetime(lesson_end_date_one) AS [End Date 1], " +
                        "get(department) AS Dept " +
                        'INTO XLSX("' +
                        fileName +
                        '", {headers:true}) FROM ?',
                    [proposal.contracts]
                )
                .then(function () {
                    Loader.hideLoading();
                })
                .catch(function (err) {
                    console.log(err);
                    Loader.hideLoading();
                });
        }

        proposalsFactory.exportProposalContracts = exportProposalContracts;

        proposalsFactory.getSearch = getSearch;
        proposalsFactory.getSearchProperties = getSearchProperties;
        proposalsFactory.getSortProperties = getSortProperties;

        proposalsFactory.getStatusPopoverHTML = getStatusPopoverHTML;
        proposalsFactory.getStatusHTML = getStatusHTML;
        proposalsFactory.getBooleanHTML = getBooleanHTML;

        return proposalsFactory;
    },
]);
