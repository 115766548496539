angular.module("app.models.users", []).service("UsersModel", [
    "$http",
    "$q",
    "API_URL",
    function ($http, $q, API_URL) {
        var model = this,
            users,
            currentUser,
            approverProposals,
            approverWarnings,
            approverSemesters,
            instructorWarnings,
            instructorSemesters,
            API = {
                /**
                 * User
                 */

                getUsers: function () {
                    return $http.get(API_URL + "users");
                },
                getUser: function (userId) {
                    return $http.get(API_URL + "users/" + userId);
                },
                getProfile: function () {
                    return $http.get(API_URL + "profile");
                },
                createUser: function (user) {
                    return $http.post(API_URL + "users", user);
                },
                updateUser: function (user) {
                    return $http.patch(API_URL + "users/" + user.id, user);
                },
                deleteUser: function (userId) {
                    return $http.delete(API_URL + "users/" + userId);
                },

                /**
                 * User Audits
                 */

                getAudits: function (userId, params) {
                    return $http.get(API_URL + "users/" + userId + "/audits", { params: params });
                },

                /**
                 * Approver
                 */

                getApproverSemesters: function () {
                    return $http.get(API_URL + "approver/semesters");
                },

                /**
                 * Approver Proposals
                 */

                getApproverSemesterProposals: function (semesterId) {
                    return $http.get(API_URL + "approver/semesters/" + semesterId + "/proposals");
                },

                createApprovals: function (proposalId, approvals) {
                    return $http.post(API_URL + "approver/proposals/" + proposalId + "/approvals", approvals);
                },

                /**
                 * Approver Warnings
                 */

                getApproverSemesterWarnings: function (semesterId) {
                    return $http.get(API_URL + "approver/semesters/" + semesterId + "/warnings");
                },
                updateApproverSemesterWarning: function (semesterId, warningId, editedWarning) {
                    return $http.patch(
                        API_URL + "approver/semesters/" + semesterId + "/warnings/" + warningId,
                        editedWarning
                    );
                },

                /**
                 * Instructor
                 */

                getInstructorSemesters: function () {
                    return $http.get(API_URL + "instructor/semesters");
                },

                /**
                 * Instructor Contracts
                 */

                getInstructorSemesterContracts: function (semesterId) {
                    return $http.get(API_URL + "instructor/semesters/" + semesterId + "/contracts");
                },
                getInstructorContract: function (contractId) {
                    return $http.get(API_URL + "instructor/contracts/" + contractId);
                },
                updateInstructorContract: function (form) {
                    return $http.patch(API_URL + "instructor/contracts/" + form.contract_id, form);
                },

                /**
                 * Instructor Warnings
                 */

                getInstructorSemesterWarnings: function (semesterId) {
                    return $http.get(API_URL + "instructor/semesters/" + semesterId + "/warnings");
                },
                updateInstructorSemesterWarning: function (semesterId, warningId, editedWarning) {
                    return $http.patch(
                        API_URL + "instructor/semesters/" + semesterId + "/warnings/" + warningId,
                        editedWarning
                    );
                },
            };

        model.fields = function () {
            return ["id", "ums_id", "first_name", "last_name", "email", "created_at", "updated_at"];
        };

        model.labels = function () {
            return {
                id: "ID",
                ums_id: "Employee ID",
                first_name: "First Name",
                last_name: "Last Name",
                email: "Email",
            };
        };

        model.groups = function () {
            return {
                id: "User",
                ums_id: "User",
                first_name: "User",
                last_name: "User",
                email: "User",
            };
        };

        model.dates = function () {
            return ["created_at", "updated_at"];
        };

        model.times = function () {
            return [];
        };

        model.booleans = function () {
            return [];
        };

        function extract(result) {
            return result.data;
        }

        /**
         * Users
         */

        function cacheUsers(result) {
            users = extract(result);
            return users;
        }

        model.getUsers = function () {
            if (users) {
                return $q.when(users);
            } else {
                users = API.getUsers().then(cacheUsers);
                return users;
            }
        };

        model.getUser = function (userId) {
            var deferred = $q.defer();

            function findUser() {
                return _.find(users, function (user) {
                    return user.id == userId;
                });
            }

            if (users) {
                $q.when(users).then(function () {
                    deferred.resolve(findUser());
                });
            } else {
                model.getUsers().then(function () {
                    deferred.resolve(findUser());
                });
            }

            return deferred.promise;
        };

        model.createUser = function (user) {
            var deferred = $q.defer();

            API.createUser(user).then(
                function (result) {
                    user = extract(result);
                    users.push(user);
                    deferred.resolve(user);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.updateUser = function (user) {
            var deferred = $q.defer();

            API.updateUser(user).then(
                function (result) {
                    result = extract(result);

                    user = _.find(users, function (user) {
                        return user.id == result.id;
                    });

                    if (user) {
                        _.assign(user, result);
                    }

                    if (currentUser.id == result.id) {
                        _.assign(currentUser, result);
                    }

                    deferred.resolve(result);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteUser = function (userId) {
            var deferred = $q.defer();

            API.deleteUser(userId).then(
                function (result) {
                    _.remove(users, function (user) {
                        return user.id == userId;
                    });
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /**
         * Current User
         */

        function cacheUserProfile(result) {
            currentUser = extract(result);

            return currentUser;
        }

        model.getProfile = function () {
            if (currentUser) {
                return $q.when(currentUser);
            } else {
                currentUser = API.getProfile().then(cacheUserProfile);
                return currentUser;
            }
        };

        model.setCurrentUser = function (user) {
            currentUser = user;
        };

        model.getCurrentUser = function () {
            return currentUser;
        };

        /**
         * Current User Permissions
         */

        model.getPermissions = function () {
            var deferred = $q.defer();

            function getPermissions() {
                var roles = currentUser.roles;
                var permissions = [];
                _.forEach(roles, function (role) {
                    permissions = permissions.concat(role.perms);
                });

                return permissions;
            }

            if (currentUser) {
                deferred.resolve(getPermissions());
            } else {
                model.getProfile().then(function () {
                    deferred.resolve(getPermissions());
                });
            }

            return deferred.promise;
        };

        /**
         * User Audits
         */

        model.getAudits = function (userId, params) {
            var deferred = $q.defer();

            API.getAudits(userId, params).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /**
         * Approver
         */

        function cacheApproverSemesters(result) {
            approverSemesters = extract(result);
            return approverSemesters;
        }

        model.getApproverSemesters = function () {
            if (approverSemesters) {
                return $q.when(approverSemesters);
            } else {
                approverSemesters = API.getApproverSemesters().then(cacheApproverSemesters);
                return approverSemesters;
            }
        };

        model.getApproverSemester = function (semesterId) {
            var deferred = $q.defer();

            function findSemester() {
                var semester = _.find(approverSemesters, function (semester) {
                    return semester.id == semesterId;
                });

                if (semester) {
                    deferred.resolve(semester);
                } else {
                    deferred.reject();
                }
            }

            if (approverSemesters) {
                $q.when(approverSemesters).then(function () {
                    findSemester();
                });
            } else {
                model.getApproverSemesters().then(function () {
                    findSemester();
                });
            }

            return deferred.promise;
        };

        /**
         * Approver Proposals
         */

        function cacheApproverSemesterProposals(semesterId, result) {
            approverProposals = _.union(approverProposals, extract(result));
            return _.filter(approverProposals, { semester_id: semesterId });
        }

        model.getApproverSemesterProposals = function (semesterId) {
            var deferred = $q.defer();

            if (approverProposals && _.filter(approverProposals, { semester_id: semesterId }).length) {
                deferred.resolve(_.filter(approverProposals, { semester_id: semesterId }));
            } else {
                API.getApproverSemesterProposals(semesterId).then(function (result) {
                    deferred.resolve(cacheApproverSemesterProposals(semesterId, result));
                });
            }

            return deferred.promise;
        };

        model.getApproverSemesterProposal = function (semesterId, proposalId) {
            var deferred = $q.defer();

            function findProposal() {
                var proposal = _.find(approverProposals, function (proposal) {
                    return proposal.id == proposalId;
                });

                if (proposal) {
                    deferred.resolve(proposal);
                } else {
                    deferred.reject();
                }
            }

            if (approverProposals && _.filter(approverProposals, { semester_id: semesterId }).length) {
                $q.when(approverProposals).then(function () {
                    findProposal();
                });
            } else {
                model.getApproverSemesterProposals(semesterId).then(function () {
                    findProposal();
                });
            }

            return deferred.promise;
        };

        model.createApprovals = function (proposalId, approvals) {
            var deferred = $q.defer();

            API.createApprovals(proposalId, approvals).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /**
         * Approver Warnings
         */

        function cacheApproverSemesterWarnings(semesterId, result) {
            approverWarnings = _.union(approverWarnings, extract(result));
            return _.filter(approverWarnings, ["contract.semester_id", semesterId]);
        }

        model.getApproverSemesterWarnings = function (semesterId) {
            var deferred = $q.defer();

            if (approverWarnings && _.filter(approverWarnings, ["contract.semester_id", semesterId]).length) {
                deferred.resolve(_.filter(approverWarnings, ["contract.semester_id", semesterId]));
            } else {
                API.getApproverSemesterWarnings(semesterId).then(function (result) {
                    deferred.resolve(cacheApproverSemesterWarnings(semesterId, result));
                });
            }

            return deferred.promise;
        };

        model.updateApproverSemesterWarning = function (semesterId, warningId, editedWarning) {
            var deferred = $q.defer();

            API.updateApproverSemesterWarning(semesterId, warningId, editedWarning).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /**
         * Instructor
         */

        function cacheInstructorSemesters(result) {
            instructorSemesters = extract(result);
            return instructorSemesters;
        }

        model.getInstructorSemesters = function () {
            if (instructorSemesters) {
                return $q.when(instructorSemesters);
            } else {
                instructorSemesters = API.getInstructorSemesters().then(cacheInstructorSemesters);
                return instructorSemesters;
            }
        };

        model.getInstructorSemester = function (semesterId) {
            var deferred = $q.defer();

            function findSemester() {
                var semester = _.find(instructorSemesters, function (semester) {
                    return semester.id == semesterId;
                });

                if (semester) {
                    deferred.resolve(semester);
                } else {
                    deferred.reject();
                }
            }

            if (instructorSemesters) {
                $q.when(instructorSemesters).then(function () {
                    findSemester();
                });
            } else {
                model.getInstructorSemesters().then(function () {
                    findSemester();
                });
            }

            return deferred.promise;
        };

        /**
         * Instructor Contracts
         */

        function cacheInstructorSemesterContracts(semester, result) {
            semester.contracts = extract(result);

            return semester.contracts;
        }

        model.clearInstructorSemesterContractsCache = function (semesterId) {
            model.getInstructorSemester(semesterId).then(function (semester) {
                semester.contracts = null;
            });
        };

        model.getInstructorSemesterContracts = function (semesterId) {
            var deferred = $q.defer();
            var cacheDeferred = $q.defer();

            model.getInstructorSemester(semesterId).then(
                function (semester) {
                    if (semester.contracts) {
                        $q.when(semester.contracts).then(function () {
                            deferred.resolve(semester.contracts);
                        });
                    } else {
                        semester.contracts = cacheDeferred.promise;

                        API.getInstructorSemesterContracts(semesterId).then(function (result) {
                            cacheInstructorSemesterContracts(semester, result);
                            cacheDeferred.resolve();
                            deferred.resolve(semester.contracts);
                        });
                    }
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        };

        model.getInstructorContract = function (contractId) {
            var deferred = $q.defer();

            API.getInstructorContract(contractId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        // model.getInstructorSemesterContract = function (semesterId, contractId) {
        //     var deferred = $q.defer();

        //     model.getInstructorSemesterContracts(semesterId).then(
        //         function (contracts) {
        //             var contract = _.find(contracts, function (contract) {
        //                 return contract.id == contractId;
        //             });

        //             if (contract) {
        //                 deferred.resolve(contract);
        //             } else {
        //                 deferred.reject();
        //             }
        //         },
        //         function () {
        //             deferred.reject();
        //         }
        //     );

        //     return deferred.promise;
        // };

        model.updateInstructorContract = function (form) {
            var deferred = $q.defer();

            API.updateInstructorContract(form).then(
                function (result) {
                    var contract = extract(result);
                    deferred.resolve(contract);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /**
         * Instructor Warnings
         */

        function cacheInstructorSemesterWarnings(semesterId, result) {
            instructorWarnings = _.union(instructorWarnings, extract(result));
            return _.filter(instructorWarnings, ["contract.semester_id", semesterId]);
        }

        model.getInstructorSemesterWarnings = function (semesterId) {
            var deferred = $q.defer();

            if (instructorWarnings && _.filter(instructorWarnings, ["contract.semester_id", semesterId]).length) {
                deferred.resolve(_.filter(instructorWarnings, ["contract.semester_id", semesterId]));
            } else {
                API.getInstructorSemesterWarnings(semesterId).then(function (result) {
                    deferred.resolve(cacheInstructorSemesterWarnings(semesterId, result));
                });
            }

            return deferred.promise;
        };

        model.updateInstructorSemesterWarning = function (semesterId, warningId, editedWarning) {
            var deferred = $q.defer();

            API.updateInstructorSemesterWarning(semesterId, warningId, editedWarning).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.clearCache = function () {
            users = null;
            approverProposals = null;
            approverWarnings = null;
            approverSemesters = null;
            instructorWarnings = null;
            instructorSemesters = null;
        };
    },
]);
