angular
    .module("admin.semesters.semester.contracts.contract.duplicate", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.contracts.contract.duplicate", {
            url: "/duplicate",
            views: {
                duplicate: {
                    templateUrl: "admin/semesters/contracts/contract/duplicate/contract-duplicate.tmpl.html",
                    controller: "DuplicateContractCtrl as duplicateContractCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Duplicate Contract",
            },
        });
    })
    .controller("DuplicateContractCtrl", [
        "semester",
        "$stateParams",
        "$scope",
        "ContractsModel",
        "Loader",
        "AlertFactory",
        function (semester, $stateParams, $scope, ContractsModel, Loader, AlertFactory) {
            var duplicateContractCtrl = this;
            var contractId = $stateParams.contractId;

            function duplicateContract(newContract) {
                Loader.showLoading("Duplicating Contract");
                ContractsModel.duplicateContract(contractId, newContract)
                    .then(
                        function (contract) {
                            semester.contracts.push(contract);
                            $scope.$emit("contracts.duplicate", contract);
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            }

            duplicateContractCtrl.duplicateContract = duplicateContract;
        },
    ]);
