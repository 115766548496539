angular
    .module("approver.semesters.proposals", ["approver.semesters.proposals.proposal"])
    .config(function ($stateProvider) {
        $stateProvider.state("approver.semesters.semester.proposals", {
            url: "/proposals",
            views: {
                "": {
                    templateUrl: "approver/semesters/proposals/proposals.tmpl.html",
                    controller: "ApproverSemesterProposalsCtrl as proposalsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Proposals",
            },
            resolve: {
                proposals: [
                    "$state",
                    "$stateParams",
                    "$q",
                    "UsersModel",
                    "Loader",
                    function ($state, $stateParams, $q, UsersModel, Loader) {
                        var deferred = $q.defer();
                        var semesterId = $stateParams.semesterId;

                        Loader.showLoading("Loading Proposals");
                        UsersModel.getApproverSemesterProposals(semesterId)
                            .then(
                                function (proposals) {
                                    deferred.resolve(proposals);
                                },
                                function () {
                                    deferred.reject();
                                    $state.go("approver.semesters.semester", { semesterId: semesterId });
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });

                        return deferred.promise;
                    },
                ],
            },
        });
    })
    .controller("ApproverSemesterProposalsCtrl", [
        "proposals",
        "$scope",
        "$stateParams",
        "ProposalsFactory",
        function (proposals, $scope, $stateParams, ProposalsFactory) {
            var proposalsCtrl = this;
            proposalsCtrl.semesterId = $stateParams.semesterId;

            proposalsCtrl.proposals = proposals;

            proposalsCtrl.search = ProposalsFactory.getSearch();

            $scope.$on("$destroy", function () {
                proposalsCtrl.search.cleanUp();
            });

            proposalsCtrl.getStatusHTML = ProposalsFactory.getStatusHTML;
        },
    ]);
