angular
    .module("admin.semesters.semester.contracts.import-update", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.contracts.import-update", {
            url: "/import-update",
            views: {
                import: {
                    templateUrl: "admin/semesters/contracts/import-update/contracts-import-update.tmpl.html",
                    controller: "ImportContractsUpdateCtrl as importContractsUpdateCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Import Update",
            },
        });
    })
    .controller("ImportContractsUpdateCtrl", [
        "semester",
        "$scope",
        "$state",
        "$stateParams",
        "SemestersModel",
        "ContractsModel",
        "Loader",
        "AlertFactory",
        "$filter",
        function (
            semester,
            $scope,
            $state,
            $stateParams,
            SemestersModel,
            ContractsModel,
            Loader,
            AlertFactory,
            $filter
        ) {
            var importContractsUpdateCtrl = this;
            var semesterId = $stateParams.semesterId;
            importContractsUpdateCtrl.contracts = {};

            importContractsUpdateCtrl.importContractsUpdate = function () {
                Loader.showLoading("Importing Update");

                var fd = new FormData();
                fd.append("file", importContractsUpdateCtrl.file);
                fd.append("excludeCancelled", importContractsUpdateCtrl.excludeCancelled);

                importContractsUpdateCtrl.importContractsUpdateForm.successes = null;
                importContractsUpdateCtrl.importContractsUpdateForm.errors = null;
                importContractsUpdateCtrl.importContractsUpdateForm.error = null;

                SemestersModel.importContractsUpdate(semesterId, fd)
                    .then(
                        function (result) {
                            if (result) {
                                if (result.successes && result.successes.length) {
                                    importContractsUpdateCtrl.importContractsUpdateForm.successes = result.successes;
                                }
                                if (result.errors && result.errors.length) {
                                    importContractsUpdateCtrl.importContractsUpdateForm.errors = result.errors;
                                }
                                if (result.data && result.data.length) {
                                    importContractsUpdateCtrl.importContractsUpdateForm.data = result.data;
                                }
                                $scope.$emit("contracts.import");
                            } else {
                                $state.go("admin.semesters.semester.contracts");
                            }
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            importContractsUpdateCtrl.exportResults = function (data) {
                if (!data.length) {
                    AlertFactory.show("No data", "danger");
                    return;
                }

                Loader.showLoading("Exporting Results");

                var term = semester.term.name.substring(0, 2).toUpperCase();
                var year = String(semester.year).substring(2, 4);
                var date = $filter("date")(new Date(), "M-d-yy");

                var fileName = term + year + "_Import Update Results_" + date + ".xlsx";

                alasql.fn.datetime = function (date) {
                    if (date) {
                        date = new Date(date);
                        return $filter("date")(date, "M/d/yyyy");
                    }
                    return "";
                };

                alasql.fn.get = function (value) {
                    if (!value && value !== 0) {
                        return "";
                    }
                    return value;
                };

                alasql
                    .promise('SELECT * INTO XLSX("' + fileName + '", {headers:true}) FROM ?', [data])
                    .then(function () {
                        Loader.hideLoading();
                    })
                    .catch(function (err) {
                        AlertFactory.show("Export Error", "danger");
                        console.log(err);
                        Loader.hideLoading();
                    });
            };
        },
    ]);
