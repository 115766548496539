angular
    .module("instructor.semesters.contracts.revisions.revision", [])
    .config(function ($stateProvider) {
        $stateProvider.state("instructor.semesters.semester.contracts.revisions.revision", {
            url: "/revisions/{revisionId:int}",
            views: {
                "": {
                    templateUrl: "instructor/semesters/contracts/revisions/revision/revision.tmpl.html",
                    controller: "InstructorRevisionCtrl as instructorRevisionCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Revision",
            },
        });
    })
    .controller("InstructorRevisionCtrl", [
        "contract",
        "$scope",
        "$stateParams",
        "$window",
        "Loader",
        "UsersModel",
        "InstructorsModel",
        "ApproversModel",
        "SemestersModel",
        function (
            contract,
            $scope,
            $stateParams,
            $window,
            Loader,
            UsersModel,
            InstructorsModel,
            ApproversModel,
            SemestersModel
        ) {
            var instructorRevisionCtrl = this;

            var revisionId = $stateParams.revisionId;

            var revisions = contract.revisions;

            var index = _.findIndex(revisions, function (revision) {
                return revisionId == revision.id;
            });

            instructorRevisionCtrl.revision = revisions[index];
            instructorRevisionCtrl.prevRevision = revisions[index + 1] || revisions[index];
            instructorRevisionCtrl.contract = contract;
            instructorRevisionCtrl.revisions = revisions;

            $scope.$emit("revisionIdUpdated", revisionId);

            instructorRevisionCtrl.acceptContract = function (form) {
                Loader.showLoading("Updating Contract");
                UsersModel.updateInstructorContract(form)
                    .then(
                        function (updatedContract) {
                            $window.scrollTo(0, 0);

                            _.assign(instructorRevisionCtrl.revision, updatedContract);

                            $scope.$emit("contractUpdated", updatedContract);

                            InstructorsModel.clearCache();
                            ApproversModel.clearCache();
                            SemestersModel.clearCache();
                        },
                        function (result) {
                            form.errors = result.errors;
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };
        },
    ]);
