angular.module("admin.semesters.semester.contracts.contract.delete", []).controller("DeleteContractCtrl", [
    "$uibModalInstance",
    function ($uibModalInstance) {
        var deleteContractCtrl = this;

        deleteContractCtrl.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        deleteContractCtrl.delete = function () {
            $uibModalInstance.close();
        };
    },
]);
