angular
    .module("admin.users.create", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.users.create", {
            url: "/create",
            views: {
                create: {
                    templateUrl: "admin/users/create/user-create.tmpl.html",
                    controller: "CreateUserCtrl as createUserCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Create User",
            },
        });
    })
    .controller("CreateUserCtrl", [
        "$scope",
        "$state",
        "$timeout",
        "UsersModel",
        "RolesModel",
        "Loader",
        "AlertFactory",
        function ($scope, $state, $timeout, UsersModel, RolesModel, Loader, AlertFactory) {
            var createUserCtrl = this;

            function returnToUsers() {
                $state.go("admin.users");
            }

            function cancelCreating() {
                returnToUsers();
            }

            function createUser(user) {
                Loader.showLoading("Creating User");
                UsersModel.createUser(user)
                    .then(
                        function (user) {
                            $scope.$emit("users.update");
                            $state.go("admin.users.user", { userId: user.id });
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_(result.errors).values().flatten().value().join("\r\n"), "danger");

                                $timeout(function () {
                                    _.forOwn(result.errors, function (value, key) {
                                        if (createUserCtrl.createUserForm[key]) {
                                            createUserCtrl.createUserForm[key].$invalid = true;
                                        }
                                    });
                                });
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            }

            function resetForm() {
                createUserCtrl.newUser = {
                    first_name: "",
                    last_name: "",
                    email: "",
                };
            }

            RolesModel.getRoles().then(function (roles) {
                createUserCtrl.roles = roles;
            });

            createUserCtrl.cancelCreating = cancelCreating;
            createUserCtrl.createUser = createUser;

            resetForm();
        },
    ]);
