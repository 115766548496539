angular
    .module("admin.approvers.approver.audits", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.approvers.approver.audits", {
            url: "/audits",
            views: {
                "": {
                    templateUrl: "admin/approvers/approver/audits/approver-audits.tmpl.html",
                    controller: "AuditApproverCtrl as auditApproverCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Audits",
            },
        });
    })
    .controller("AuditApproverCtrl", [
        "$stateParams",
        "$location",
        "$timeout",
        "ApproversModel",
        "RolesModel",
        "Loader",
        "LSFactory",
        "ApproversFactory",
        function ($stateParams, $location, $timeout, ApproversModel, RolesModel, Loader, LSFactory, ApproversFactory) {
            var auditApproverCtrl = this;
            var approverId = $stateParams.approverId;

            var roles = [];
            RolesModel.getRoles().then(function (result) {
                roles = result;
            });

            var params = $location.search();

            if (_.isEmpty(params)) {
                params = LSFactory.get("approverAuditsQuery");
            }

            auditApproverCtrl.events = ["created", "updated", "deleted"];

            auditApproverCtrl.fields = [];
            _.forEach(ApproversModel.fields(), function (field) {
                if (!_.includes(["created_at", "updated_at"], field)) {
                    auditApproverCtrl.fields.push({
                        value: field,
                        label: ApproversModel.labels()[field],
                        group: ApproversModel.groups()[field],
                    });
                }
            });

            if (!_.isEmpty(params)) {
                if (params.page) {
                    auditApproverCtrl.page = parseInt(params.page);
                }

                if (params.event) {
                    auditApproverCtrl.event = params.event;
                }

                if (params["field[]"]) {
                    auditApproverCtrl.field = _.filter(auditApproverCtrl.fields, function (field) {
                        return _.includes(params["field[]"], field.value);
                    });
                }

                updateQueryString();
            }

            function updateQueryString() {
                $timeout(function () {
                    LSFactory.set("approverAuditsQuery", params);
                    $location.search(params);
                });
            }

            function getAudits() {
                Loader.showLoading("Loading Audits");
                ApproversModel.getAudits(approverId, params).then(function (result) {
                    auditApproverCtrl.audits = result;
                    auditApproverCtrl.users = result.users;

                    if (params && params["user[]"]) {
                        auditApproverCtrl.user = _.filter(auditApproverCtrl.users, function (user) {
                            return _.includes(params["user[]"], user.id);
                        });
                    }

                    Loader.hideLoading();
                });
            }

            auditApproverCtrl.label = function (field) {
                $labels = ApproversModel.labels();

                if ($labels[field]) {
                    return $labels[field];
                }

                return field;
            };

            auditApproverCtrl.format = function (field, value) {
                value = ApproversFactory.format(field, value);

                if (field == "role_id") {
                    var role = _.find(roles, function (role) {
                        return role.id == value;
                    });

                    if (role) {
                        value = role.display_name + " (" + value + ")";
                    }
                }

                if (!value && value !== 0) {
                    value = "NULL";
                }

                return value;
            };

            var timeout;

            auditApproverCtrl.search = function (keywords) {
                function searchAudits() {
                    params = {};

                    if (auditApproverCtrl.page) {
                        params.page = parseInt(auditApproverCtrl.page);
                    }

                    if (auditApproverCtrl.event) {
                        params.event = auditApproverCtrl.event;
                    }

                    if (auditApproverCtrl.user) {
                        params["user[]"] = _.map(auditApproverCtrl.user, "id");
                    }

                    if (auditApproverCtrl.field) {
                        params["field[]"] = _.map(auditApproverCtrl.field, "value");
                    }

                    updateQueryString();

                    getAudits();
                }

                if (timeout) {
                    $timeout.cancel(timeout);
                }

                if (keywords) {
                    timeout = $timeout(
                        function () {
                            searchAudits();
                        },
                        500,
                        false
                    );
                } else {
                    searchAudits();
                }
            };

            auditApproverCtrl.clear = function (field) {
                if (field) {
                    if (field == "event") {
                        auditApproverCtrl.event = null;
                    }
                } else {
                    auditApproverCtrl.event = null;
                    auditApproverCtrl.user = null;
                    auditApproverCtrl.field = null;
                }
                auditApproverCtrl.page = 1;
                auditApproverCtrl.search();
            };

            getAudits();

            auditApproverCtrl.getAudits = getAudits;
        },
    ]);
