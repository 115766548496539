angular
    .module("admin.semesters.semester.enrollments.import", [])
    .config(function ($stateProvider) {
        $stateProvider.state("admin.semesters.semester.enrollments.import", {
            url: "/import",
            views: {
                import: {
                    templateUrl: "admin/semesters/enrollments/import/enrollments-import.tmpl.html",
                    controller: "ImportEnrollmentsCtrl as importEnrollmentsCtrl",
                },
            },
            ncyBreadcrumb: {
                label: "Import Enrollments",
            },
        });
    })
    .directive("fileModel", [
        "$parse",
        function ($parse) {
            return {
                restrict: "A",
                link: function (scope, element, attrs) {
                    var model = $parse(attrs.fileModel);
                    var modelSetter = model.assign;

                    element.bind("change", function () {
                        scope.$apply(function () {
                            modelSetter(scope, element[0].files[0]);
                        });
                    });
                },
            };
        },
    ])
    .controller("ImportEnrollmentsCtrl", [
        "$scope",
        "$state",
        "$stateParams",
        "SemestersModel",
        "Loader",
        "AlertFactory",
        "$filter",
        function ($scope, $state, $stateParams, SemestersModel, Loader, AlertFactory, $filter) {
            var importEnrollmentsCtrl = this;
            var semesterId = $stateParams.semesterId;
            importEnrollmentsCtrl.enrollments = {};
            importEnrollmentsCtrl.update_contracts = false;

            importEnrollmentsCtrl.importEnrollments = function () {
                Loader.showLoading("Importing Enrollments");

                var exported_at = moment(importEnrollmentsCtrl.exported_at).format();

                var fd = new FormData();
                fd.append("file", importEnrollmentsCtrl.file);
                fd.append("exported_at", exported_at);
                fd.append("update_contracts", importEnrollmentsCtrl.update_contracts);

                SemestersModel.importEnrollments(semesterId, fd, importEnrollmentsCtrl.update_contracts)
                    .then(
                        function (result) {
                            SemestersModel.clearContractsCache(semesterId);

                            if (result.errors) {
                                importEnrollmentsCtrl.importEnrollmentsForm.errors = result.errors;
                            } else {
                                $state.go("admin.semesters.semester.enrollments");
                            }

                            $scope.$emit("enrollments.update", exported_at);
                        },
                        function (result) {
                            if (result && result.errors) {
                                importEnrollmentsCtrl.importEnrollmentsForm.errors = result.errors;
                            } else {
                                AlertFactory.show("Cannot load excel file. It may be corrupt.", "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };
        },
    ]);
