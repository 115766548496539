angular
    .module("admin.semesters.semester.proposals.proposal.approvers", [
        "app.models.approvers",
        "app.factories.approvers",
        "admin.semesters.semester.proposals.proposal.approvers.add",
        "admin.semesters.semester.proposals.proposal.approvers.remove",
        "admin.semesters.semester.proposals.proposal.approvers.replace",
        "admin.semesters.semester.proposals.proposal.approvers.statuses",
        "admin.semesters.semester.proposals.proposal.approvers.approver.contracts",
    ])
    .config(function ($stateProvider) {
        $stateProvider
            .state("admin.semesters.semester.proposals.proposal.approvers", {
                url: "/approvers",
                views: {
                    "": {
                        templateUrl: "admin/semesters/proposals/approvers/approvers.tmpl.html",
                        controller: "AdminSemesterProposalApproversCtrl as proposalApproversCtrl",
                    },
                },
                ncyBreadcrumb: {
                    label: "Approvers",
                },
            })
            .state("admin.semesters.semester.proposals.proposal.approvers.approver", {
                abstract: true,
                url: "/{proposalApproverId:int}",
                templateUrl: "admin/semesters/proposals/approvers/approver.tmpl.html",
                ncyBreadcrumb: {
                    skip: true,
                },
                controller: [
                    "proposal",
                    "$stateParams",
                    function (proposal, $stateParams) {
                        var proposalApproverCtrl = this;

                        var proposalApproverId = $stateParams.proposalApproverId;

                        proposalApproverCtrl.proposalApprover = _.find(proposal.approvers, function (proposalApprover) {
                            return proposalApprover.id == proposalApproverId;
                        });
                    },
                ],
                controllerAs: "proposalApproverCtrl",
            });
    })
    .controller("AdminSemesterProposalApproversCtrl", [
        "proposal",
        "$stateParams",
        "$uibModal",
        "SemestersModel",
        "ProposalsModel",
        "Loader",
        "AlertFactory",
        function (proposal, $stateParams, $uibModal, SemestersModel, ProposalsModel, Loader, AlertFactory) {
            proposalApproversCtrl = this;

            var semesterId = $stateParams.semesterId;
            var proposalId = $stateParams.proposalId;

            proposalApproversCtrl.semesterId = semesterId;
            proposalApproversCtrl.proposalId = proposalId;

            proposalApproversCtrl.proposal = proposal;

            proposalApproversCtrl.removeProposalApprover = function (proposalApprover) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: "admin/semesters/proposals/approvers/remove/approver-remove.tmpl.html",
                    controller: "RemoveProposalApproverCtrl as removeProposalApproverCtrl",
                    resolve: {
                        proposalApprover: function () {
                            return proposalApprover;
                        },
                    },
                });

                modalInstance.result
                    .then(function (proposalApproverId) {
                        Loader.showLoading("Removing Approver");
                        ProposalsModel.removeProposalApprover(proposalId, proposalApproverId)
                            .then(
                                function (proposalApprovers) {
                                    _.remove(proposal.approvers, function (proposalApprover) {
                                        return proposalApprover.id == proposalApproverId;
                                    });

                                    _.merge(proposal.approvers, proposalApprovers);

                                    SemestersModel.clearWarningsCache(semesterId);
                                },
                                function (result) {
                                    if (result.errors) {
                                        AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                                    }
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });
                    })
                    .catch(function (result) {
                        if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                    });
            };

            proposalApproversCtrl.sendProposalApproverReminder = function (proposalApprover) {
                Loader.showLoading("Sending Reminder");
                ProposalsModel.sendProposalApproverReminder(proposalId, proposalApprover.id)
                    .then(
                        function (updatedProposalApprover) {
                            _.merge(proposalApprover, updatedProposalApprover);
                        },
                        function (result) {
                            if (result.errors) {
                                AlertFactory.show(_.values(result.errors).join("\r\n"), "danger");
                            }
                        }
                    )
                    .finally(function () {
                        Loader.hideLoading();
                    });
            };

            proposalApproversCtrl.getProposalApproverStatusHTML = function (proposalApprover) {
                var html = proposalApprover.status;

                if (proposalApprover.status == "Approved") {
                    html = '<span class="text-success"><i class="fa fa-check-circle"></i> ' + html + "</span>";
                } else if (proposalApprover.status == "Declined") {
                    html = '<span class="text-danger"><i class="fa fa-exclamation-circle"></i> ' + html + "</span>";
                } else if (proposalApprover.status.indexOf("Reminder") !== -1) {
                    if (proposalApprover.reminders > 1) {
                        html = html + " " + proposalApprover.reminders;
                    }
                    html = '<span><i class="fa fa-envelope"></i> ' + html + "</span>";
                } else if (proposalApprover.status == "Needs Revised") {
                    html = '<span class="text-warning"><i class="fa fa-exclamation-triangle"></i> ' + html + "</span>";
                }

                return html;
            };

            proposalApproversCtrl.showProposalApproverStatuses = function (proposalApprover) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: "admin/semesters/proposals/approvers/statuses/approver-statuses.tmpl.html",
                    controller: "ProposalApproverStatusesCtrl as proposalApproverStatusesCtrl",
                    resolve: {
                        proposalApprover: function () {
                            return proposalApprover;
                        },
                    },
                });

                modalInstance.result.catch(function (result) {
                    if (["cancel", "backdrop click", "escape key press"].indexOf(result) === -1) throw result;
                });
            };
        },
    ]);
