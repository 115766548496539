angular.module("app.models.semesters", []).service("SemestersModel", [
    "$http",
    "$q",
    "API_URL",
    function ($http, $q, API_URL) {
        var model = this,
            semesters,
            API = {
                /*
                 * Semesters
                 */

                getSemesters: function () {
                    return $http.get(API_URL + "semesters");
                },
                createSemester: function (semester) {
                    return $http.post(API_URL + "semesters", semester);
                },
                deleteSemester: function (semester) {
                    return $http.delete(API_URL + "semesters/" + semester.id);
                },

                /*
                 * Semester Contracts
                 */

                getContracts: function (semesterId) {
                    return $http.get(API_URL + "semesters/" + semesterId + "/contracts");
                },
                createContract: function (semesterId, contract) {
                    return $http.post(API_URL + "semesters/" + semesterId + "/contracts", contract);
                },
                importContracts: function (semesterId, contracts) {
                    return $http.post(API_URL + "semesters/" + semesterId + "/contracts/import", contracts, {
                        transformRequest: angular.identity,
                        headers: { "Content-Type": undefined },
                    });
                },
                importContractsUpdate: function (semesterId, contractsUpdate) {
                    return $http.post(
                        API_URL + "semesters/" + semesterId + "/contracts/import-update",
                        contractsUpdate,
                        {
                            transformRequest: angular.identity,
                            headers: { "Content-Type": undefined },
                        }
                    );
                },

                /*
                 * Semester Proposals
                 */

                getProposals: function (semesterId) {
                    return $http.get(API_URL + "semesters/" + semesterId + "/proposals");
                },
                createProposal: function (semesterId, newProposal) {
                    return $http.post(API_URL + "semesters/" + semesterId + "/proposals", newProposal);
                },

                /*
                 * Semester Enrollments
                 */

                getEnrollments: function (semesterId, exported_at) {
                    return $http.get(API_URL + "semesters/" + semesterId + "/enrollments", {
                        params: { exported_at: exported_at },
                    });
                },
                deleteEnrollments: function (semesterId, exported_at) {
                    var params = {};
                    params.exported_at = exported_at;

                    return $http.delete(API_URL + "semesters/" + semesterId + "/enrollments", { params: params });
                },
                importEnrollments: function (semesterId, fd) {
                    return $http.post(API_URL + "semesters/" + semesterId + "/enrollments/import", fd, {
                        transformRequest: angular.identity,
                        headers: { "Content-Type": undefined },
                    });
                },

                /*
                 * Semester Warnings
                 */

                getWarnings: function (semesterId) {
                    return $http.get(API_URL + "semesters/" + semesterId + "/warnings");
                },
                sendWarningReminders: function (semesterId, warningId, user) {
                    return $http.patch(
                        API_URL + "semesters/" + semesterId + "/warnings/" + warningId + "/remind",
                        user
                    );
                },
            };

        function extract(result) {
            return result.data;
        }

        /*
         * Semesters
         */

        function cacheSemesters(result) {
            semesters = extract(result);

            return semesters;
        }

        model.clearCache = function () {
            semesters = null;
        };

        model.getSemesters = function () {
            if (semesters) {
                return $q.when(semesters);
            } else {
                semesters = API.getSemesters().then(cacheSemesters);
                return semesters;
            }
        };

        model.getSemester = function (semesterId) {
            var deferred = $q.defer();

            function findSemester() {
                var semester = _.find(semesters, function (semester) {
                    return semester.id == semesterId;
                });

                if (semester) {
                    deferred.resolve(semester);
                } else {
                    deferred.reject();
                }
            }

            if (semesters) {
                $q.when(semesters).then(function () {
                    findSemester();
                });
            } else {
                model.getSemesters().then(function () {
                    findSemester();
                });
            }

            return deferred.promise;
        };

        model.createSemester = function (semester) {
            var deferred = $q.defer();

            API.createSemester(semester).then(
                function (result) {
                    var semester = extract(result);
                    semesters.push(semester);
                    deferred.resolve(semester);
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteSemester = function (deletedSemester) {
            var deferred = $q.defer();

            API.deleteSemester(deletedSemester).then(
                function () {
                    _.remove(semesters, function (semester) {
                        return semester.id == deletedSemester.id;
                    });
                    deferred.resolve();
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /**
         * Contracts
         */

        function cacheContracts(semester, result) {
            semester.contracts = extract(result);

            return semester.contracts;
        }

        model.clearContractsCache = function (semesterId) {
            model.getSemester(semesterId).then(function (semester) {
                semester.contracts = null;
            });
        };

        model.getContracts = function (semesterId) {
            var deferred = $q.defer();

            model.getSemester(semesterId).then(
                function (semester) {
                    if (semester.contracts) {
                        $q.when(semester.contracts).then(function () {
                            deferred.resolve(semester.contracts);
                        });
                    } else {
                        semester.contracts = API.getContracts(semesterId).then(function (result) {
                            return cacheContracts(semester, result);
                        });
                        deferred.resolve(semester.contracts);
                    }
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        };

        model.createContract = function (semesterId, contract) {
            var deferred = $q.defer();

            API.createContract(semesterId, contract).then(
                function (result) {
                    var contract = extract(result);
                    model.getSemester(semesterId).then(
                        function (semester) {
                            semester.contracts.push(contract);
                            deferred.resolve(contract);
                        },
                        function () {
                            deferred.reject();
                        }
                    );
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.importContracts = function (semesterId, contracts) {
            var deferred = $q.defer();

            API.importContracts(semesterId, contracts).then(
                function (result) {
                    var data = extract(result);
                    model.getSemester(semesterId).then(
                        function (semester) {
                            _.forEach(data.contracts, function (updatedContract) {
                                var contract = _.find(semester.contracts, function (contract) {
                                    return contract.id == updatedContract.id;
                                });

                                if (contract) {
                                    delete contract.originalContract;
                                    delete contract.revisions;
                                    _.merge(contract, updatedContract);
                                } else {
                                    semester.contracts.push(updatedContract);
                                }
                            });

                            deferred.resolve(data);
                        },
                        function () {
                            deferred.reject();
                        }
                    );
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.importContractsUpdate = function (semesterId, update) {
            var deferred = $q.defer();

            API.importContractsUpdate(semesterId, update).then(
                function (result) {
                    var data = extract(result);

                    model.getSemester(semesterId).then(
                        function (semester) {
                            _.forEach(data.contracts, function (updatedContract) {
                                var contract = _.find(semester.contracts, function (contract) {
                                    return contract.id == updatedContract.id;
                                });

                                if (contract) {
                                    delete contract.originalContract;
                                    delete contract.revisions;
                                    _.merge(contract, updatedContract);
                                } else {
                                    semester.contracts.push(updatedContract);
                                }
                            });

                            deferred.resolve(data);
                        },
                        function () {
                            deferred.reject();
                        }
                    );
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /*
         * Proposals
         */

        function cacheProposals(semester, result) {
            semester.proposals = extract(result);

            return semester.proposals;
        }

        model.clearProposalsCache = function (semesterId) {
            model.getSemester(semesterId).then(function (semester) {
                semester.proposals = null;
            });
        };

        model.getProposals = function (semesterId) {
            var deferred = $q.defer();

            model.getSemester(semesterId).then(
                function (semester) {
                    if (semester.proposals) {
                        deferred.resolve(semester.proposals);
                    } else {
                        semester.proposals = API.getProposals(semesterId).then(function (result) {
                            return cacheProposals(semester, result);
                        });
                        deferred.resolve(semester.proposals);
                    }
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        };

        model.getProposal = function (semesterId, proposalId) {
            var deferred = $q.defer();

            model.getProposals(semesterId).then(
                function (proposals) {
                    var proposal = _.find(proposals, function (proposal) {
                        return proposal.id == proposalId;
                    });

                    if (proposal) {
                        deferred.resolve(proposal);
                    } else {
                        deferred.reject();
                    }
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        };

        model.createProposal = function (semesterId, newProposal) {
            var deferred = $q.defer();

            API.createProposal(semesterId, newProposal).then(
                function (result) {
                    var newProposal = extract(result);
                    model.getSemester(semesterId).then(function (semester) {
                        semester.proposals.push(newProposal);
                        deferred.resolve(newProposal);
                    });
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /**
         * Enrollments
         */

        function cacheEnrollments(semester, result) {
            var enrollments = extract(result);

            if (semester.enrollments) {
                semester.enrollments.data = _.unionBy(semester.enrollments.data, enrollments.data, "id");
            } else {
                semester.enrollments = enrollments;
            }

            return semester.enrollments;
        }

        model.getEnrollments = function (semesterId, exported_at) {
            var deferred = $q.defer();

            model.getSemester(semesterId).then(
                function (semester) {
                    function findExportedAt(index) {
                        return _.find(semester.enrollments.data, function (enrollments) {
                            return enrollments.exported_at == semester.enrollments.exported_ats[index];
                        });
                    }

                    function isCached() {
                        if (!semester.enrollments) {
                            return false;
                        }

                        if (!exported_at && semester.enrollments.exported_ats.length > 0) {
                            exported_at = semester.enrollments.exported_ats[0];
                        }

                        var index = _.findIndex(semester.enrollments.exported_ats, function (_exported_at) {
                            return _exported_at == exported_at;
                        });

                        if (index == semester.enrollments.exported_ats.length - 1) {
                            return findExportedAt(index);
                        }

                        return findExportedAt(index) && findExportedAt(index + 1);
                    }

                    if (semester.getEnrollments && isCached()) {
                        // semester.enrollments
                        $q.when(semester.enrollments).then(function (enrollments) {
                            deferred.resolve(enrollments);
                        });
                    } else {
                        semester.getEnrollments = API.getEnrollments(semesterId, exported_at).then(function (result) {
                            deferred.resolve(cacheEnrollments(semester, result));
                        });
                    }
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        };

        model.importEnrollments = function (semesterId, fd, update_contracts) {
            var deferred = $q.defer();

            API.importEnrollments(semesterId, fd).then(
                function (result) {
                    var enrollments = extract(result);

                    model.getSemester(semesterId).then(
                        function (semester) {
                            semester.enrollments.data = _.unionBy(semester.enrollments.data, enrollments.data, "id");
                            semester.enrollments.exported_ats = _.orderBy(
                                _.union(semester.enrollments.exported_ats, enrollments.exported_ats),
                                function (exported_at) {
                                    return new Date(exported_at);
                                },
                                "desc"
                            );

                            deferred.resolve(enrollments);
                        },
                        function () {
                            deferred.reject();
                        }
                    );
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteEnrollments = function (semesterId, exported_at) {
            var deferred = $q.defer();

            API.deleteEnrollments(semesterId, exported_at).then(
                function () {
                    model.getSemester(semesterId).then(
                        function (semester) {
                            _.remove(semester.enrollments.data, function (_enrollment) {
                                return _enrollment.exported_at == exported_at;
                            });
                            _.remove(semester.enrollments.exported_ats, function (_exported_at) {
                                return _exported_at == exported_at;
                            });
                            deferred.resolve(semester.enrollments);
                        },
                        function () {
                            deferred.reject();
                        }
                    );
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /*
         * Warnings
         */

        function cacheWarnings(semester, result) {
            semester.warnings = extract(result);

            return semester.warnings;
        }

        model.clearWarningsCache = function (semesterId) {
            model.getSemester(semesterId).then(function (semester) {
                semester.warnings = null;
            });
        };

        model.getWarnings = function (semesterId) {
            var deferred = $q.defer();

            model.getSemester(semesterId).then(
                function (semester) {
                    if (semester.warnings) {
                        $q.when(semester.warnings).then(function (warnings) {
                            deferred.resolve(warnings);
                        });
                    } else {
                        semester.warnings = API.getWarnings(semesterId).then(function (result) {
                            return cacheWarnings(semester, result);
                        });

                        deferred.resolve(semester.warnings);
                    }
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        };

        model.sendWarningReminders = function (semesterId, warningId, userId) {
            var deferred = $q.defer();

            API.sendWarningReminders(semesterId, warningId, userId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };
    },
]);
