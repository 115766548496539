angular
    .module("admin.approvers.approver", [
        "admin.approvers.approver.update",
        "admin.approvers.approver.audits",
        "admin.approvers.approver.members.add",
        "admin.approvers.approver.members.remove",
    ])
    .config(function ($stateProvider) {
        $stateProvider
            .state("admin.approvers.approver", {
                url: "/{approverId:int}",
                views: {
                    "": {
                        templateUrl: "admin/approvers/approver/approver.tmpl.html",
                        controller: "ApproverCtrl as approverCtrl",
                    },
                    "members@admin.approvers.approver": {
                        templateUrl: "admin/approvers/approver/members/members.tmpl.html",
                        controller: "ApproverCtrl as approverCtrl",
                    },
                },
                ncyBreadcrumb: {
                    label: "Approver",
                },
            })
            .state("admin.approvers.approver.members", {
                abstract: true,
                views: {
                    "members@admin.approvers.approver": {
                        templateUrl: "admin/approvers/approver/members/members.tmpl.html",
                        controller: "ApproverCtrl as approverCtrl",
                    },
                },
                ncyBreadcrumb: {
                    skip: true,
                },
            });
    })
    .controller("ApproverCtrl", [
        "$stateParams",
        "$timeout",
        "$uibModal",
        "SemestersModel",
        "InstructorsModel",
        "ApproversModel",
        "ApproversFactory",
        "UsersFactory",
        "Loader",
        "AlertFactory",
        function (
            $stateParams,
            $timeout,
            $uibModal,
            SemestersModel,
            InstructorsModel,
            ApproversModel,
            ApproversFactory,
            UsersFactory,
            Loader,
            AlertFactory
        ) {
            var approverCtrl = this;
            var approverId = $stateParams.approverId;

            Loader.showLoading("Loading Approver");
            ApproversModel.getApprover(approverId).then(function (approver) {
                ApproversModel.getRoles().then(function (roles) {
                    approverCtrl.roles = roles;
                    approver.editable = ApproversFactory.getEditable(approver);
                    approver.user.editable = UsersFactory.getEditable(approver.user);
                    approverCtrl.approver = approver;

                    Loader.hideLoading();
                });
            });

            approverCtrl.saveEdit = function (editable) {
                editable.saveEdit().then(
                    function () {
                        InstructorsModel.clearCache();
                        SemestersModel.clearCache();
                    },
                    function (result) {
                        if (result.errors) {
                            AlertFactory.show(_(result.errors).values().flatten().value().join("\r\n"), "danger");
                        }

                        $timeout(function () {
                            approverCtrl.approverForm[editable.key].$invalid = true;
                        });
                    }
                );
            };

            approverCtrl.updateDirector = function (approver) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: "admin/approvers/approver/update/approver-update.tmpl.html",
                    controller: "UpdateApproverCtrl as updateApproverCtrl",
                    resolve: {
                        approver: function () {
                            return approver;
                        },
                    },
                });

                modalInstance.result
                    .then(function (approver) {
                        approver.isDirector = true;
                        approver.role.alt_display_name = "DLL Director, " + approver.role.display_name;

                        Loader.showLoading("Loading Approvers");
                        ApproversModel.getApprovers().then(
                            function (approvers) {
                                _.forEach(approvers, function (a) {
                                    a.isDirector = false;
                                    a.role.alt_display_name = a.role.display_name;
                                    if (approver.user_id == a.user_id) {
                                        a.isDirector = true;
                                        a.role.alt_display_name = "DLL Director, " + a.role.display_name;
                                    }
                                });
                                _.assign(approverCtrl.approver, approver);
                                Loader.hideLoading();
                            },
                            function () {
                                Loader.hideLoading();
                            }
                        );
                    })
                    .catch(function (res) {
                        if (["cancel", "backdrop click", "escape key press"].indexOf(res) === -1) throw res;
                    });
            };

            approverCtrl.deleteApprover = function (approver) {
                $uibModal.open({
                    animation: true,
                    templateUrl: "admin/approvers/delete/approver-delete.tmpl.html",
                    controller: "DeleteApproverCtrl as deleteApproverCtrl",
                    resolve: {
                        approver: function () {
                            return approver;
                        },
                    },
                });
            };

            approverCtrl.removeApproverMember = function (member) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: "admin/approvers/approver/members/remove/member-remove.tmpl.html",
                    controller: "RemoveApproverMemberCtrl as removeApproverMemberCtrl",
                    resolve: {
                        member: function () {
                            return member;
                        },
                    },
                });

                modalInstance.result
                    .then(function () {
                        Loader.showLoading("Removing Staff Member");
                        ApproversModel.removeApproverMember(approverId, member.id)
                            .then(
                                function () {
                                    _.remove(approverCtrl.approver.members, function (_member) {
                                        return member.id === _member.id;
                                    });
                                },
                                function (result) {
                                    if (result.errors) {
                                        var messages = [];
                                        _.forEach(result.errors, function (error) {
                                            messages.push(error);
                                        });
                                        AlertFactory.show(messages.join("\r\n"), "danger");
                                    }
                                }
                            )
                            .finally(function () {
                                Loader.hideLoading();
                            });
                    })
                    .catch(function (res) {
                        if (["cancel", "backdrop click", "escape key press"].indexOf(res) === -1) throw res;
                    });
            };
        },
    ]);
