angular.module('app.models.roles', [])
    .service('RolesModel', ['$http', '$q', 'API_URL', function($http, $q, API_URL) {
        var model = this,
            roles,
            API = {
                getRoles: function() {
                    return $http.get(API_URL + 'roles');
                }
            };

        function extract(result) {
            return result.data;
        }

        function cacheRoles(result) {
            roles = extract(result);
            return roles;
        }

        model.getRoles = function() {
            if (roles) {
                return $q.when(roles);
            } else {
                return API.getRoles().then(cacheRoles);
            }
        };
    }]);