angular.module("app.models.warnings", []).service("WarningsModel", [
    "$http",
    "$q",
    "API_URL",
    function ($http, $q, API_URL) {
        var model = this,
            responseStatuses = ["Pro-rate Salary", "Cancel", "Other", "Min Enrl Reached", "On-Load", "Yes", "No"],
            API = {
                updateWarning: function (warningId, editedWarning) {
                    return $http.patch(API_URL + "warnings/" + warningId, editedWarning);
                },

                /*
                 * Statuses
                 */
                getWarningStatuses: function (warningId) {
                    return $http.get(API_URL + "warnings/" + warningId + "/statuses");
                },

                getWarningApproverStatuses: function (warningApproverId) {
                    return $http.get(API_URL + "warning-approvers/" + warningApproverId + "/statuses");
                },

                /*
                 * Notes
                 */
                createWarningNote: function (warningId, note) {
                    return $http.post(API_URL + "warnings/" + warningId + "/notes", note);
                },
                deleteWarningNote: function (warningId, warningNoteId) {
                    return $http.delete(API_URL + "warnings/" + warningId + "/notes/" + warningNoteId);
                },

                /*
                 * Send
                 */
                sendWarning: function (warningId) {
                    return $http.patch(API_URL + "warnings/" + warningId + "/send");
                },
                sendWarningApproverWarning: function (warningId, warningApproverId) {
                    return $http.patch(API_URL + "warnings/" + warningId + "/approvers/" + warningApproverId + "/send");
                },
            };

        function extract(result) {
            return result.data;
        }

        model.getResponseStatuses = function () {
            return responseStatuses;
        };

        model.updateWarning = function (warningId, editedWarning) {
            var deferred = $q.defer();

            API.updateWarning(warningId, editedWarning).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /*
         * Statuses
         */

        model.getWarningStatuses = function (warningId) {
            var deferred = $q.defer();

            API.getWarningStatuses(warningId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.getWarningApproverStatuses = function (warningApproverId) {
            var deferred = $q.defer();

            API.getWarningApproverStatuses(warningApproverId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /*
         *  Notes
         */

        model.createWarningNote = function (warningId, note) {
            var deferred = $q.defer();

            API.createWarningNote(warningId, note).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.deleteWarningNote = function (warningId, warningNoteId) {
            var deferred = $q.defer();

            API.deleteWarningNote(warningId, warningNoteId).then(
                function () {
                    deferred.resolve();
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        /*
         * Send
         */

        model.sendWarning = function (warningId) {
            var deferred = $q.defer();

            API.sendWarning(warningId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };

        model.sendWarningApproverWarning = function (warningId, warningApproverId) {
            var deferred = $q.defer();

            API.sendWarningApproverWarning(warningId, warningApproverId).then(
                function (result) {
                    deferred.resolve(extract(result));
                },
                function (result) {
                    deferred.reject(extract(result));
                }
            );

            return deferred.promise;
        };
    },
]);
